import React from "react";
import Button from "@material-ui/core/Button";
import AppLoading from "components/common/AppLoading";
import { Formik } from "formik";
import Page from "components/common/Page";
import Paper from "components/common/Paper";
import CitySelect from "components/common/form/CitySelect";
import CancelButton from "components/common/form/CancelButton";

export default function Stages({
  loading,
  stage,
  onChangeName,
  save,
  validate,
  path,
  pathPoints,
  updateStart,
  updateEnd,
  points,
  center
}) {
  if (loading) return <AppLoading />;

  return (
    <Page
      path={path}
      title={stage.name ? "Edit " + stage.name : "New Stage"}
      crumbs={[
        {
          name: "Stages",
          href: "/stages"
        },
        {
          name: stage.name || "New Stage"
        }
      ]}
    >
      <Paper>
        <Formik
          initialValues={stage}
          validate={validate}
          onSubmit={async (values, { setSubmitting }) => {
            await save(values);
            setSubmitting(false);
          }}
          noValidate
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting
          }) => (
            <form onSubmit={handleSubmit} noValidate autoComplete="off">
              <CitySelect name="cityStartId" pathId={path.id} />

              <CitySelect name="cityEndId" pathId={path.id} />

              <Button
                variant="contained"
                color="primary"
                type="submit"
                disabled={isSubmitting}
                style={{ marginRight: 6 }}
              >
                Save
              </Button>
              <CancelButton location={`/paths/${path.id}/stages`} />
            </form>
          )}
        </Formik>
      </Paper>
    </Page>
  );
}
