import { connect, initializable } from "effects";
import * as functions from "./functions";
import TagsDashboard from "./TagsDashboard";

const mapStateToProps = (state, ownProps, getState) => {
  return {
    path: getState("app.path", null),
    tag: getState("tagdashboard.tag"),
    loading: getState("tagdashboard.loading", true)
  };
};

export default connect(
  initializable(TagsDashboard),
  mapStateToProps,
  functions
);
