import React from "react";
import Select from "components/common/form/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import { FastField } from "formik";

export default function Currency(props) {
  return (
    <FastField name={props.name}>
      {({ field, meta }) => {
        return (
          <FormControl
            variant="outlined"
            margin="normal"
            fullWidth
            error={Boolean(meta.error)}
          >
            <InputLabel id="currency-label" style={{ width: 170 }}>
              Currency
            </InputLabel>
            <Select
              name={props.name}
              value={field.value || ""}
              onChange={field.onChange}
              onBlur={field.onBlur}
              fullWidth
              {...props}
            >
              {currencies.map(c => (
                <MenuItem key={c.code} value={c.code}>
                  {c.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        );
      }}
    </FastField>
  );
}

const currencies = [
  { symbol: "€", name: "Euro", rounding: 0, code: "EUR" },
  { symbol: "$", name: "US Dollar", rounding: 0, code: "USD" },
  { symbol: "CA$", name: "Canadian Dollar", rounding: 0, code: "CAD" },
  {
    symbol: "AED",
    name: "United Arab Emirates Dirham",
    rounding: 0,
    code: "AED"
  },
  { symbol: "Af", name: "Afghan Afghani", rounding: 0, code: "AFN" },
  { symbol: "ALL", name: "Albanian Lek", rounding: 0, code: "ALL" },
  { symbol: "AMD", name: "Armenian Dram", rounding: 0, code: "AMD" },
  { symbol: "AR$", name: "Argentine Peso", rounding: 0, code: "ARS" },
  { symbol: "AU$", name: "Australian Dollar", rounding: 0, code: "AUD" },
  { symbol: "man.", name: "Azerbaijani Manat", rounding: 0, code: "AZN" },
  {
    symbol: "KM",
    name: "Bosnia-Herzegovina Convertible Mark",
    rounding: 0,
    code: "BAM"
  },
  { symbol: "Tk", name: "Bangladeshi Taka", rounding: 0, code: "BDT" },
  { symbol: "BGN", name: "Bulgarian Lev", rounding: 0, code: "BGN" },
  { symbol: "BD", name: "Bahraini Dinar", rounding: 0, code: "BHD" },
  { symbol: "FBu", name: "Burundian Franc", rounding: 0, code: "BIF" },
  { symbol: "BN$", name: "Brunei Dollar", rounding: 0, code: "BND" },
  { symbol: "Bs", name: "Bolivian Boliviano", rounding: 0, code: "BOB" },
  { symbol: "R$", name: "Brazilian Real", rounding: 0, code: "BRL" },
  { symbol: "BWP", name: "Botswanan Pula", rounding: 0, code: "BWP" },
  { symbol: "Br", name: "Belarusian Ruble", rounding: 0, code: "BYN" },
  { symbol: "BZ$", name: "Belize Dollar", rounding: 0, code: "BZD" },
  { symbol: "CDF", name: "Congolese Franc", rounding: 0, code: "CDF" },
  { symbol: "CHF", name: "Swiss Franc", rounding: 0.05, code: "CHF" },
  { symbol: "CL$", name: "Chilean Peso", rounding: 0, code: "CLP" },
  { symbol: "CN¥", name: "Chinese Yuan", rounding: 0, code: "CNY" },
  { symbol: "CO$", name: "Colombian Peso", rounding: 0, code: "COP" },
  { symbol: "₡", name: "Costa Rican Colón", rounding: 0, code: "CRC" },
  { symbol: "CV$", name: "Cape Verdean Escudo", rounding: 0, code: "CVE" },
  { symbol: "Kč", name: "Czech Republic Koruna", rounding: 0, code: "CZK" },
  { symbol: "Fdj", name: "Djiboutian Franc", rounding: 0, code: "DJF" },
  { symbol: "Dkr", name: "Danish Krone", rounding: 0, code: "DKK" },
  { symbol: "RD$", name: "Dominican Peso", rounding: 0, code: "DOP" },
  { symbol: "DA", name: "Algerian Dinar", rounding: 0, code: "DZD" },
  { symbol: "Ekr", name: "Estonian Kroon", rounding: 0, code: "EEK" },
  { symbol: "EGP", name: "Egyptian Pound", rounding: 0, code: "EGP" },
  { symbol: "Nfk", name: "Eritrean Nakfa", rounding: 0, code: "ERN" },
  { symbol: "Br", name: "Ethiopian Birr", rounding: 0, code: "ETB" },
  { symbol: "£", name: "British Pound Sterling", rounding: 0, code: "GBP" },
  { symbol: "GEL", name: "Georgian Lari", rounding: 0, code: "GEL" },
  { symbol: "GH₵", name: "Ghanaian Cedi", rounding: 0, code: "GHS" },
  { symbol: "FG", name: "Guinean Franc", rounding: 0, code: "GNF" },
  { symbol: "GTQ", name: "Guatemalan Quetzal", rounding: 0, code: "GTQ" },
  { symbol: "HK$", name: "Hong Kong Dollar", rounding: 0, code: "HKD" },
  { symbol: "HNL", name: "Honduran Lempira", rounding: 0, code: "HNL" },
  { symbol: "kn", name: "Croatian Kuna", rounding: 0, code: "HRK" },
  { symbol: "Ft", name: "Hungarian Forint", rounding: 0, code: "HUF" },
  { symbol: "Rp", name: "Indonesian Rupiah", rounding: 0, code: "IDR" },
  { symbol: "₪", name: "Israeli New Sheqel", rounding: 0, code: "ILS" },
  { symbol: "Rs", name: "Indian Rupee", rounding: 0, code: "INR" },
  { symbol: "IQD", name: "Iraqi Dinar", rounding: 0, code: "IQD" },
  { symbol: "IRR", name: "Iranian Rial", rounding: 0, code: "IRR" },
  { symbol: "Ikr", name: "Icelandic Króna", rounding: 0, code: "ISK" },
  { symbol: "J$", name: "Jamaican Dollar", rounding: 0, code: "JMD" },
  { symbol: "JD", name: "Jordanian Dinar", rounding: 0, code: "JOD" },
  { symbol: "¥", name: "Japanese Yen", rounding: 0, code: "JPY" },
  { symbol: "Ksh", name: "Kenyan Shilling", rounding: 0, code: "KES" },
  { symbol: "KHR", name: "Cambodian Riel", rounding: 0, code: "KHR" },
  { symbol: "CF", name: "Comorian Franc", rounding: 0, code: "KMF" },
  { symbol: "₩", name: "South Korean Won", rounding: 0, code: "KRW" },
  { symbol: "KD", name: "Kuwaiti Dinar", rounding: 0, code: "KWD" },
  { symbol: "KZT", name: "Kazakhstani Tenge", rounding: 0, code: "KZT" },
  { symbol: "LB£", name: "Lebanese Pound", rounding: 0, code: "LBP" },
  { symbol: "SLRs", name: "Sri Lankan Rupee", rounding: 0, code: "LKR" },
  { symbol: "Lt", name: "Lithuanian Litas", rounding: 0, code: "LTL" },
  { symbol: "Ls", name: "Latvian Lats", rounding: 0, code: "LVL" },
  { symbol: "LD", name: "Libyan Dinar", rounding: 0, code: "LYD" },
  { symbol: "MAD", name: "Moroccan Dirham", rounding: 0, code: "MAD" },
  { symbol: "MDL", name: "Moldovan Leu", rounding: 0, code: "MDL" },
  { symbol: "MGA", name: "Malagasy Ariary", rounding: 0, code: "MGA" },
  { symbol: "MKD", name: "Macedonian Denar", rounding: 0, code: "MKD" },
  { symbol: "MMK", name: "Myanma Kyat", rounding: 0, code: "MMK" },
  { symbol: "MOP$", name: "Macanese Pataca", rounding: 0, code: "MOP" },
  { symbol: "MURs", name: "Mauritian Rupee", rounding: 0, code: "MUR" },
  { symbol: "MX$", name: "Mexican Peso", rounding: 0, code: "MXN" },
  { symbol: "RM", name: "Malaysian Ringgit", rounding: 0, code: "MYR" },
  { symbol: "MTn", name: "Mozambican Metical", rounding: 0, code: "MZN" },
  { symbol: "N$", name: "Namibian Dollar", rounding: 0, code: "NAD" },
  { symbol: "₦", name: "Nigerian Naira", rounding: 0, code: "NGN" },
  { symbol: "C$", name: "Nicaraguan Córdoba", rounding: 0, code: "NIO" },
  { symbol: "Nkr", name: "Norwegian Krone", rounding: 0, code: "NOK" },
  { symbol: "NPRs", name: "Nepalese Rupee", rounding: 0, code: "NPR" },
  { symbol: "NZ$", name: "New Zealand Dollar", rounding: 0, code: "NZD" },
  { symbol: "OMR", name: "Omani Rial", rounding: 0, code: "OMR" },
  { symbol: "B/.", name: "Panamanian Balboa", rounding: 0, code: "PAB" },
  { symbol: "S/.", name: "Peruvian Nuevo Sol", rounding: 0, code: "PEN" },
  { symbol: "₱", name: "Philippine Peso", rounding: 0, code: "PHP" },
  { symbol: "PKRs", name: "Pakistani Rupee", rounding: 0, code: "PKR" },
  { symbol: "zł", name: "Polish Zloty", rounding: 0, code: "PLN" },
  { symbol: "₲", name: "Paraguayan Guarani", rounding: 0, code: "PYG" },
  { symbol: "QR", name: "Qatari Rial", rounding: 0, code: "QAR" },
  { symbol: "RON", name: "Romanian Leu", rounding: 0, code: "RON" },
  { symbol: "din.", name: "Serbian Dinar", rounding: 0, code: "RSD" },
  { symbol: "RUB", name: "Russian Ruble", rounding: 0, code: "RUB" },
  { symbol: "RWF", name: "Rwandan Franc", rounding: 0, code: "RWF" },
  { symbol: "SR", name: "Saudi Riyal", rounding: 0, code: "SAR" },
  { symbol: "SDG", name: "Sudanese Pound", rounding: 0, code: "SDG" },
  { symbol: "Skr", name: "Swedish Krona", rounding: 0, code: "SEK" },
  { symbol: "S$", name: "Singapore Dollar", rounding: 0, code: "SGD" },
  { symbol: "Ssh", name: "Somali Shilling", rounding: 0, code: "SOS" },
  { symbol: "SY£", name: "Syrian Pound", rounding: 0, code: "SYP" },
  { symbol: "฿", name: "Thai Baht", rounding: 0, code: "THB" },
  { symbol: "DT", name: "Tunisian Dinar", rounding: 0, code: "TND" },
  { symbol: "T$", name: "Tongan Paʻanga", rounding: 0, code: "TOP" },
  { symbol: "TL", name: "Turkish Lira", rounding: 0, code: "TRY" },
  {
    symbol: "TT$",
    name: "Trinidad and Tobago Dollar",
    rounding: 0,
    code: "TTD"
  },
  { symbol: "NT$", name: "New Taiwan Dollar", rounding: 0, code: "TWD" },
  { symbol: "TSh", name: "Tanzanian Shilling", rounding: 0, code: "TZS" },
  { symbol: "₴", name: "Ukrainian Hryvnia", rounding: 0, code: "UAH" },
  { symbol: "USh", name: "Ugandan Shilling", rounding: 0, code: "UGX" },
  { symbol: "$U", name: "Uruguayan Peso", rounding: 0, code: "UYU" },
  { symbol: "UZS", name: "Uzbekistan Som", rounding: 0, code: "UZS" },
  { symbol: "Bs.F.", name: "Venezuelan Bolívar", rounding: 0, code: "VEF" },
  { symbol: "₫", name: "Vietnamese Dong", rounding: 0, code: "VND" },
  { symbol: "FCFA", name: "CFA Franc BEAC", rounding: 0, code: "XAF" },
  { symbol: "CFA", name: "CFA Franc BCEAO", rounding: 0, code: "XOF" },
  { symbol: "YR", name: "Yemeni Rial", rounding: 0, code: "YER" },
  { symbol: "R", name: "South African Rand", rounding: 0, code: "ZAR" },
  { symbol: "ZK", name: "Zambian Kwacha", rounding: 0, code: "ZMK" }
];
