import { cmds } from "effects";
import { get } from "object-path";
import { getPathPoints } from "components/helpers";

export function* setup(props) {
  const pathId = get(props, "match.params.pathId");
  const { path } = yield cmds.rpc("GetPathByID", { id: pathId });
  yield cmds.setState("app.path", path);

  const cities = yield cmds.rpc("ListCities", {
    pathId,
    page: 0,
    pageLength: 1000
  });

  const citiesWithDefault = cities.map(c => {
    c.distanceFromOriginInMeters = c.distanceFromOriginInMeters || 0;
    return c;
  });

  yield cmds.setState("cities.list", citiesWithDefault);

  const points = yield cmds.call(getPathPoints, pathId);
  yield cmds.setState("app.pathPoints", points);

  yield cmds.setState("cities.loading", false);
}

export function* deleteCity(id) {
  const confirmed = yield cmds.confirm(
    "Are you sure you want to delete this city?"
  );
  if (!confirmed) return;

  const cities = yield cmds.getState("cities.list", []);
  yield cmds.setState(
    "cities.list",
    cities.filter(p => p.id !== id)
  );
  yield cmds.rpc("DeleteCity", { id });
}
