import React from "react";
import Button from "@material-ui/core/Button";
import AppLoading from "components/common/AppLoading";
import { Formik } from "formik";
import Page from "components/common/Page";
import Paper from "components/common/Paper";
import Section from "components/common/form/Section";
import FormControl from "@material-ui/core/FormControl";
import TagSelect from "components/common/form/TagSelect";
import TextField from "components/common/form/TextField";
import { StandardPOIFields } from "components/common/form/StandardPOIFields";
import CancelButton from "components/common/form/CancelButton";

export default function Restaurants({
  loading,
  restaurant,
  onChangeName,
  save,
  validate,
  path,
  city,
  pathPoints
}) {
  if (loading) return <AppLoading />;
  return (
    <Page
      path={path}
      title={
        restaurant.name
          ? "Edit " + restaurant.name
          : "New Restaurant in " + city.name
      }
      crumbs={[
        {
          name: "Cities and Towns",
          href: "/cities"
        },
        {
          name: city.name,
          href: `/cities/${city.id}`
        },
        {
          name: "Restaurants",
          href: `/cities/${city.id}/restaurants`
        },
        {
          name: restaurant.name || "New Restaurant"
        }
      ]}
    >
      <Paper>
        <Formik
          initialValues={restaurant}
          validate={validate}
          onSubmit={async (values, { setSubmitting }) => {
            await save(values);
            setSubmitting(false);
          }}
          noValidate
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            setFieldValue
          }) => (
            <form onSubmit={handleSubmit} autoComplete="off">
              <StandardPOIFields
                setFieldValue={setFieldValue}
                path={path}
                city={city}
                handleChange={handleChange}
                handleBlur={handleBlur}
                values={values}
                errors={errors}
                pathPoints={pathPoints}
              />

              <Section title="Restaurant Details">
                <FormControl fullWidth margin="normal">
                  <TagSelect
                    pathId={path.id}
                    setName="restaurant-food-types"
                    label="Food Types"
                    name="foodTypes"
                  />
                </FormControl>

                <FormControl fullWidth margin="normal">
                  <TagSelect
                    pathId={path.id}
                    setName="restaurant-diet-options"
                    label="Diet Options"
                    name="dietOptions"
                  />
                </FormControl>

                <FormControl fullWidth margin="normal">
                  <TagSelect
                    pathId={path.id}
                    setName="restaurant-eatery-types"
                    label="Eatery Types"
                    name="eateryTypes"
                  />
                </FormControl>

                <FormControl fullWidth margin="normal">
                  <TagSelect
                    pathId={path.id}
                    setName="restaurant-reservation-options"
                    label="Reservation Options"
                    name="reservations"
                  />
                </FormControl>

                <TextField label="Menu URL" name="menuUrl" />
                <TextField label="Price" name="price" />
              </Section>

              <Section title="Save Changes">
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  disabled={isSubmitting}
                  style={{ marginRight: 6 }}
                >
                  Save
                </Button>
                <CancelButton
                  location={`/paths/${path.id}/cities/${city.id}/restaurants`}
                />
              </Section>
            </form>
          )}
        </Formik>
      </Paper>
    </Page>
  );
}
