import { cmds } from "effects";
import { get } from "object-path";
import mongoid from "mongoid-js";

export function* setup(props) {
  const pathId = get(props, "match.params.pathId");
  const { path } = yield cmds.rpc("GetPathByID", { id: pathId });
  yield cmds.setState("plannerCategoryPublish.path", path);

  yield cmds.setState("plannerCategoryPublish.loading", true);
  const plannerCategoryId = get(props, "match.params.plannerCategoryId");
  yield cmds.setState("plannerCategoryPublish.pathId", pathId);

  if (plannerCategoryId) {
    const { plannerCategory } = yield cmds.rpc("GetPlannerCategoryByID", {
      id: plannerCategoryId,
    });
    yield cmds.setState(
      "plannerCategoryPublish.plannerCategory",
      plannerCategory
    );
  } else {
    yield cmds.setState("plannerCategoryPublish.plannerCategory", {
      pathId,
    });
  }
  yield cmds.setState("plannerCategoryPublish.loading", false);
}

export function* save(plannerCategory) {
  const pathId = yield cmds.getState("plannerCategoryPublish.pathId");

  if (plannerCategory.id) {
    yield cmds.rpc("UpdatePlannerCategory", plannerCategory);
  } else {
    plannerCategory = {
      id: mongoid(),
      ...plannerCategory,
    };
    yield cmds.rpc("CreatePlannerCategory", plannerCategory);
  }
  yield cmds.redirect(`/paths/${pathId}/planner-categories`);
}

// eslint-disable-next-line require-yield
export function* validate(values) {
  const errors = {};

  if (!values.title) {
    errors.title = "Required";
  }

  return errors;
}
