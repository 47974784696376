import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Link from "@material-ui/core/Link";
import Page from "components/common/Page";

const useStyles = makeStyles({
  table: {
    width: "100%",
    overflowX: "auto"
  },
  header: {
    display: "flex",
    alignItems: "center"
  }
});

export default function Paths({ paths, deletePath }) {
  const classes = useStyles();

  return (
    <Page
      title="Paths"
      crumbs={[
        {
          name: "Paths",
          href: "/paths"
        }
      ]}
    >
      <Button
        color="primary"
        variant="contained"
        href={`/paths/create`}
        style={{ marginBottom: 20 }}
      >
        New Path
      </Button>
      <Paper className={classes.table}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Path Name</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {paths.map(path => (
              <TableRow key={path.id}>
                <TableCell component="th" scope="row">
                  <Link href={`/paths/${path.id}`}>{path.name}</Link>
                </TableCell>
                <TableCell component="th" scope="row">
                  <Link href={`/paths/edit/${path.id}`}>Edit</Link>
                  &nbsp;
                  <Link href="#" onClick={() => deletePath(path.id)}>
                    Delete
                  </Link>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Paper>
    </Page>
  );
}
