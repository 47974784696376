import { connect, initializable } from "effects";
import * as functions from "./functions";
import AccommodationPublish from "./AccommodationPublish";

const mapStateToProps = (state, ownProps, getState) => {
  return {
    loading: getState("accommodationpublish.loading", true),
    accommodation: getState("accommodationpublish.accommodation"),
    path: getState("accommodationpublish.path"),
    city: getState("accommodationpublish.city"),
    pathPoints: getState("app.pathPoints")
  };
};

export default connect(
  initializable(AccommodationPublish),
  mapStateToProps,
  functions
);
