const { get } = require("object-path");

function calculateHeight(post, width) {
  return Math.floor(width * getRatio(post));
}

function getRatio(post) {
  return get(post, "related.media.0.ratio");
}

function isVideo(post) {
  return get(post, "related.media.0.fileType") === "video";
}

function isVideoFile(filename) {
  if (!filename) return false;
  var ext = filename.split(".").pop();
  const videoFileExtensions = ["mov", "mp4", "ogv", "avi", "mov", "flv", "3gp"];
  return videoFileExtensions.includes(ext.toLowerCase());
}

function buildVimeoVideoUrl(post, width, height) {
  const pictureId = get(post, "related.media.0.meta.pictureId");
  return `https://i.vimeocdn.com/video/${pictureId}_${width}x${height}.jpg`;
}

function getImageUrl(post) {
  return get(post, "_meta.photoUrl");
}

function getHeight(width, post) {
  const ratio = get(post, "related.media.0.ratio");
  return width * ratio || 0;
}

function hasMedia(post) {
  return !!get(post, "related.media.0");
}

function getPreviewUrl(post, width = 500) {
  const height = calculateHeight(post, width);

  const url = isVideo(post)
    ? buildVimeoVideoUrl(post, width, height)
    : getImageUrl(post);

  return url;
}

module.exports = {
  calculateHeight,
  getRatio,
  isVideo,
  buildVimeoVideoUrl,
  getImageUrl,
  getHeight,
  hasMedia,
  getPreviewUrl,
  isVideoFile
};
