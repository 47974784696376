import { connect, initializable } from "effects";
import * as functions from "./functions";
import RestaurantPublish from "./RestaurantPublish";

const mapStateToProps = (state, ownProps, getState) => {
  return {
    loading: getState("restaurantpublish.loading", true),
    restaurant: getState("restaurantpublish.restaurant"),
    path: getState("restaurantpublish.path"),
    city: getState("restaurantpublish.city"),
    pathPoints: getState("app.pathPoints")
  };
};

export default connect(
  initializable(RestaurantPublish),
  mapStateToProps,
  functions
);
