import {cmds} from "effects";
import {get} from "object-path";

export function* setup(props) {
  const pathId = get(props, "match.params.pathId");
  const {path} = yield cmds.rpc("GetPathByID", {id: pathId});
  yield cmds.setState("contributions.path", path);

  const {contributions} = yield cmds.rpc("GetContributionsForPath", {
    pathId,
  });

  yield cmds.setState("contributions.list", contributions);
  yield cmds.setState("contributions.loading", false);
}

export function* deleteContribution(id) {
  const confirmed = yield cmds.confirm(
    "Are you sure you want to delete this contribution?"
  );
  if (!confirmed) return;

  const contributions = yield cmds.getState("contributions.list", []);
  yield cmds.setState(
    "contributions.list",
    contributions.filter(p => p.id !== id)
  );
  yield cmds.rpc("DeleteContribution", {id});
}
