import { cmds } from "effects";
import assert from "assert";

export function* setup({ pathId, currentIndex = 0, cityId }) {
  assert(pathId, "pathId required");
  assert(cityId, "cityId required");

  yield cmds.setState(`accommodationselect_${currentIndex}_${cityId}.loading`, true);
  const cities = yield cmds.rpc("ListAccommodations", {
    pathId,
    cityId,
    page: 0,
    pageLength: 1000,
  });

  const map = cities.reduce((p, c) => {
    return {
      ...p,
      [c.id]: c,
    };
  }, {});

  yield cmds.setState(`accommodationselect_${currentIndex}_${cityId}.options`, cities);
  yield cmds.setState(`accommodationselect_${currentIndex}_${cityId}.map`, map);
  yield cmds.setState(`accommodationselect_${currentIndex}_${cityId}.loading`, false);
}
