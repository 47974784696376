import React from "react";
import Loading from "components/common/AppLoading";
import Paper from "@material-ui/core/Paper";
import Page from "components/common/Page";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import HotelIcon from "@material-ui/icons/Hotel";
import RestaurantIcon from "@material-ui/icons/Restaurant";
import CaminoListItem from "components/common/ListItem";

export default function TagDashboard({ tag, path, loading }) {
  if (loading) return <Loading />;

  return (
    <Page
      path={path}
      title={path.name}
      crumbs={[
        {
          name: "Tags",
          href: "/tags"
        }
      ]}
    >
      <Paper>
        <List>
          <CaminoListItem
            path={path}
            icon={<HotelIcon />}
            label="Accommodation Restrictions"
            description="Manage accommodation restrictions"
            url={`/paths/${path.id}/tags/accommodation-restrictions?title=Accommodation Restrictions`}
          />

          <Divider variant="inset" component="li" />

          <CaminoListItem
            path={path}
            icon={<HotelIcon />}
            label="Accommodation Room Configurations"
            description="Manage accommodation room configurations"
            url={`/paths/${path.id}/tags/accommodation-room-configs?title=Accommodation Room Configurations`}
          />

          <Divider variant="inset" component="li" />

          <CaminoListItem
            path={path}
            icon={<HotelIcon />}
            label="Accommodation Reservation Options"
            description="Manage accommodation reservation options"
            url={`/paths/${path.id}/tags/accommodation-reservation-options?title=Accommodation Reservation Options`}
          />

          <Divider variant="inset" component="li" />

          <CaminoListItem
            path={path}
            icon={<HotelIcon />}
            label="Accommodation Amenities"
            description="Manage accommodation amenities"
            url={`/paths/${path.id}/tags/accommodation-amenities?title=Accommodation Amenities`}
          />

          <Divider variant="inset" component="li" />

          <CaminoListItem
            path={path}
            icon={<RestaurantIcon />}
            label="Restaurant Food Types"
            description="Manage restaurant food types"
            url={`/paths/${path.id}/tags/restaurant-food-types?title=Restaurant Food Types`}
          />

          <Divider variant="inset" component="li" />

          <CaminoListItem
            path={path}
            icon={<RestaurantIcon />}
            label="Restaurant Reservation Options"
            description="Manage restaurant reservation options"
            url={`/paths/${path.id}/tags/restaurant-reservation-options?title=Restaurant Reservation Options`}
          />

          <Divider variant="inset" component="li" />

          <CaminoListItem
            path={path}
            icon={<RestaurantIcon />}
            label="Restaurant Eatery Types"
            description="Manage restaurant eatery types"
            url={`/paths/${path.id}/tags/restaurant-eatery-types?title=Restaurant Eatery Types`}
          />

          <Divider variant="inset" component="li" />

          <CaminoListItem
            path={path}
            icon={<RestaurantIcon />}
            label="Restaurant Diet Options"
            description="Manage restaurant diet options"
            url={`/paths/${path.id}/tags/restaurant-diet-options?title=Restaurant Diet Options`}
          />
        </List>
      </Paper>
    </Page>
  );
}
