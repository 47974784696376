import { connect, initializable } from "effects";
import * as functions from "./functions";
import POIList from "./POIList";
import { get } from "object-path";

const mapStateToProps = (state, ownProps, getState) => {
  const pathId = get(ownProps, "match.params.pathId");
  return {
    loading: getState("pois.loading", true),
    path: getState("pois.path"),
    city: getState("pois.city"),
    pois: getState("pois.list", []),
    pathPoints: getState("app.pathPoints"),
    pathId
  };
};

export default connect(initializable(POIList), mapStateToProps, functions);
