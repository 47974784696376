import { connect, initializable } from "effects";
import * as functions from "./functions";
import PlannerList from "./PlannerList";
import { get } from "object-path";

const mapStateToProps = (state, ownProps, getState) => {
  const pathId = get(ownProps, "match.params.pathId");
  const categoryId = get(ownProps, "match.params.categoryId");
  return {
    loading: getState("planners.loading", true),
    path: getState("app.path"),
    planners: getState("planners.list", []),
    plannerCategoryDetail: getState("planners.plannerCategoryDetail", {}),
    pathId,
    categoryId,
  };
};

export default connect(initializable(PlannerList), mapStateToProps, functions);
