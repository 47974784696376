import { connect, initializable } from "effects";
import * as functions from "./functions";
import AccommodationList from "./AccommodationList";
import { get } from "object-path";

const mapStateToProps = (state, ownProps, getState) => {
  const pathId = get(ownProps, "match.params.pathId");
  return {
    loading: getState("accommodations.loading", true),
    path: getState("accommodations.path"),
    city: getState("accommodations.city"),
    accommodations: getState("accommodations.list", []),
    pathPoints: getState("app.pathPoints"),
    pathId
  };
};

export default connect(initializable(AccommodationList), mapStateToProps, functions);
