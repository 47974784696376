import { cmds } from "effects";
import { get } from "object-path";

export function* setup(props) {
  const pathId = get(props, "match.params.pathId");
  const categoryId = get(props, "match.params.categoryId");

  const { path } = yield cmds.rpc("GetPathByID", { id: pathId });
  yield cmds.setState("app.path", path);

  const { plannersByCategories } = yield cmds.rpc("GetPlannersByCategories", {
    pathId,
    page: 0,
    pageLength: 1000,
    plannerCategoryId: categoryId,
  });

  let planners = [];
  let plannerCategoryDetail;

  if (plannersByCategories && plannersByCategories.length > 0) {
    planners = plannersByCategories[0].planners;
    plannerCategoryDetail = plannersByCategories[0].plannerCategoryDetail;
  }

  //   const plannersWithDefault = planners.map((c) => {
  //     return c;
  //   });

  yield cmds.setState("planners.list", planners);
  yield cmds.setState("planners.plannerCategoryDetail", plannerCategoryDetail);
  yield cmds.setState("planners.loading", false);
}

export function* deletePlanner(id) {
  const confirmed = yield cmds.confirm(
    "Are you sure you want to delete this planner?"
  );
  if (!confirmed) return;

  const planners = yield cmds.getState("planners.list", []);
  yield cmds.setState(
    "planners.list",
    planners.filter((p) => p.id !== id)
  );
  yield cmds.rpc("DeletePlanner", { id });
}
