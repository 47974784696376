import React, { useState } from "react";
import { makeStyles, Typography } from "@material-ui/core";
import { Formik, Form } from "formik";
import Button from "@material-ui/core/Button";
import AppLoading from "components/common/AppLoading";
import TextField from "components/common/form/TextField";
import FormHelperText from "@material-ui/core/FormHelperText";
import Page from "components/common/Page";
import Paper from "components/common/Paper";
import Section from "components/common/form/Section";
import CancelButton from "components/common/form/CancelButton";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

const useStyles = makeStyles({
    quillEditor: {
        '& .ql-editor img': {
            maxWidth: '40%',
            height: 'auto',
        },
    },
});

export default function QAPublish({ loading, faq, path, guide, save, validate }) {
    const classes = useStyles();

    if (loading) return <AppLoading />;

    const modules = {
        toolbar: [
            [{ 'header': '1' }, { 'header': '2' }, { 'font': [] }],
            [{ 'list': 'ordered' }, { 'list': 'bullet' }],
            ['bold', 'italic', 'underline'],
            [{ 'align': [] }],
            ['link'],
            ['image', 'video'], // Add image and video options
            ['clean']
        ],
        clipboard: {
            matchVisual: false,
        },
    };

    const formats = [
        'header',
        'font',
        'list',
        'bold',
        'italic',
        'underline',
        'align',
        'link',
        'image', // Add image format
        'video'  // Add video format
    ];

    return (
        <Page
            path={path}
            title={faq.id ? "Edit QA " : "New QA"}
            crumbs={[
                {
                    name: "guide",
                    href: "/guide"
                },
                {
                    name: guide.title,
                    href: "/guide"
                },
                {
                    name: "faqs",
                    href: `/guide/${guide.id}/faqs`
                },
                {
                    name: "QA"
                }
            ]}
        >

            <Paper>
                <Formik
                    initialValues={faq}
                    validate={validate}
                    onSubmit={async (values, { setSubmitting }) => {
                        await save(values);
                        setSubmitting(false);
                    }}
                    noValidate
                >
                    {({
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        isSubmitting,
                        setFieldValue
                    }) => (
                        <Form onSubmit={handleSubmit}>
                            <Section title="Details">
                                <Typography style={{ marginBottom: "-5px" }} >Question</Typography>
                                <TextField
                                    label="Question"
                                    name="question"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.question || ""}
                                    error={Boolean(errors.question)}
                                    helperText={errors.question}
                                />
                            </Section>

                            <Typography style={{ marginBottom: "10px" }}>Answer</Typography>

                            <div style={{ marginBottom: "20px" }}>
                                <ReactQuill
                                    className={classes.quillEditor}
                                    value={values.answerContent}
                                    onChange={(content) => {
                                        if (content === '<p><br></p>') {
                                            content = '';
                                        }
                                        setFieldValue("answerContent", content);
                                    }}
                                    modules={modules}
                                    formats={formats}
                                />

                                {errors.answerContent &&
                                    <FormHelperText error={Boolean(errors.answerContent)} style={{ marginLeft: "14px" }}>
                                        {errors.answerContent}
                                    </FormHelperText>
                                }
                            </div>
                            <Section title="Save Changes">
                                <Button
                                    variant="contained"
                                    color="primary"
                                    type="submit"
                                    disabled={isSubmitting}
                                    style={{ marginRight: 6 }}
                                >
                                    Save
                                </Button>
                                <CancelButton location={`/paths/${path.id}/guide/${guide.id}/faqs`} />
                            </Section>
                        </Form>
                    )}
                </Formik>
            </Paper>
        </Page>
    );
}
