import React from "react";
import Map from "./Map";
import TextField from "components/common/form/TextField";
import TextFieldMaterial from "@material-ui/core/TextField";

export default class Location extends React.Component {
  pasteFromGoogle(e) {
    const { setFieldValue } = this.props;

    const name = this.getFieldName();

    const parts = e.target.value
      .split(",")
      .map(v => v.trim())
      .map(v => Number(v));

    const invalid = parts.some(p => isNaN(p));
    if (invalid) {
      alert("Could not set invalid coordinates");
      e.target.value = "";
      return;
    }

    setFieldValue(`${name}.lat`, parts[0]);
    setFieldValue(`${name}.lng`, parts[1]);
  }

  getFieldName() {
    return this.props.name || "location";
  }

  render() {
    const { values, pathPoints, zoom } = this.props;

    const name = this.getFieldName();

    return (
      <div>
        <div>
          <TextField
            label="Latitude"
            name={`${name}.lat`}
            type="number"
            defaultValue={0}
            fullWidth={false}
          />
          &nbsp;
          <TextField
            label="Longitude"
            name={`${name}.lng`}
            type="number"
            defaultValue={0}
            fullWidth={false}
          />
          &nbsp;
          <TextFieldMaterial
            label="Paste from Google"
            variant="outlined"
            fullWidth={false}
            margin="normal"
            type="text"
            onChange={e => this.pasteFromGoogle(e)}
          />
        </div>

        <Map location={values[name]} pathPoints={pathPoints} zoom={zoom} />
      </div>
    );
  }
}
