import React from "react";
import Autocomplete from "@material-ui/lab/Autocomplete";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import TextField from "@material-ui/core/TextField";
import { Field } from "formik";

export default function ({
  name,
  loading,
  options,
  map,
  onChange,
  onBlur,
  value,
}) {
  return (
    <Field name={name}>
      {({ field, form, meta }) => {
        // const fieldValue = map[field.value] || map[value];
        // Support Multiple Selection During EDIT
        let fieldValue = [];
        field.value && field.value.map(e => {
          if (map[e]) {
            fieldValue.push(map[e]);
          }
        });
        if (loading)
          return (
            <FormControl fullWidth margin="normal">
              <Autocomplete
                multiple
                key="loading"
                loading={true}
                loadingText="Loading POIs..."
                disabled={true}
                options={[]}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Loading POIs..."
                    variant="outlined"
                    fullWidth
                  />
                )}
              />
            </FormControl>
          );
        return (
          <FormControl fullWidth margin="normal" error={Boolean(meta.error)}>
            <Autocomplete
              multiple
              loading={loading}
              loadingText="Loading POIs..."
              options={options}
              getOptionLabel={(option) => {
                return option.name;
              }}
              value={fieldValue}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Select POI"
                  variant="outlined"
                  fullWidth
                />
              )}
              onChange={(e, v) => {
                const result = form.setFieldValue(name, [...v.map((option) => option.id)]);
                if (onChange) onChange(v);
                return result;
              }}
              onClose={field.onBlur}
            />
            {meta.error && <FormHelperText>{meta.error}</FormHelperText>}
          </FormControl>
        );
      }}
    </Field>
  );
}
