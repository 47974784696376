import React from "react";
import TextField from "../../common/form/TextField";
import Switch from "../../common/form/Switch";
import Media from "../../common/form/Media";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import {makeStyles} from "@material-ui/core/styles";
import Link from "@material-ui/core/Link";

const useStyles = makeStyles({
    form: {
        paddingBottom: 30,
        marginBottom: 30,
        borderBottom: "1px solid #ccc"
    },
    deleteContainer: {
        textAlign: "right",
    }
});

export default function AlternateRouteForm({path, route, removeAltRoute, setFieldValue}) {
    const classes = useStyles();

    return (
        <div className={classes.form}>
            <div className={classes.deleteContainer}>
                <Link style={{color: "red", cursor: "pointer"}} onClick={() => {
                    const yes = window.confirm("Are you sure you want to remove this alternate route?")
                    if (!yes) return;
                    setFieldValue(`alternateRoutes.${route.id}`, undefined);
                }}>remove alternate route</Link>
            </div>
            <TextField label="Name" name={fieldName(route, "name")}/>
            <TextField label="Description" name={fieldName(route, "description")} multiline/>
            <FormControlLabel
                control={
                    <Switch
                        name={fieldName(route, "green")}
                        color="primary"
                    />
                }
                label="Green"
            />
            <div>
                <Media name={fieldName(route, "media")} pathId={path.id}/>
            </div>
        </div>
    );
}

function fieldName(route, name) {
    return `alternateRoutes.${route.id}.${name}`
}