import { connect, initializable } from "effects";
import * as functions from "./functions";
import StagePublish from "./StagePublish";

const mapStateToProps = (state, ownProps, getState) => {
  return {
    loading: getState("stagePublish.loading", true),
    stage: getState("stagePublish.stage"),
    path: getState("stagePublish.path"),
    points: getState("stagePublish.points"),
    center: getState("stagePublish.center")
  };
};

export default connect(
  initializable(StagePublish),
  mapStateToProps,
  functions
);
