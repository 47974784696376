import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import Section from "components/common/form/Section";
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
    modal: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: "600px",
        backgroundColor: '#f4f4f4',
        maxHeight: '50vh',
        border: "0px",
        padding: "20px 10px 10px 20px",
        boxShadow: "0 1px 5px 1px rgba(0, 0, 0, 0.14)",
        outline: 'none',
        overflowY: "scroll",
    },
    dayContainer: {
        marginBottom: "10px",
        borderBottom: "1px solid #ccc",
        paddingBottom: "10px",
    },
    image: {
        margin: "5px 15px 5px 5px",
    },
    imageDiv: {
        display: "flex",
        flexWrap: "wrap",
        width: "100%"
    },
    divider: {
        borderBottom: "1px solid #ddd",
        marginTop: "-10px",
        border: "none"
    },
    category: {
        fontSize: "18px",
        marginTop: "-8px"
    },
});

export default function PlannerModal({ open, setOpen, planners, selectedPlannerIndex, plannerCategoryDetail }) {
    const classes = useStyles();
    const selectedPlanner = planners[selectedPlannerIndex];
    const handleClose = () => setOpen(false);

    return (
        <div>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box className={classes.modal}>
                    <Section title="Details">
                        <div className={classes.category}>
                            <Typography>
                                Category: {plannerCategoryDetail.title}
                            </Typography>
                        </div>

                        <div>
                            <Typography>
                                Planner Name: {selectedPlanner.title}
                            </Typography>
                        </div>
                    </Section>
                    <hr className={classes.divider} />

                    <div style={{ marginTop: "17px" }}>
                        <Section title="Day Details">
                            <Typography>
                                <b>Total Days</b>: {selectedPlanner.totalDays}
                            </Typography>

                            {selectedPlanner.dayDetails.map((day, index) => (
                                <div key={index} className={classes.dayContainer}>
                                    <Typography variant="subtitle1" style={{ color: '#333' }}>
                                        <b>Day {day.dayNumber}</b>: {day.startCityDetail.name}
                                        <span style={{ color: "gray", margin: "0px 10px" }}>to</span>
                                        {day.endCityDetail.name}
                                    </Typography>

                                    <Typography variant="body2" style={{ color: '#777' }}>
                                        Distance: {(day.endCityDetail.distanceFromOriginInMeters / 1000).toFixed(1)} km
                                    </Typography>

                                    {/* <Typography variant="body2">
                                        Description: {day.discription}
                                    </Typography> */}
                                </div>
                            ))}
                        </Section>

                        {selectedPlanner.media.length > 0 &&
                            <Section title="Media">
                                <div className={classes.imageDiv}>
                                    {selectedPlanner.media.map((media, mediaIndex) => (
                                        <img
                                            key={mediaIndex}
                                            alt={`Media ${mediaIndex}`}
                                            src={`${process.env.REACT_APP_IMGIX_URL}${media.filePath}?h=70&&w=100`}
                                            width={140}
                                            height={140}
                                            className={classes.image}
                                        />
                                    ))}
                                </div>
                            </Section>
                        }
                    </div>
                </Box>
            </Modal>
        </div>
    );
}
