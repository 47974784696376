import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Link from "@material-ui/core/Link";
import AppLoading from "components/common/AppLoading";
import Page from "components/common/Page";

const useStyles = makeStyles({
  table: {
    width: "100%",
    overflowX: "auto"
  },
  header: {
    display: "flex",
    alignItems: "center"
  }
});

export default function Contributions({
  loading,
  contributions,
  path,
  pathId,
  deleteContribution,
  pathPoints
}) {
  const classes = useStyles();

  if (loading) return <AppLoading />;

  return (
    <Page
      path={path}
      title="Contributions"
      crumbs={[
        {
          name: "Contributions",
          href: "/contributions"
        }
      ]}
    >
      <Paper className={classes.table}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Place</TableCell>
              <TableCell>User</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {contributions.map(contribution => (
              <TableRow key={contribution.id}>
                <TableCell component="th" scope="row">
                  {contribution.placeName}
                </TableCell>
                <TableCell component="th" scope="row">
                  {contribution.userName}
                </TableCell>
                <TableCell component="th" scope="row">
                  <ContributionStatus status={contribution.status} />
                </TableCell>
                <TableCell component="th" scope="row">
                  <Link href={`/paths/${pathId}/contributions/edit/${contribution.id}`}>
                    View
                  </Link>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Paper>
    </Page>
  );
}

function ContributionStatus({ status }) {
  switch (status) {
    case "pending":
      return "Pending";
    case "denied":
      return <span style={{ color: "red" }}>Denied</span>
    case "approved":
      return "Approved"
    default:
      return "Unknown :-("
  }
}
