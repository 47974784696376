import { cmds } from "effects";
import {Polyline} from "google-maps-react";
import React from "react";

export function* getPathPoints(pathId) {
  const key = `pathRoutes${pathId}`
  const cached = yield cmds.getLocal(key);
  if (cached) return cached;

  const routes = yield cmds.rpc("GetAllRoutePointLocations", {
    pathId
  });

  yield cmds.setLocal(key, routes);

  return routes;
}

export function getPlaceLink(placeType, placeId) {
  return `${process.env.REACT_APP_RPC_URL}/redirect?type=${placeType}&id=${placeId}&basePath=${window.location.protocol}//${window.location.host}`;
}

export function renderAlternateRoutes(routes) {
  let out = []
  for (let id in routes) {
    out.push(<Polyline
        key={id}
        path={routes[id].points}
        strokeColor="#52d600"
        strokeOpacity={0.8}
        strokeWeight={2}
    />)
  }
  return out
}