import React from "react";
import Button from "@material-ui/core/Button";
import TextField from "components/common/form/TextField";
import AppLoading from "components/common/AppLoading";
import { Formik } from "formik";
import Page from "components/common/Page";
import Paper from "components/common/Paper";
import Section from "components/common/form/Section";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Select from "components/common/form/Select";
import { StandardPOIFields } from "components/common/form/StandardPOIFields";
import TagSelect from "components/common/form/TagSelect";
import HourRange from "components/common/form/HourRange";
import CancelButton from "components/common/form/CancelButton";

const types = [
  "Albergue",
  "Home",
  "Hostal",
  "Hotel",
  "Camping",
  "Apartment",
  "Pension"
];

const subTypes = {
  hotel: ["Standard", "Parador", "Hotel Rural", "Casa Rural"],
  accommodation: [
    "Municiple",
    "Xunta",
    "Parochial Albergue",
    "Association",
    "Private Albergue"
  ]
};

export default function Accommodations({
  loading,
  accommodation,
  onChangeName,
  save,
  validate,
  path,
  city,
  pathPoints
}) {
  if (loading) return <AppLoading />;
  return (
    <Page
      path={path}
      title={
        accommodation.name
          ? "Edit " + accommodation.name
          : "New Accommodation in " + city.name
      }
      crumbs={[
        {
          name: "Cities and Towns",
          href: "/cities"
        },
        {
          name: city.name,
          href: `/cities/${city.id}`
        },
        {
          name: "Accommodations",
          href: `/cities/${city.id}/accommodations`
        },
        {
          name: accommodation.name || "New Accommodations"
        }
      ]}
    >
      <Paper>
        <Formik
          initialValues={accommodation}
          validate={validate}
          onSubmit={async (values, { setSubmitting }) => {
            await save(values);
            setSubmitting(false);
          }}
          validateOnBlur={false}
          validateOnChange={false}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            setFieldValue
          }) => (
            <form onSubmit={handleSubmit} autoComplete="off" noValidate>
              <StandardPOIFields
                setFieldValue={setFieldValue}
                path={path}
                city={city}
                handleChange={handleChange}
                handleBlur={handleBlur}
                values={values}
                errors={errors}
                pathPoints={pathPoints}
              />
              <Section title="Accommodation Information">
                <FormControl variant="outlined" fullWidth margin="normal">
                  <InputLabel id="accommodation-type-label" style={{ width: 170 }}>
                    Accommodation Type
                  </InputLabel>
                  <Select
                    labelWidth={170}
                    name="type"
                    labelId="accommodation-type-label"
                    id="accommodation-type"
                    value={values.type || ""}
                    onChange={e => {
                      setFieldValue("subType", "");
                      setFieldValue("type", e.target.value);
                    }}
                    fullWidth
                  >
                    {types.map(t => (
                      <MenuItem key={t.toLowerCase()} value={t.toLowerCase()}>
                        {t}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>

                {(values.type === "hotel" || values.type === "accommodation") && (
                  <FormControl variant="outlined" fullWidth margin="normal">
                    <InputLabel id="accommodation-type-label" style={{ width: 200 }}>
                      Accommodations Subtype
                    </InputLabel>
                    <Select
                      labelWidth={200}
                      name="subType"
                      labelId="accommodation-type-label"
                      id="accommodation-type"
                      value={values.subType || ""}
                      onChange={handleChange}
                    >
                      {subTypes[values.type].map(t => (
                        <MenuItem key={t.toLowerCase()} value={t.toLowerCase()}>
                          {t}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                )}

                <TextField
                  label="Price range"
                  type="number"
                  name="price"
                  defaultValue={0}
                />

                <TextField
                  label="Beds Available"
                  type="number"
                  name="bedsAvailable"
                  defaultValue={0}
                />

                <TextField label="Booking URL" name="bookingUrl" />

                <FormControl fullWidth margin="normal">
                  <TagSelect
                    pathId={path.id}
                    setName="accommodation-restrictions"
                    label="Restrictions"
                    name="restrictions"
                  />
                </FormControl>

                <FormControl fullWidth margin="normal">
                  <TagSelect
                    pathId={path.id}
                    setName="accommodation-room-configs"
                    label="Room Config"
                    name="roomConfig"
                  />
                </FormControl>

                <FormControl fullWidth margin="normal">
                  <TagSelect
                    pathId={path.id}
                    setName="accommodation-reservation-options"
                    label="How to Reserve"
                    name="howToReserve"
                  />
                </FormControl>

                <FormControl fullWidth margin="normal">
                  <TagSelect
                    pathId={path.id}
                    setName="accommodation-amenities"
                    label="Amenities"
                    name="amenities"
                  />
                </FormControl>

                {/*<FormControl fullWidth margin="normal">
                  <Tags
                    label="Amenities"
                    name="amenities"
                    options={[
                      { label: "Laundry", value: "laundry" },
                      { label: "Wifi", value: "wifi" },
                      { label: "Cafe", value: "cafe" },
                      { label: "Kitchen", value: "kitchen" },
                      { label: "Linens", value: "linens" },
                      {
                        label: "Exclusively for pilgrims",
                        value: "exclusively-for-pilgrims"
                      },
                      {
                        label: "Family style meal",
                        value: "family-style-meal"
                      },
                      {
                        label: "Vegetarian meal option",
                        value: "vegetarian-meal-option"
                      }
                    ]}
                  />
                </FormControl>*/}
              </Section>

              <Section title="Check-in / Check-out">
                <HourRange
                  startLabel="Check-in"
                  startName="checkInTime"
                  startLabelWidth={70}
                  endLabel="Check-out"
                  endName="checkOutTime"
                  endLabelWidth={75}
                />
              </Section>

              <Section title="Save Changes">
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  disabled={isSubmitting}
                  style={{ marginRight: 6 }}
                >
                  Save
                </Button>
                <CancelButton
                  location={`/paths/${path.id}/cities/${city.id}/accommodations`}
                />
              </Section>
            </form>
          )}
        </Formik>
      </Paper>
    </Page>
  );
}
