import { connect, initializable } from "effects";
import * as functions from "./functions";
import PlannerCategoryPublish from "./PlannerCategoryPublish";

const mapStateToProps = (state, ownProps, getState) => {
  return {
    loading: getState("plannerCategoryPublish.loading", true),
    plannerCategory: getState("plannerCategoryPublish.plannerCategory"),
    path: getState("plannerCategoryPublish.path"),
  };
};

export default connect(
  initializable(PlannerCategoryPublish),
  mapStateToProps,
  functions
);
