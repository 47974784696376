import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  withRouter
} from "react-router-dom";
import Login from "./components/Login";
import Home from "./components/Home";
import AbuseReportsList from "./components/AbuseReports/AbuseReportsList";
import AbuseReportView from "./components/AbuseReports/AbuseReportView";
import PlaceProblemReportsList from "./components/PlaceProblemReports/PlaceProblemReportsList";
import ImportData from "./components/ImportData";
import PathsList from "./components/Paths/PathsList";
import PathPublish from "./components/Paths/PathPublish";
import PathDashboard from "./components/Paths/PathDashboard";
import CitiesList from "./components/Cities/CityList";
import CityPublish from "./components/Cities/CityPublish";
import CityDashboard from "./components/Cities/CityDashboard";
import AccommodationList from "./components/Accommodations/AccommodationList";
import AccommodationPublish from "./components/Accommodations/AccommodationPublish";
import RestaurantList from "./components/Restaurants/RestaurantList";
import RestaurantPublish from "./components/Restaurants/RestaurantPublish";
import POIList from "./components/POIs/POIList";
import POIPublish from "./components/POIs/POIPublish";
import StageList from "./components/Stages/StageList";
import StagePublish from "./components/Stages/StagePublish";
import TagsDashboard from "./components/Tags/TagsDashboard";
import TagsList from "./components/Tags/TagsList";
import Search from "./components/Search";
import GuidePublish from "./components/CaminoGuide/GuidePublish";
import GuideList from "./components/CaminoGuide/GuideList";
import ContributionList from "./components/Contributions/ContributionList";
import ContributionPublish from "./components/Contributions/ContributionPublish";
import FAQs from "./components/CaminoGuide/FAQs";
import QAPublish from "components/CaminoGuide/QAPublish";
import PlannerCategoryList from "./components/Planners/PlannerCategoryList";
import PlannerCategoryPublish from "./components/Planners/PlannerCategoryPublish";
import PlannerList from "./components/Planners/PlannerList";
import PlannerPublish from "./components/Planners/PlannerPublish";

import { connect, initializable } from "effects";
import * as functions from "./functions";
import Loading from "components/common/AppLoading";

const App = function App({ loading, loggedIn }) {
  if (loading) {
    return <Loading />;
  }

  if (!loggedIn) {
    return <Login />;
  }
  return (
    <Router>
      <Switch>
        {/* Accommodations */}
        <Route path="/paths/:pathId/cities/:cityId/accommodations/edit/:accommodationId">
          {withRouter(props => (
            <AccommodationPublish {...props} />
          ))}
        </Route>
        <Route path="/paths/:pathId/cities/:cityId/accommodations/create">
          {withRouter(props => (
            <AccommodationPublish {...props} />
          ))}
        </Route>
        <Route path="/paths/:pathId/cities/:cityId/accommodations">
          {withRouter(props => (
            <AccommodationList {...props} />
          ))}
        </Route>

        {/* Restaurants */}
        <Route path="/paths/:pathId/cities/:cityId/restaurants/edit/:restaurantId">
          {withRouter(props => (
            <RestaurantPublish {...props} />
          ))}
        </Route>
        <Route path="/paths/:pathId/cities/:cityId/restaurants/create">
          {withRouter(props => (
            <RestaurantPublish {...props} />
          ))}
        </Route>
        <Route path="/paths/:pathId/cities/:cityId/restaurants">
          {withRouter(props => (
            <RestaurantList {...props} />
          ))}
        </Route>

        {/* POIs */}
        <Route path="/paths/:pathId/cities/:cityId/pois/edit/:poiId">
          {withRouter(props => (
            <POIPublish {...props} />
          ))}
        </Route>
        <Route path="/paths/:pathId/cities/:cityId/pois/create">
          {withRouter(props => (
            <POIPublish {...props} />
          ))}
        </Route>
        <Route path="/paths/:pathId/cities/:cityId/pois">
          {withRouter(props => (
            <POIList {...props} />
          ))}
        </Route>

        {/* Cities */}
        <Route path="/paths/:pathId/cities/edit/:cityId">
          {withRouter(props => (
            <CityPublish {...props} />
          ))}
        </Route>
        <Route path="/paths/:pathId/cities/create">
          {withRouter(props => (
            <CityPublish {...props} />
          ))}
        </Route>
        <Route path="/paths/:pathId/cities/:cityId">
          {withRouter(props => (
            <CityDashboard {...props} />
          ))}
        </Route>
        <Route path="/paths/:pathId/cities">
          {withRouter(props => (
            <CitiesList {...props} />
          ))}
        </Route>

        {/* Tags */}
        <Route path="/paths/:pathId/tags/:setName">
          {withRouter(props => (
            <TagsList {...props} />
          ))}
        </Route>
        <Route path="/paths/:pathId/tags">
          {withRouter(props => (
            <TagsDashboard {...props} />
          ))}
        </Route>

        {/* Stages */}
        <Route path="/paths/:pathId/stages/edit/:stageId">
          {withRouter(props => (
            <StagePublish {...props} />
          ))}
        </Route>
        <Route path="/paths/:pathId/stages/create">
          {withRouter(props => (
            <StagePublish {...props} />
          ))}
        </Route>
        <Route path="/paths/:pathId/stages">
          {withRouter(props => (
            <StageList {...props} />
          ))}
        </Route>

        {/* Contributions */}
        <Route path="/paths/:pathId/contributions/edit/:contributionId">
          {withRouter(props => (
            <ContributionPublish {...props} />
          ))}
        </Route>
        <Route path="/paths/:pathId/contributions/create">
          {withRouter(props => (
            <ContributionPublish {...props} />
          ))}
        </Route>
        <Route path="/paths/:pathId/contributions">
          {withRouter(props => (
            <ContributionList {...props} />
          ))}
        </Route>


        {/* faq management */}
        <Route path="/paths/:pathId/guide/:guideId/faq/edit/:faqId">
          {withRouter(props => (
            <QAPublish  {...props} />
          ))}
        </Route>
        <Route path="/paths/:pathId/guide/:guideId/faqs/create">
          {withRouter(props => (
            <QAPublish  {...props} />
          ))}
        </Route>
        <Route path="/paths/:pathId/guide/:guideId/faqs">
          {withRouter(props => (
            <FAQs  {...props} />
          ))}
        </Route>

        {/* guide management */}
        <Route path="/paths/:pathId/guide/edit/:guideId">
          {withRouter(props => (
            <GuidePublish {...props} />
          ))}
        </Route>
        <Route path="/paths/:pathId/guide/create">
          {withRouter(props => (
            <GuidePublish {...props} />
          ))}
        </Route>
        <Route path="/paths/:pathId/guide">
          {withRouter(props => (
            <GuideList  {...props} />
          ))}
        </Route>

        {/* Import Data */}
        <Route path="/paths/:pathId/import-data/:entityType">
          {withRouter(props => (
            <ImportData {...props} />
          ))}
        </Route>

        {/* Search */}
        <Route path="/paths/:pathId/search">
          {withRouter(props => (
            <Search {...props} />
          ))}
        </Route>

        {/* Place Problem Reports */}
        <Route path="/paths/:pathId/place-problems">
          {withRouter(props => (
            <PlaceProblemReportsList {...props} />
          ))}
        </Route>

        {/* Planner-Categories*/}
        <Route path="/paths/:pathId/planner-categories/edit/:plannerCategoryId">
          {withRouter(props => (
            <PlannerCategoryPublish {...props} />
          ))}
        </Route>
        <Route path="/paths/:pathId/planner-categories/create">
          {withRouter(props => (
            <PlannerCategoryPublish {...props} />
          ))}
        </Route>
        <Route path="/paths/:pathId/planner-categories">
          {withRouter(props => (
            <PlannerCategoryList {...props} />
          ))}
        </Route>

        {/* Planners */}
        <Route path="/paths/:pathId/planners/:categoryId/edit/:plannerId">
          {withRouter(props => (
            <PlannerPublish {...props} />
          ))}
        </Route>
        <Route path="/paths/:pathId/planners/:categoryId/create">
          {withRouter(props => (
            <PlannerPublish {...props} />
          ))}
        </Route>
        <Route path="/paths/:pathId/planners/:categoryId">
          {withRouter(props => (
            <PlannerList {...props} />
          ))}
        </Route>
        <Route path="/paths/:pathId/planners">
          {withRouter(props => (
            <PlannerList {...props} />
          ))}
        </Route>

        {/* Paths */}
        <Route path="/paths/edit/:pathId">
          {withRouter(props => (
            <PathPublish {...props} />
          ))}
        </Route>
        <Route path="/paths/create">
          <PathPublish />
        </Route>
        <Route path="/paths/:pathId">
          {withRouter(props => (
            <PathDashboard {...props} />
          ))}
        </Route>
        <Route path="/paths">
          <PathsList />
        </Route>

        {/* Abuse Reports */}
        <Route path="/abuse-reports/:abuseReportId">
          {withRouter(props => (
            <AbuseReportView {...props} />
          ))}
        </Route>
        <Route path="/abuse-reports">
          <AbuseReportsList />
        </Route>

        {/* Home */}
        <Route path="/">
          <Home />
        </Route>
      </Switch>
    </Router>
  );
};

const mapStateToProps = (state, ownProps, prop) => {
  return {
    loading: prop("app.loading", true),
    loggedIn: prop("app.loggedIn", false)
  };
};

export default connect(initializable(App), mapStateToProps, functions);
