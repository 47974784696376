import { connect, initializable } from "effects";
import * as functions from "./functions";
import PathsList from "./PathsList";

const mapStateToProps = (state, ownProps, prop) => {
  return {
    paths: prop("paths.list", [])
  };
};

export default connect(initializable(PathsList), mapStateToProps, functions);
