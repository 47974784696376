import { connect, initializable } from "effects";
import * as functions from "./functions";
import PlannerCategoryList from "./PlannerCategoryList";
import { get } from "object-path";

const mapStateToProps = (state, ownProps, getState) => {
  const pathId = get(ownProps, "match.params.pathId");
  return {
    loading: getState("plannerCategories.loading", true),
    path: getState("app.path"),
    plannerCategories: getState("plannerCategories.list", []),
    pathId,
  };
};

export default connect(
  initializable(PlannerCategoryList),
  mapStateToProps,
  functions
);
