import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "components/common/Paper";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import { Formik } from "formik";
import { validate } from "./functions";

const useStyles = makeStyles({
  root: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "100vh"
  },
  formError: {
    color: "red"
  },
  logo: {
    width: 120
  },
  formHeader: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column"
  }
});

export default function Login({ login }) {
  const classes = useStyles();

  // console.log('--- Environment Variables ---')
  // console.log(`NODE_PATH: ${process.env.NODE_PATH}`);
  // console.log(`REACT_APP_RPC_URL: ${process.env.REACT_APP_RPC_URL}`);
  // console.log(`REACT_APP_FIREBASE_API_KEY: ${process.env.REACT_APP_FIREBASE_API_KEY}`);
  // console.log(`REACT_APP_FIREBASE_PROJECT_ID: ${process.env.REACT_APP_FIREBASE_PROJECT_ID}`);
  // console.log(`REACT_APP_FIREBASE_STORAGE_BUCKET: ${process.env.REACT_APP_FIREBASE_STORAGE_BUCKET}`);
  // console.log(`REACT_APP_GOOGLE_MAPS_API_KEY: ${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}`);
  // console.log(`REACT_APP_IMGIX_URL: ${process.env.REACT_APP_IMGIX_URL}`);
  // console.log(`REACT_APP_ALGOLIA_ID: ${process.env.REACT_APP_ALGOLIA_ID}`);
  // console.log(`REACT_APP_ALGOLIA_KEY: ${process.env.REACT_APP_ALGOLIA_KEY}`);
  // console.log(`REACT_APP_STORY_REVIEW_URL: ${process.env.REACT_APP_STORY_REVIEW_URL}`);

  return (
    <div className={classes.root}>
      <Paper>
        <Formik
          initialValues={{ username: "", password: "" }}
          validate={validate}
          onSubmit={async (values, { setSubmitting, setErrors }) => {
            const result = await login(values);
            if (!result.ok) {
              setErrors({ form: result.message });
            }
            setSubmitting(false);
          }}
          noValidate
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting
          }) => (
            <form onSubmit={handleSubmit}>
              <div className={classes.formHeader}>
                <img
                  src="/images/logo.png"
                  className={classes.logo}
                  alt="Camino Connections"
                />
                {errors.form && (
                  <div className={classes.formError}>{errors.form}</div>
                )}
              </div>

              <TextField
                label="Username"
                variant="outlined"
                fullWidth
                margin="normal"
                type="text"
                name="username"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.username || ""}
                error={Boolean(errors.username)}
                helperText={errors.username}
              />

              <TextField
                label="Password"
                variant="outlined"
                fullWidth
                margin="normal"
                type="password"
                name="password"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.password || ""}
                error={Boolean(errors.password)}
                helperText={errors.password}
              />
              <Button
                variant="contained"
                color="primary"
                type="submit"
                disabled={isSubmitting}
                fullWidth
              >
                Login
              </Button>
            </form>
          )}
        </Formik>
      </Paper>
    </div>
  );
}
