import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Page from "components/common/Page";
import { Button } from "@material-ui/core";
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Typography } from "@material-ui/core";
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';

const useStyles = makeStyles({
    table: {
        width: "100%",
        overflowX: "auto"
    },
    header: {
        display: "flex",
        alignItems: "center"
    },
    maindiv: {
        display: "flex",
        justifyContent: "space-between",
        width: "100%"
    },
    icon: {
        height: "3vh !important",
        color: "gray",
        paddingTop: "5px",
        marginTop: "3px"
    },
    imageInContent: {
        width: "40%",
        height: "auto",
        display: "block",
        margin: "0",
    },
});

export default function FAQs({ path, loading, guide, pathId, faqlist, deleteFaqList }) {
    const classes = useStyles();
    const [expanded, setExpanded] = React.useState(false);

    const handleAccordionChange = panel => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };
    const onEdit = (e) => {
        e.stopPropagation();
    }

    return (
        <Page
            path={path}
            title="FAQs"
            crumbs={[
                {
                    name: "Guide",
                    href: "/guide"
                },
                {
                    name: guide.title,
                    href: '/guide'
                },
                {
                    name: "faqs"
                }
            ]}
        >
            <Button
                color="primary"
                variant="contained"
                href={`/paths/${pathId}/guide/${guide.id}/faqs/create`}
                style={{ marginBottom: 20 }}
            >
                New Q/A
            </Button>
            <div>
                {faqlist.map(faq => (
                    <Accordion key={faq.id} expanded={expanded === faq.id} onChange={handleAccordionChange(faq.id)}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls={`${faq.id}bh-content`}
                            id={`${faq.id}bh-header`}
                        >
                            <div className={classes.maindiv}>
                                <Typography sx={{ width: '33%', flexShrink: 0 }}>{faq.question}</Typography>

                                <div>
                                    <span onClick={(e) => deleteFaqList(e, faq.id)}> <DeleteOutlineIcon className={classes.icon} /> </span>
                                    <a href={`/paths/${pathId}/guide/${guide.id}/faq/edit/${faq.id}`} onClick={onEdit}> <ModeEditOutlineOutlinedIcon className={classes.icon} /> </a>
                                </div>
                            </div>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography>
                                {/* <div dangerouslySetInnerHTML={{ __html: faq.answerContent }} /> */}
                                <div
                                    dangerouslySetInnerHTML={{
                                        __html: faq.answerContent.replace(/<img/g, `<img class="${classes.imageInContent}"`),
                                    }}
                                />
                                {/* {faq.answerContent} */}
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                ))}
            </div>
        </Page>
    );
}
