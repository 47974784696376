import { connect, initializable } from "effects";
import * as functions from "./functions";
import { get } from "object-path";
import FAQs from "./FAQs";

const mapStateToProps = (state, ownProps, getState) => {
    const pathId = get(ownProps, "match.params.pathId");
    return {
        loading: getState("faq.loading", true),
        path: getState("faq.path"),
        faqlist: getState("faq.list", []),
        guide: getState("faq.guide", []),
        pathId
    };
};

export default connect(initializable(FAQs), mapStateToProps, functions);
