import algoliasearch from "algoliasearch";

const client = algoliasearch(
  process.env.REACT_APP_ALGOLIA_ID,
  process.env.REACT_APP_ALGOLIA_KEY
);
const index = client.initIndex("global");

export async function search({ term, pathId }) {
  if (!term.trim()) return [];

  const results = await index.search(term, {
    facetFilters: [[`pathID:${pathId}`]]
  });

  return results.hits;
}
