import { connect, initializable } from "effects";
import * as functions from "./functions";
import TagsList from "./TagsList";
import { get } from "object-path";
import queryString from "query-string";

const mapStateToProps = (state, ownProps, getState) => {
  const pathId = get(ownProps, "match.params.pathId");
  const setName = get(ownProps, "match.params.setName");
  const queryParams = queryString.parse(ownProps.location.search);

  return {
    loading: getState("tags.loading", true),
    path: getState("tags.path"),
    tags: getState("tags.list", []),
    formOpen: getState("tags.formOpen", false),
    edit: getState("tags.edit", {}),
    errors: getState("tags.errors", {}),
    pathId,
    setName,
    setTitle: queryParams.title
  };
};

export default connect(initializable(TagsList), mapStateToProps, functions);
