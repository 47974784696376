import React from "react";
import Switch from "@material-ui/core/Switch";
import { FastField } from "formik";

export default function SelectFast(props) {
  return (
    <FastField name={props.name}>
      {({ field, meta }) => {
        return (
          <Switch
            value={field.value}
            onChange={field.onChange}
            onBlur={field.onBlur}
            checked={Boolean(field.value)}
            {...props}
          >
            {props.children}
          </Switch>
        );
      }}
    </FastField>
  );
}
