import { cmds } from "effects";
import { get } from "object-path";
import { getPathPoints } from "components/helpers";

export function* setup(props) {
  const pathId = get(props, "match.params.pathId");
  const cityId = get(props, "match.params.cityId");
  const [{ path }, { city }] = yield [
    cmds.rpc("GetPathByID", { id: pathId }),
    cmds.rpc("GetCityByID", { id: cityId })
  ];

  yield cmds.setState("pois.path", path);
  yield cmds.setState("pois.city", city);

  const pois = yield cmds.rpc("ListPOIs", {
    pathId,
    cityId,
    page: 0,
    pageLength: 1000
  });
  yield cmds.setState("pois.list", pois);
  yield cmds.setState("pois.loading", false);

  const points = yield cmds.call(getPathPoints, pathId);
  yield cmds.setState("app.pathPoints", points);
}

export function* deletePOI(id) {
  const confirmed = yield cmds.confirm(
    "Are you sure you want to delete this poi?"
  );
  if (!confirmed) return;

  const pois = yield cmds.getState("pois.list", []);
  yield cmds.setState(
    "pois.list",
    pois.filter(p => p.id !== id)
  );
  yield cmds.rpc("DeletePOI", { id });
}
