import { connect, initializable } from "effects";
import * as functions from "./functions";
import TopMenu from "./TopMenu";

const mapStateToProps = (state, ownProps, getState) => {
  return {
    loggedIn: getState("app.loggedIn", false)
  };
};

export default connect(initializable(TopMenu), mapStateToProps, functions);
