import { cmds } from "effects";
import { get } from "object-path";
import { validateStandardPOIFields } from "components/common/form/StandardPOIFields";
import { getPathPoints } from "components/helpers";
import mongoid from "mongoid-js";
import { isURL } from "validator";

export function* setup(props) {
  yield cmds.setState("poipublish.loading", true);

  const pathId = get(props, "match.params.pathId");
  const cityId = get(props, "match.params.cityId");
  const [{ path }, { city }] = yield [
    cmds.rpc("GetPathByID", { id: pathId }),
    cmds.rpc("GetCityByID", { id: cityId })
  ];

  yield cmds.setState("poipublish.path", path);
  yield cmds.setState("poipublish.city", city);

  const poiId = get(props, "match.params.poiId");
  yield cmds.setState("poipublish.pathId", pathId);

  if (poiId) {
    const { poi } = yield cmds.rpc("GetPOIByID", { id: poiId });
    yield cmds.setState("poipublish.poi", poi);
  } else {
    yield cmds.setState("poipublish.poi", {
      pathId,
      cityId
    });
  }
  yield cmds.setState("poipublish.loading", false);

  const points = yield cmds.call(getPathPoints, pathId);
  yield cmds.setState("app.pathPoints", points);
}

export function* onChangeName(name) {
  yield cmds.setState("poipublish.poi.name", name);
}

export function* save(poi) {
  const pathId = yield cmds.getState("poipublish.path.id");
  const cityId = yield cmds.getState("poipublish.city.id");

  if (poi.id) {
    yield cmds.rpc("UpdatePOI", poi);
  } else {
    poi = {
      id: mongoid(),
      ...poi
    };
    yield cmds.rpc("CreatePOI", poi);
  }
  yield cmds.redirect(`/paths/${pathId}/cities/${cityId}/pois`);
}

// eslint-disable-next-line require-yield
export function* validate(values) {
  const errors = validateStandardPOIFields(values);

  if (values.bookingUrl && !isURL(values.bookingUrl)) {
    errors.bookingUrl = "URL is invalid";
  }

  if (values.facebook && !isURL(values.facebook)) {
    errors.facebook = "URL is invalid";
  }

  return errors;
}
