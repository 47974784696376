import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles({
  container: {
    marginBottom: 20
  },
  header: {
    borderBottom: "1px solid #ddd",
    marginBottom: 20
  }
});

export default function Section({ children, title }) {
  const classes = useStyles();

  return (
    <section className={classes.container}>
      <header className={classes.header}>
        <Typography variant="h6" component="h2">
          {title}
        </Typography>
      </header>
      {children}
    </section>
  );
}
