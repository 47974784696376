
import { cmds } from "effects";
import { get } from "object-path";

export function* setup(props) {
    const pathId = get(props, "match.params.pathId");
    const guideId = get(props, "match.params.guideId");

    const [{ path }, { guideTitle }] = yield [
        cmds.rpc("GetPathByID", { id: pathId }),
        cmds.rpc("GetGuideTitleByID", { id: guideId })
    ];
    yield cmds.setState("faq.path", path);
    yield cmds.setState("faq.guide", guideTitle);

    const faqlist = yield cmds.rpc("ListGuideQuestionAnswers", {
        pathId,
        page: 0,
        pageLength: 1000,
        guide_title_id: guideId,
    });

    yield cmds.setState("faq.list", faqlist);
    yield cmds.setState("faq.loading", false);
}

export function* deleteFaqList(e, id) {
    e.stopPropagation();
    const confirmed = yield cmds.confirm(
        "Are you sure you want to delete this FAQ title?"
    );
    if (!confirmed) return;

    const faq = yield cmds.getState("faq.list", []);
    yield cmds.setState(
        "faq.list",
        faq.filter(p => p.id !== id)
    );
    yield cmds.rpc("DeleteGuideQuestionAnswer", { id });
}



