import React from "react";

export function initializable(Component) {
  return class Initializable extends React.Component {
    componentDidMount() {
      if (this.props.setup) this.props.setup(this.props);
    }

    componentWillUnmount() {
      if (this.props.teardown) this.props.teardown(this.props);
    }

    render() {
      return <Component {...this.props} />;
    }
  };
}
