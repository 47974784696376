import { connect, initializable } from "effects";
import * as functions from "./functions";
import QAPublish from "./QAPublish";

const mapStateToProps = (state, ownProps, getState) => {
    return {
        loading: getState("qapublish.loading", true),
        path: getState("qapublish.path"),
        faq: getState("qapublish.faq"),
        guide: getState("qapublish.guide", []),
    };
};

export default connect(initializable(QAPublish), mapStateToProps, functions);
