import { cmds } from "effects";
import { get } from "object-path";
import { getPathPoints } from "components/helpers";

export function* setup(props) {
  const pathId = get(props, "match.params.pathId");
  const { path } = yield cmds.rpc("GetPathByID", { id: pathId });
  yield cmds.setState("stages.path", path);

  const stages = yield cmds.rpc("ListStages", {
    pathId,
    page: 0,
    pageLength: 1000
  });

  const stagesWithDefault = stages.map(c => {
    c.distanceFromOriginInMeters = c.distanceFromOriginInMeters || 0;
    return c;
  });

  yield cmds.setState("stages.list", stagesWithDefault);
  yield cmds.setState("stages.loading", false);

  const points = yield cmds.call(getPathPoints, pathId);
  yield cmds.setState("app.pathPoints", points);
}

export function* deleteStage(id) {
  const confirmed = yield cmds.confirm(
    "Are you sure you want to delete this stage?"
  );
  if (!confirmed) return;

  const stages = yield cmds.getState("stages.list", []);
  yield cmds.setState(
    "stages.list",
    stages.filter(p => p.id !== id)
  );
  yield cmds.rpc("DeleteStage", { id });
}
