import { connect, initializable } from "effects";
import * as functions from "./functions";
import PathsPublish from "./PathsPublish";

const mapStateToProps = (state, ownProps, getState) => {
  return {
    loading: getState("pathpublish.loading", true),
    path: getState("pathpublish.path")
  };
};

export default connect(initializable(PathsPublish), mapStateToProps, functions);
