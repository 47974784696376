import { connect, initializable } from "effects";
import * as functions from "./functions";
import CityPublish from "./CityPublish";

const mapStateToProps = (state, ownProps, getState) => {
  return {
    loading: getState("citypublish.loading", true),
    city: getState("citypublish.city"),
    path: getState("citypublish.path"),
    pathPoints: getState("app.pathPoints")
  };
};

export default connect(initializable(CityPublish), mapStateToProps, functions);
