import {connect, initializable} from "effects";
import * as functions from "./functions";
import ContributionPublish from "./ContributionPublish";

const mapStateToProps = (contribution, ownProps, getState) => {
  return {
    loading: getState("contributionPublish.loading", true),
    contribution: getState("contributionPublish.contribution"),
    path: getState("contributionPublish.path"),
    submitting: getState("contributionPublish.submitting", false),
  };
};

export default connect(
  initializable(ContributionPublish),
  mapStateToProps,
  functions
);
