import React from "react";
import { Map, Marker, GoogleApiWrapper, Polyline } from "google-maps-react";
import { get } from "object-path";
import {renderAlternateRoutes} from "../../../helpers";

class MapContainer extends React.Component {
  shouldComponentUpdate(props) {
    const newLat = get(props, "location.lat", 0);
    const oldLat = get(this.props, "location.lat", 0);
    if (oldLat !== newLat) return true;

    const newLng = get(props, "location.lng", 0);
    const oldLng = get(this.props, "location.lng", 0);
    if (oldLng !== newLng) return true;

    return false;
  }

  render() {
    const { location, google, pathPoints, zoom } = this.props;

    const normalizedLocation = {
      lat: get(location, "lat", 0),
      lng: get(location, "lng", 0)
    };

    return (
      <Map
        google={google}
        zoom={zoom || 12}
        style={{ position: "static", width: 800, height: 400 }}
        containerStyle={{ position: "static" }}
        initialCenter={normalizedLocation}
        center={normalizedLocation}
      >
        <Marker
          position={normalizedLocation}
          icon={{
            url: "/images/marker.png",
            anchor: new google.maps.Point(4, 4), // eslint-disable-line
            scaledSize: new google.maps.Size(8, 8) // eslint-disable-line
          }}
        />
        {pathPoints && (
          <Polyline
            path={pathPoints.main || []}
            strokeColor="#0000FF"
            strokeOpacity={0.8}
            strokeWeight={2}
          />
        )}
        {pathPoints && renderAlternateRoutes(pathPoints.alternateRoutes)}
      </Map>
    );
  }
}

export default GoogleApiWrapper({
  apiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY
})(MapContainer);
