import { connect, initializable } from "effects";
import * as functions from "./functions";
import AccommodationSelectMultiple from "./AccommodationSelectMultiple";
import { get } from "object-path";

const mapStateToProps = (state, ownProps, getState) => {
  let currentIndex = get(ownProps, "currentIndex") || 0;
  const cityId = get(ownProps, "cityId");
  return {
    ...ownProps,
    loading: getState(`accommodationselect_${currentIndex}_${cityId}.loading`, true),
    options: getState(`accommodationselect_${currentIndex}_${cityId}.options`, []),
    map: getState(`accommodationselect_${currentIndex}_${cityId}.map`, {})
  };
};

export default connect(initializable(AccommodationSelectMultiple), mapStateToProps, functions);
