import React from "react";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";

export default function Crumbs({ path, items = [] }) {
  if (!path && items.length === 0) return null;

  let pathCrumb;
  if (items.length === 0 && path) {
    pathCrumb = (
      <Typography color="textPrimary">{path.name || "New Path"}</Typography>
    );
  } else if (path) {
    pathCrumb = (
      <Link color="inherit" href={`/paths/${path.id}`}>
        {path.name}
      </Link>
    );
  }
  return (
    <Breadcrumbs aria-label="breadcrumb">
      <Link color="inherit" href="/">
        Dashboard
      </Link>
      {path && (
        < Link color="inherit" href="/paths">
          Paths
        </Link>
      )
      }
      {pathCrumb}
      {
        items.map((item, index) => {
          if (index === items.length - 1) {
            return (
              <Typography color="textPrimary" key={index}>
                {item.name}
              </Typography>
            );
          }
          if (path) {
            return (
              <Link
                color="inherit"
                href={`/paths/${path.id}${item.href}`}
                key={index}
              >
                {item.name}
              </Link>
            );
          } else {
            return (
              <Link color="inherit" href={`${item.href}`} key={index}>
                {item.name}
              </Link>
            );
          }
        })
      }
    </Breadcrumbs>
  );
}
