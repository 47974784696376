import { cmds } from "effects";

export function* login({ username, password }) {
  try {
    const result = yield cmds.gateway("/login", { username, password });
    if (result.ok) {
      yield cmds.redirect("/");
      // use the setState in case some browser wont redirect to same page
      // yield cmds.setState("app.loggedIn", true);
    } else {
      return {
        ok: false,
        message: "Invalid username or password."
      };
    }
    return result;
  } catch (e) {
    return {
      ok: false,
      message: "An unexpected error has occurred."
    };
  }
}

export function validate(values) {
  const errors = {};

  if (values.username === "") {
    errors.username = "Required";
  }

  if (values.password === "") {
    errors.password = "Required";
  }

  return errors;
}
