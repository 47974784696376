import { cmds } from "effects";
import { get } from "object-path";

export function* setup(props) {
  yield cmds.setState("abuseReportView.loading", true);
  const id = get(props, "match.params.abuseReportId");
  const { abuseReport } = yield cmds.rpc("GetAbuseReportByID", { id });
  yield cmds.setState("abuseReportView.abuseReport", abuseReport);
  yield cmds.setState("abuseReportView.loading", false);

  if (abuseReport.entityType === "messagethread") {
    const messages = yield cmds.rpc("ListMessages", {
      threadIds: [abuseReport.entityId]
    });
    yield cmds.setState("abuseReportView.messages", messages);
  }
}

export function* closeReport() {
  const abuseReport = yield cmds.getState("abuseReportView.abuseReport");
  abuseReport.closed = true;
  yield cmds.rpc("UpdateAbuseReport", abuseReport);
  yield cmds.redirect("/abuse-reports");
}
