import { cmds } from "effects";
import arrayMove from "array-move";
import assert from "assert";
import uuid from "uuid";

export function* setup() {
  const { name, media, isSingle } = yield cmds.props();
  yield cmds.setState(fieldName(name, "media"), media || []);
  yield cmds.setState(fieldName(name, "isSingle"), isSingle);
}

export function* moveMedia(oldIndex, newIndex) {
  const { name } = yield cmds.props();

  const media = yield cmds.getState(fieldName(name, "media"), []);
  const mediaResorted = arrayMove(media, oldIndex, newIndex);
  yield cmds.setState(fieldName(name, "media"), mediaResorted);
  yield cmds.call(mediaChanged);
}

export function* clearMedia(filePath) {
  const { name } = yield cmds.props();

  assert(filePath, "filePath required");
  const uploadingMediaList = yield cmds.getState(fieldName(name, "media"), []);
  const uploadingMedia = uploadingMediaList.find(m => m.filePath === filePath);
  const newUploadingMediaList = uploadingMediaList.filter(
    m => m.filePath !== filePath
  );
  yield cmds.setState(fieldName(name, "media"), newUploadingMediaList);
  if (uploadingMedia.transactionId) {
    yield cmds.uploadCancel(uploadingMedia.transactionId);
  }
  yield cmds.call(mediaChanged);
}

export function* uploadMedia(files, isSingle) {
  let uploadCmds = [];

  if (isSingle) {
    uploadCmds = [cmds.call(uploadMediaFile, files[files.length - 1], isSingle)];
  }
  else {
    for (var i = 0; i < files.length; i++) {
      uploadCmds.push(cmds.call(uploadMediaFile, files[i]));
    }
  }
  yield uploadCmds;
}

export function* uploadMediaFile(file, isSingle) {
  const { name } = yield cmds.props();

  if (!isImage(file) && !isVideo(file)) {
    yield cmds.alert("You can only upload a photo.");
    return;
  }

  const { pathId } = yield cmds.props();

  const transactionId = uuid.v4();
  const uploadPath = `/assets/${pathId}/${transactionId}`;

  const isImageFile = isImage(file);
  yield cmds.upload(file, pathId, {
    transactionId,
    uploadPath,
    onEnd: onUploadEnd,
    onProgress: onUploadProgress
    // onError: console.error // eslint-disable-line
  });

  const previewUrl = yield cmds.call(generatePreview, file);

  const media = {
    pathId,
    fileType: isImageFile ? "image" : "video",
    filePath: uploadPath,
    url: previewUrl,
    uploading: Boolean(transactionId),
    transactionId
  };
  if (isSingle) {
    yield cmds.setState(fieldName(name, "media"), [media]);
  }
  else {
    yield cmds.pushState(fieldName(name, "media"), media, "filePath");
  }
  yield cmds.call(setUploadStats);
  yield cmds.call(mediaChanged);
}

export function* generatePreview(file) {
  const isVideo = !isImage(file);

  if (isVideo) {
    const supportsVideo = yield cmds.modernizr("videoautoplay");
    if (supportsVideo) {
      const canvas = yield cmds.generateVideoPreview(file);
      return yield cmds.call.fnBound(canvas, canvas.toDataURL);
    } else {
      return "VIDEO PLACEHOLDER URL";
    }
  } else {
    const orientation = yield cmds.imageOrientation(file);
    const { canvas } = yield cmds.generateImagePreview(file, orientation);
    return yield cmds.call.fnBound(canvas, canvas.toDataURL);
  }
}

export function isImage(file) {
  return Boolean(file.type.match(/^image/));
}

export function isVideo(file) {
  return Boolean(file.type.match(/^video/));
}

export function* onUploadEnd({ dims, url, publicUrl, transactionId, pathId, uploadPath }) {
  const { name } = yield cmds.props();

  const media = yield cmds.getState(fieldName(name, "media"), []);
  const upload = media.find(m => m.transactionId === transactionId);
  if (!upload) return;
  const mediaUrl = upload.fileType === "image" ? url : publicUrl;

  const mediaEntity = {
    pathId,
    fileType: upload.fileType,
    filePath: uploadPath,
    url: mediaUrl,
    uploading: false,
    transactionId: "",
    height: dims.height,
    width: dims.width,
  };

  yield cmds.replaceState(fieldName(name, "media"), mediaEntity, "filePath");

  yield cmds.call(setUploadStats);
  yield cmds.call(mediaChanged);
}

export function* setUploadStats() {
  const { name } = yield cmds.props();
  const media = yield cmds.getState(fieldName(name, "media"), []);
  const transactionIds = media.map(m => m.transactionId).filter(v => v);
  const uploading = transactionIds.length > 0;
  yield cmds.setState(fieldName(name, "uploading"), uploading);

  const progress = yield cmds.getState(fieldName(name, "progress"), {});
  const progresses = transactionIds.map(t => progress[t]);
  const averageProgress = average(progresses.filter(p => p < 100)).toFixed(0);
  yield cmds.setState(fieldName(name, "uploadProgressTotal"), averageProgress);
}

const average = arr => {
  if (arr.length === 0) return 100;
  return arr.map(Number).reduce((p, c) => p + c, 0) / arr.length;
};

export function* onUploadProgress({ progress, transactionId }) {
  const { name } = yield cmds.props();

  yield cmds.setState(fieldName(name, `progress.${transactionId}`), progress.toFixed(0));
}

export function* mediaChanged() {
  const { name } = yield cmds.props();

  const { onChange } = yield cmds.props();
  const media = yield cmds.getState(fieldName(name, "media"), []);
  if (onChange) {
    yield cmds.fn(onChange, media);
  }
}

function fieldName(name, field) {
  return `${name}.${field}`
}
