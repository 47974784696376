import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Link from "@material-ui/core/Link";
import Button from "@material-ui/core/Button";
import AppLoading from "components/common/AppLoading";
import Page from "components/common/Page";
import Modal from "@material-ui/core/Modal";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles(theme => ({
  table: {
    width: "100%",
    overflowX: "auto"
  },
  header: {
    display: "flex",
    alignItems: "center"
  },
  mapContainer: {
    marginBottom: 20,
    width: "100%",
    height: 500
  },
  modalContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  modal: {
    // position: "absolute",
    width: 800,
    maxWidth: "90%",
    backgroundColor: theme.palette.background.paper,
    padding: 20,
    borderRadius: 3
  }
}));

export default function Tags({
  loading,
  tags,
  path,
  pathId,
  deleteTag,
  pathPoints,
  formOpen,
  edit,
  errors,
  newTag,
  editTag,
  handleTagFormNameChange,
  tagFormSubmit,
  setName,
  setTitle,
  cancel
}) {
  const classes = useStyles();

  if (loading) return <AppLoading />;

  return (
    <Page
      path={path}
      title="Tags"
      crumbs={[
        {
          name: "Tags",
          href: "/tags"
        },
        {
          name: setTitle,
          href: "/tags/" + setName
        }
      ]}
    >
      <Button
        color="primary"
        variant="contained"
        onClick={newTag}
        style={{ marginBottom: 20 }}
      >
        New Tag
      </Button>

      <Paper className={classes.table}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Code</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {tags.map(tag => (
              <TableRow key={tag.slug}>
                <TableCell component="th" scope="row">
                  {tag.name}
                </TableCell>
                <TableCell component="th" scope="row">
                  {tag.slug}
                </TableCell>
                <TableCell component="th" scope="row">
                  {tag.global && (
                    <Typography variant="caption">global tag</Typography>
                  )}
                  {!tag.global && (
                    <div>
                      <Link onClick={() => editTag(tag)}>Edit</Link>
                      <span>&nbsp;</span>
                      <Link href="#" onClick={() => deleteTag(tag.id)}>
                        Delete
                      </Link>
                    </div>
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <Modal
          open={formOpen}
          onClose={cancel}
          className={classes.modalContainer}
        >
          <form
            className={classes.modal}
            onSubmit={e => {
              e.preventDefault();
              tagFormSubmit();
            }}
          >
            <TextField
              label={"Tag Name"}
              variant="outlined"
              fullWidth
              margin="normal"
              type={"text"}
              name="name"
              onChange={e => handleTagFormNameChange(e.target.value)}
              value={edit.name}
              error={Boolean(errors.name)}
              helperText={errors.name}
            />
            <Button
              type="submit"
              variant="contained"
              color="primary"
              style={{ marginRight: 6 }}
            >
              Save
            </Button>

            <Button variant="contained" color="secondary" onClick={cancel}>
              Cancel
            </Button>
          </form>
        </Modal>
      </Paper>
    </Page>
  );
}
