import { cmds } from "effects";
import { get } from "object-path";

export function* setup(props) {
  const pathId = get(props, "match.params.pathId");
  const { path } = yield cmds.rpc("GetPathByID", { id: pathId });
  yield cmds.setState("app.path", path);
  yield cmds.setState("search.loading", false);
}

var debounce = null;

export function* search(term) {
  yield cmds.setState("search.query", term);

  if (debounce) {
    yield cmds.clearTimeout(debounce);
    debounce = null;
  }

  yield cmds.setState("searching.searching", true);
  debounce = yield cmds.setTimeout(cmds.call(doSearch, term), 200);
}

export function* doSearch(term) {
  try {
    const pathId = yield cmds.getState("app.path.id");
    if (!pathId) return;

    const results = yield cmds.search(term, pathId);
    yield cmds.setState("search.results", results);
  } finally {
    yield cmds.setState("searching.searching", false);
  }
}
