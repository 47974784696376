import { cmds } from "effects";
import assert from "assert";

export function* setup(props) {
  const { setName, name, pathId } = props;
  assert(setName, "setName required");
  assert(name, "name required");
  assert(pathId, "pathId required");

  yield cmds.setState(`tagselect.${name}.loading`, true);

  const tags = yield cmds.rpc("ListTags", {
    pathId,
    setName: setName
  });

  tags.sort((a, b) => a.name.localeCompare(b.name));

  const options = tags.map(t => ({ label: t.name, value: t.slug }));

  yield cmds.setState(`tagselect.${name}.loading`, false);
  yield cmds.setState(`tagselect.${setName}.options`, options);
}
