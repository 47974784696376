import React from "react";
import Button from "@material-ui/core/Button";
import AppLoading from "components/common/AppLoading";
import Page from "components/common/Page";
import CancelButton from "components/common/form/CancelButton";
import Section from "../../common/form/Section";
import { getPlaceLink } from "../../helpers";

export default function Contributions({
  loading,
  contribution,
  path,
  submitting,
  setStatus
}) {
  if (loading) return <AppLoading />;

  return (
    <Page
      path={path}
      title={contribution.name ? "Edit " + contribution.name : "New Contribution"}
      crumbs={[
        {
          name: "Contributions",
          href: "/contributions"
        },
        {
          name: contribution.name || "New Contribution"
        }
      ]}
    >
      <Section title="Review">
        <p><b>Place:</b> <a href={getPlaceLink(contribution.placeType, contribution.placeId)}
          target="_blank">{contribution.placeName}</a></p>
        <p><b>User Name:</b> {contribution.userName}</p>
        {contribution.placeProblem && <p><b>Problem:</b> {contribution.placeProblem}</p>}
        {contribution.description && <p><b>Description:</b> {contribution.description}</p>}

        {contribution.media.map((m) => <div key={m.filePath}><a href={`${process.env.REACT_APP_IMGIX_URL}${m.filePath}`}
          target="_blank"><img
            src={`${process.env.REACT_APP_IMGIX_URL}${m.filePath}?w=250`}
            alt={"Contribution"} width={250} /></a></div>)}
      </Section>

      <Section title="Approval">
        <Button
          variant="contained"
          color="primary"
          type="submit"
          disabled={submitting}
          style={{ marginRight: 6 }}
          onClick={() => setStatus("approved")}
        >
          Approve
        </Button>

        <Button
          variant="contained"
          color="secondary"
          type="submit"
          disabled={submitting}
          style={{ marginRight: 6 }}
          onClick={() => setStatus("denied")}
        >
          Deny
        </Button>

        <CancelButton
          location={`/paths/${path.id}/contributions`}
        />
      </Section>
    </Page>
  );
}
