// Set firebase config
window.firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET
};

window.imgixUrl = process.env.REACT_APP_IMGIX_URL;

require("./init.js");
