import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Link from "@material-ui/core/Link";
import AppLoading from "components/common/AppLoading";
import Page from "components/common/Page";
import TextField from "@material-ui/core/TextField";
import CircularProgress from "@material-ui/core/CircularProgress";
import { getPlaceLink} from "../helpers"

const useStyles = makeStyles({
  table: {
    width: "100%",
    overflowX: "auto"
  },
  header: {
    display: "flex",
    alignItems: "center"
  },
  mapContainer: {
    marginBottom: 20,
    width: "100%",
    height: 500
  },
  searchFieldContainer: {
    padding: 15
  }
});

export default function Search({
  loading,
  results,
  path,
  search,
  query,
  searching
}) {
  const classes = useStyles();

  if (loading) return <AppLoading />;

  return (
    <Page
      path={path}
      title="Search"
      crumbs={[
        {
          name: "Search",
          href: "/search"
        }
      ]}
    >
      <Paper className={classes.table}>
        <div className={classes.searchFieldContainer}>
          <TextField
            label="Search places"
            fullWidth
            value={query}
            onChange={e => {
              search(e.target.value);
            }}
          />
        </div>
        <ResultsTable
          results={results}
          pathId={path.id}
          query={query}
          searching={searching}
        />
      </Paper>
    </Page>
  );
}

const resultStyles = makeStyles({
  message: {
    padding: 15
  },
  street: {
    fontSize: 12,
    color: "#aaa"
  }
});

function ResultsTable({ results, pathId, query, searching }) {
  const classes = resultStyles();

  if (searching)
    return (
      <div className={classes.message}>
        <CircularProgress />
      </div>
    );
  if (!query) return null;
  if (!results.length) return <div className={classes.message}>no results</div>;

  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>Name</TableCell>
          <TableCell>City</TableCell>
          <TableCell>Street</TableCell>
          <TableCell>Type</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {results.map(result => (
          <TableRow key={result.objectID}>
            <TableCell component="th" scope="row">
              <Link href={getPlaceLink(result.type, result.objectID)}>{result.name}</Link>
            </TableCell>
            <TableCell component="th" scope="row">
              {result.city}
            </TableCell>
            <TableCell component="th" scope="row">
              {result.street}
            </TableCell>
            <TableCell component="th" scope="row">
              {getLabel(result.type)}
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
}

function getLabel(type) {
  switch (type) {
    case "accommodation":
      return "Accommodation";
    case "restaurant":
      return "Restaurant";
    case "poi":
      return "Point-of-interest";
    case "city":
      return "City";
    default:
      return type;
  }
}

