import { cmds } from "effects";
import { get } from "object-path";
import mongoid from "mongoid-js";

export function* setup(props) {
  const pathId = get(props, "match.params.pathId");
  const categoryId = get(props, "match.params.categoryId");

  const { path } = yield cmds.rpc("GetPathByID", { id: pathId });
  yield cmds.setState("plannerPublish.path", path);

  const { plannerCategory } = yield cmds.rpc("GetPlannerCategoryByID", {
    id: categoryId,
  });
  yield cmds.setState("plannerPublish.plannerCategory", plannerCategory);

  yield cmds.setState("plannerPublish.loading", true);
  const plannerId = get(props, "match.params.plannerId");
  yield cmds.setState("plannerPublish.pathId", pathId);

  yield cmds.setState("plannerPublish.categoryId", categoryId);

  if (plannerId) {
    const { planner } = yield cmds.rpc("GetPlannerByID", {
      id: plannerId,
    });
    yield cmds.setState("plannerPublish.planner", planner);
  } else {
    yield cmds.setState("plannerPublish.planner", {
      pathId,
    });
  }
  yield cmds.setState("plannerPublish.loading", false);
}

export function* handleAddDay(values, setFieldValue) {
  let tempArr = [];
  if (values.dayDetails) tempArr = values.dayDetails;

  tempArr.push({
    startCityId: "",
    startCityAccommodationIds: [],
    startCityPoiIds: [],
    startCityRestaurantIds: [],
    endCityId: "",
    endCityAccommodationIds: [],
    endCityPoiIds: [],
    endCityRestaurantIds: [],
    // description: ""
  });

  setFieldValue("dayDetails", tempArr);
}

export function* handleRemoveDay(values, setFieldValue, index) {
  let tempArr = [];
  if (values.dayDetails) tempArr = values.dayDetails;

  tempArr.splice(index, 1);

  setFieldValue("dayDetails", tempArr);
}

export function* save(planner) {
  const pathId = yield cmds.getState("plannerPublish.pathId");
  const plannerCategory = yield cmds.getState("plannerPublish.plannerCategory");

  if (plannerCategory && plannerCategory.id) {
    if (planner.id) {
      planner = {
        ...planner,
        plannerCategoryId: plannerCategory.id,
        dayDetails: [
          ...planner.dayDetails.map((e, i) => {
            return {
              dayNumber: i + 1,
              startCityId: e.startCityId,
              startCityAccommodationIds: e.startCityAccommodationIds,
              startCityPoiIds: e.startCityPoiIds,
              startCityRestaurantIds: e.startCityRestaurantIds,
              endCityId: e.endCityId,
              endCityAccommodationIds: e.endCityAccommodationIds,
              endCityPoiIds: e.endCityPoiIds,
              endCityRestaurantIds: e.endCityRestaurantIds,
              // description: e.description,
            };
          }),
        ],
      };
     
      yield cmds.rpc("UpdatePlanner", planner);
    }
     else {
      planner = {
        id: mongoid(),
        ...planner,
        plannerCategoryId: plannerCategory.id,
        dayDetails: [
          ...planner.dayDetails.map((e, i) => {
            return {
              dayNumber: i + 1,
              startCityId: e.startCityId,
              startCityAccommodationIds: e.startCityAccommodationIds,
              startCityPoiIds: e.startCityPoiIds,
              startCityRestaurantIds: e.startCityRestaurantIds,
              endCityId: e.endCityId,
              endCityAccommodationIds: e.endCityAccommodationIds,
              endCityPoiIds: e.endCityPoiIds,
              endCityRestaurantIds: e.endCityRestaurantIds,
              // description: e.description,
            };
          }),
        ],
      };

      yield cmds.rpc("CreatePlanner", planner);
    }
    yield cmds.redirect(`/paths/${pathId}/planners/${plannerCategory.id}`);
  }
}

// eslint-disable-next-line require-yield
export function* validate(values) {
  const errors = {};

  const isUploading = get(values, "media", []).some((m) => m.uploading);
  if (isUploading) {
    errors.media = "Media is still uploading";
  }

  if (!values.title) {
    errors.title = "Required";
  }

  if (!values.dayDetails || values.dayDetails.length == 0) {
    errors.dayDetails = "Required";
  }

  if (values.dayDetails) {
    values.dayDetails.forEach((e, i) => {
      if (!e.startCityId) {
        errors[`dayDetails_startCityId_${i}`] = "Required";
      }
      if (!e.endCityId) {
        errors[`dayDetails_endCityId_${i}`] = "Required";
      }
      // if (!e.description) {
      //   errors[`dayDetails_description_${i}`] = "Required";
      // }
    });
  }

  return errors;
}
