import { cmds } from "effects";

export function* setup() {
  const paths = yield cmds.rpc("ListPaths", { page: 0, pageLength: 1000 });
  yield cmds.setState("paths.list", paths);
}

export function* deletePath(id) {
  const confirmed = yield cmds.confirm(
    "Are you sure you want to delete this path?"
  );
  if (!confirmed) return;

  const paths = yield cmds.getState("paths.list", []);
  yield cmds.setState(
    "paths.list",
    paths.filter(p => p.id !== id)
  );
  yield cmds.rpc("DeletePath", { id });
}
