import React from "react";
import ProgressBar from "./progressbar.js";
import { makeStyles } from "@material-ui/core/styles";

const Circle = ProgressBar.Circle;

const options = {
  strokeWidth: 10,
  trailColor: "#eee",
  color: "#777"
};

const containerStyle = {
  width: "60px",
  height: "60px"
};

const useStyles = makeStyles({
  loader: {
    position: "absolute",
    height: "100%",
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  }
});

export default function MediaPreviewLoader({ progress }) {
  const classes = useStyles();

  return (
    <div className={classes.loader}>
      <Circle
        progress={progress === 0 ? 0 : progress / 100}
        options={options}
        style={containerStyle}
      />
    </div>
  );
}
