import { cmds } from "effects";
import { get } from "object-path";
import mongoid from "mongoid-js";

export function* setup(props) {
  const pathId = get(props, "match.params.pathId");
  const { path } = yield cmds.rpc("GetPathByID", { id: pathId });
  yield cmds.setState("contributionPublish.path", path);

  yield cmds.setState("contributionPublish.loading", true);
  const contributionId = get(props, "match.params.contributionId");
  yield cmds.setState("contributionPublish.pathId", pathId);

  if (contributionId) {
    const contribution = yield cmds.rpc("GetContributionById", { id: contributionId });
    yield cmds.setState("contributionPublish.contribution", contribution);
  } else {
    yield cmds.setState("contributionPublish.contribution", {
      pathId,
    });
  }
  yield cmds.setState("contributionPublish.loading", false);
}

export function* setStatus(status) {
  const submitting = yield cmds.getState("contributionPublish.submitting", false);
  if (submitting) return;
  yield cmds.setState("contributionPublish.submitting", true);

  const contribution = yield cmds.getState("contributionPublish.contribution");
  const pathId = yield cmds.getState("contributionPublish.pathId");

  try {
    yield cmds.rpc("UpdateContributionStatus", {
      contributionId: contribution.id, status,
    });
    yield cmds.redirect(`/paths/${pathId}/contributions`);
  } catch (e) {
    console.error(e)
  } finally {
    yield cmds.setState("contributionPublish.submitting", false);
  }
}
