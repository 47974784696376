import { cmds } from "effects";
import { get } from "object-path";

export function* setup(props) {
  const pathId = get(props, "match.params.pathId");
  const { path } = yield cmds.rpc("GetPathByID", { id: pathId });
  yield cmds.setState("app.path", path);
}

export function* publish() {
  const building = yield cmds.getState("paths.building", false);
  if (building) return;

  const response = yield cmds.confirm("Are you sure that you want to publish?");
  if (!response) return;

  yield cmds.setState("paths.building", true);

  try {
    const pathId = yield cmds.getState("app.path.id");
    yield cmds.gateway("/build", { pathId });
    yield cmds.setState("paths.importSuccess", true);
  } catch (e) {
    yield cmds.setState("paths.importError", true);
  } finally {
    yield cmds.setState("paths.building", false);
  }
}

export function* closeSuccessDialog() {
  yield cmds.setState("paths.importSuccess", false);
}

export function* closeErrorDialog() {
  console.log("closeErrorDialog");
  yield cmds.setState("paths.importError", false);
}
