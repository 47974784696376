import React from "react";
import Button from "@material-ui/core/Button";

function clearInput(event) {
  event.target.value = null;
}

export default function MediaSelectButton({ onChange, isSingle }) {
  return (
    <div>
      <Button
        variant="contained"
        color="secondary"
        style={{ position: "relative", cursor: "pointer" }}
      >
        Add Photos
        <input
          type="file"
          accept=".jpg,.png,.jpeg"
          className="publish-dialog-media-select-input"
          onChange={e => onChange(e.target.files)}
          data-publish-media-select
          id="upload-file-input"
          onClick={clearInput}
          multiple={!isSingle}
          style={{
            position: "absolute",
            height: "100%",
            width: "100%",
            opacity: 0,
            cursor: "pointer"
          }}
        />
      </Button>
    </div>
  );
}
