export function rpc(fn, payload) {
  return {
    type: "rpc",
    fn,
    payload
  };
}

export function gateway(path, payload) {
  return {
    type: "gateway",
    path,
    payload
  };
}

export function appLoadingStart() {
  return {
    type: "setState",
    path: "app.loading",
    value: true
  };
}

export function appLoadingEnd() {
  return {
    type: "setState",
    path: "app.loading",
    value: false
  };
}

export function redirect(url) {
  return {
    type: "redirect",
    url
  };
}

export function confirm(question) {
  return {
    type: "confirm",
    question
  };
}

export function upload(file, pathId, options) {
  return {
    type: "firebaseUpload",
    file,
    pathId,
    ...options
  };
}

export function uploadCancel(transactionId) {
  return {
    type: "firebaseUploadCancel",
    transactionId
  };
}

export function uploadDataSheet(file, pathId, options) {
  return {
    type: "firebaseDataSheetUpload",
    file,
    pathId,
    ...options
  };
}

export function uploadDataSheetCancel(transactionId) {
  return {
    type: "firebaseDataSheetUploadCancel",
    transactionId
  };
}

export function imageDimensions(file) {
  return {
    type: "imageDimensions",
    file
  };
}

export function imageOrientation(file) {
  return {
    type: "imageOrientation",
    file
  };
}

export function generateImagePreview(file, orientation) {
  return {
    type: "generateImagePreview",
    file,
    orientation
  };
}

export function generateVideoPreview(file) {
  return {
    type: "generateVideoPreview",
    file
  };
}

export function setLocal(key, value) {
  return {
    type: "setLocal",
    key,
    value
  };
}

export function getLocal(key) {
  return {
    type: "getLocal",
    key
  };
}

export function search(term, pathId) {
  return {
    type: "search",
    term,
    pathId
  };
}
