import { connect, initializable } from "effects";
import * as functions from "./functions";
import CitySelect from "./CitySelect";

const mapStateToProps = (state, ownProps, getState) => {
  return {
    ...ownProps,
    loading: getState("cityselect.loading", true),
    options: getState("cityselect.options", []),
    map: getState("cityselect.map", {})
  };
};

export default connect(initializable(CitySelect), mapStateToProps, functions);
