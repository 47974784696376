import React from "react";
import Select from "@material-ui/core/Select";
import FormHelperText from "@material-ui/core/FormHelperText";
import { FastField } from "formik";

export default function SelectFast(props) {
  return (
    <FastField name={props.name}>
      {({ field, meta }) => {
        return (
          <div>
            <Select
              value={field.value}
              onChange={field.onChange}
              onBlur={field.onBlur}
              error={Boolean(meta.error)}
              {...props}
            >
              {props.children}
            </Select>
            <FormHelperText error={Boolean(meta.error)}>
              {meta.error}
            </FormHelperText>
          </div>
        );
      }}
    </FastField>
  );
}
