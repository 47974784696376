import { cmds } from "effects";
import { get } from "object-path";
import { validateStandardPOIFields } from "components/common/form/StandardPOIFields";
import { getPathPoints } from "components/helpers";
import mongoid from "mongoid-js"

export function* setup(props) {
  yield cmds.setState("restaurantpublish.loading", true);

  const pathId = get(props, "match.params.pathId");
  const cityId = get(props, "match.params.cityId");
  const [{ path }, { city }] = yield [
    cmds.rpc("GetPathByID", { id: pathId }),
    cmds.rpc("GetCityByID", { id: cityId })
  ];

  yield cmds.setState("restaurantpublish.path", path);
  yield cmds.setState("restaurantpublish.city", city);

  const restaurantId = get(props, "match.params.restaurantId");
  yield cmds.setState("restaurantpublish.pathId", pathId);

  if (restaurantId) {
    const { restaurant } = yield cmds.rpc("GetRestaurantByID", {
      id: restaurantId
    });
    yield cmds.setState("restaurantpublish.restaurant", restaurant);
  } else {
    yield cmds.setState("restaurantpublish.restaurant", {
      pathId,
      cityId
    });
  }
  yield cmds.setState("restaurantpublish.loading", false);

  const points = yield cmds.call(getPathPoints, pathId);
  yield cmds.setState("app.pathPoints", points);
}

export function* onChangeName(name) {
  yield cmds.setState("app.restaurant.name", name);
}

export function* save(restaurant) {
  const pathId = yield cmds.getState("restaurantpublish.path.id");
  const cityId = yield cmds.getState("restaurantpublish.city.id");

  if (restaurant.id) {
    yield cmds.rpc("UpdateRestaurant", restaurant);
  } else {
    restaurant = {
      id: mongoid(),
      ...restaurant
    }
    yield cmds.rpc("CreateRestaurant", restaurant);
  }
  yield cmds.redirect(`/paths/${pathId}/cities/${cityId}/restaurants`);
}

// eslint-disable-next-line require-yield
export function* validate(values) {
  const errors = validateStandardPOIFields(values);

  if (!values.foodTypes) {
    errors.foodTypes = "Required";
  }

  return errors;
}
