import { cmds } from "effects";
import { get } from "object-path";
import { getPathPoints } from "components/helpers";
import mongoid from "mongoid-js";

export function* setup(props) {
  const pathId = get(props, "match.params.pathId");
  const { path } = yield cmds.rpc("GetPathByID", { id: pathId });
  yield cmds.setState("stagePublish.path", path);

  yield cmds.setState("stagePublish.loading", true);
  const stageId = get(props, "match.params.stageId");
  yield cmds.setState("stagePublish.pathId", pathId);

  if (stageId) {
    const { stage } = yield cmds.rpc("GetStageByID", { id: stageId });
    if (!stage.location) stage.location = { lat: 0, lng: 0 };
    yield cmds.setState("stagePublish.stage", stage);
  } else {
    yield cmds.setState("stagePublish.stage", {
      pathId,
      location: {
        lat: 0,
        lng: 0
      }
    });
  }
  yield cmds.setState("stagePublish.loading", false);

  const points = yield cmds.call(getPathPoints, pathId);
  yield cmds.setState("app.pathPoints", points);
}

export function* save(stage) {
  const pathId = yield cmds.getState("stagePublish.pathId");

  if (stage.id) {
    yield cmds.rpc("UpdateStage", stage);
  } else {
    stage = {
      id: mongoid(),
      ...stage
    };
    yield cmds.rpc("CreateStage", stage);
  }
  yield cmds.redirect(`/paths/${pathId}/stages`);
}

// eslint-disable-next-line require-yield
export function* validate(values) {
  const errors = {};

  if (!values.cityStartId) {
    errors.cityStartId = "Required";
  }

  if (!values.cityEndId) {
    errors.cityEndId = "Required";
  }

  return errors;
}

export function* updateStart(city) {
  yield cmds.setState("stagePublish.startCity", city);
  yield cmds.call(updateMap);
}

export function* updateEnd(city) {
  yield cmds.setState("stagePublish.endCity", city);
  yield cmds.call(updateMap);
}

export function* updateMap() {
  const start = yield cmds.getState("stagePublish.startCity");
  const end = yield cmds.getState("stagePublish.endCity");

  if (!start || !end) {
    yield cmds.setState("stagePublish.points", []);
    return;
  }

  const pathId = yield cmds.getState("stagePublish.path.id");

  const points = yield cmds.rpc("ListLocationsBetween", {
    start: start.location,
    end: end.location,
    pathId
  });

  yield cmds.setState("stagePublish.points", points);

  if (points.length > 3) {
    const center = points[points.length / 2];
    yield cmds.setState("stagePublish.center", center);
  }

  yield cmds.setState("stagePublish.points", points);
}
