import { connect, initializable } from "effects";
import * as functions from "./functions";
import GuideList from "./GuideList";
import { get } from "object-path";

const mapStateToProps = (state, ownProps, getState) => {
    const pathId = get(ownProps, "match.params.pathId");
    return {
        loading: getState("caminoGuide.loading", true),
        path: getState("app.path"),
        guide: getState("guide.list", []),
        pathId
    };
};

export default connect(initializable(GuideList), mapStateToProps, functions);
