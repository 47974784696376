import React from "react";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import { Field } from "formik";

export default function({
  name,
  loading,
  options,
  map,
  onChange,
  onBlur,
  value,
  label
}) {
  return (
    <Field name={name}>
      {({ field, form, meta }) => {
        const fieldValue = options.filter(
          o => field.value && field.value.includes(o.value)
        );
        const filteredOptions = options.filter(
          o => !field.value || !field.value.includes(o.value)
        );
        return (
          <FormControl error={Boolean(meta.error)}>
            <Autocomplete
              multiple
              loading={loading}
              loadingText="Loading..."
              options={filteredOptions}
              getOptionLabel={option => {
                return option.label;
              }}
              value={fieldValue}
              renderInput={params => (
                <TextField
                  {...params}
                  label={"Select " + label}
                  variant="outlined"
                  fullWidth
                />
              )}
              onChange={(e, v) => {
                form.setFieldValue(
                  name,
                  v.map(i => i.value)
                );
              }}
              onClose={field.onBlur}
            />
            {meta.error && <FormHelperText>{meta.error}</FormHelperText>}
          </FormControl>
        );
      }}
    </Field>
  );
}
