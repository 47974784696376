import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Link from "@material-ui/core/Link";
import Button from "@material-ui/core/Button";
import AppLoading from "components/common/AppLoading";
import Page from "components/common/Page";
import Map from "./Map";

const useStyles = makeStyles({
  table: {
    width: "100%",
    overflowX: "auto"
  },
  header: {
    display: "flex",
    alignItems: "center"
  },
  mapContainer: {
    marginBottom: 20,
    width: "100%",
    height: 500
  }
});

export default function Cities({
  loading,
  cities,
  path,
  pathId,
  deleteCity,
  pathPoints
}) {
  const classes = useStyles();
  if (loading) return <AppLoading />;

  return (
    <Page
      path={path}
      title="Cities and Towns"
      crumbs={[
        {
          name: "Cities and Towns",
          href: "/cities"
        }
      ]}
    >
      <Button
        color="primary"
        variant="contained"
        href={`/paths/${pathId}/cities/create`}
        style={{ marginBottom: 20 }}
      >
        New City
      </Button>

      <div className={classes.mapContainer}>
        <Map cities={cities} pathPoints={pathPoints} center={path.gpsCenter} />
      </div>
      <Paper className={classes.table}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Distance from Origin</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {cities.map(city => (
              <TableRow key={city.id}>
                <TableCell component="th" scope="row">
                  <Link href={`/paths/${pathId}/cities/${city.id}`}>
                    {city.name}
                  </Link>
                </TableCell>
                <TableCell component="th" scope="row">
                  {(city.distanceFromOriginInMeters / 1000).toFixed(1)}km
                </TableCell>
                <TableCell component="th" scope="row">
                  <Link href={`/paths/${pathId}/cities/edit/${city.id}`}>
                    Edit
                  </Link>
                  &nbsp;
                  <Link href="#" onClick={() => deleteCity(city.id)}>
                    Delete
                  </Link>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Paper>
    </Page>
  );
}
