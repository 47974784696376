import { get } from "object-path";

const url = process.env.REACT_APP_RPC_URL;

export async function rpc({ fn, payload }) {
  try {
    const result = await fetch(url + "/v1/" + fn, {
      method: "POST",
      credentials: "include",
      body: JSON.stringify(payload)
    });

    return parse(fn, result);
  } catch (e) {
    const grpcResponse = get(e, "response.data");
    if (!grpcResponse) throw e;

    const grpcError = new Error(grpcResponse.message);
    grpcError.code = grpcResponse.code;

    throw grpcError;
  }
}

export async function gateway({ path, payload }) {
  const result = await fetch(url + path, {
    method: "POST",
    credentials: "include",
    body: JSON.stringify(payload)
  });

  return parse(path, result);
}

async function parse(fn, response) {
  const text = await response.text();

  if (response.status >= 300) {
    let message = "";
    try {
      message = JSON.parse(text).message;
    } catch (e) {
      // noop
    }

    const err = new Error(message || text);
    err.status = response.status;
    throw err;
  }

  const isStream = response.headers.get("grpc-metadata-type") === "stream";

  // handle GRPC streams
  if (isStream) {
    const lines = text.split("\n");
    const results = lines
      .filter(v => v)
      .map(l => JSON.parse(l))
      .map(r => r.result);
    return results;
  }

  if (text === "") {
    return null;
  }

  try {
    return JSON.parse(text);
  } catch (e) {
    console.error("unable to parse:", text);
    throw e;
  }
}
