import React from "react";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import Link from "@material-ui/core/Link";

export default function CaminoListItem({
  path,
  icon,
  label,
  description,
  url,
  onClick
}) {
  return (
    <ListItem>
      <ListItemAvatar>
        <Link href={url} onClick={onClick} style={{ cursor: "pointer" }}>
          <Avatar>{icon}</Avatar>
        </Link>
      </ListItemAvatar>
      <Link
        href={url}
        onClick={onClick}
        underline="none"
        color="textPrimary"
        style={{ cursor: "pointer" }}
      >
        <ListItemText primary={label} secondary={description} />
      </Link>
    </ListItem>
  );
}
