import React from "react";
import { createStore } from "redux";
import {
  addInterpreters,
  onError,
  promisify,
  interpreters as coreInterpreters,
  cmds as coreCmds,
  getInterpreters,
  getContext
} from "effects-as-data/es5";
import { call } from "effects-as-data/es5/core";
import { get } from "object-path";
import { set, push } from "object-path-immutable";
import * as RR from "react-redux";
import uniqBy from "lodash/uniqBy";
import * as appCmds from "../cmds";
import * as appInterpreters from "../interpreters";

export { addToContext } from "effects-as-data/es5";
export { initializable } from "./initializable";

const meta = {};

export function addMeta(key, value) {
  meta[key] = value;
}

let reducers = [];

export function addReducer(r) {
  reducers.push(r);
}

export const store = createStore(function (state = {}, action = {}) {
  let newState = state;
  if (action.type === "setState") {
    newState = set(newState, action.path, action.value);
  }

  if (action.type === "pushState") {
    if (action.uniqueKey) {
      const list = get(newState, action.path) || [];
      const filteredList = list.filter(
        v => v[action.uniqueKey] !== action.value[action.uniqueKey]
      );
      const combinedList = [...filteredList, action.value];
      const uniqueList = uniqBy(combinedList, v => v[action.uniqueKey]);
      newState = set(newState, action.path, uniqueList);
    } else {
      newState = push(newState, action.path, action.value);
    }
  }

  if (action.type === "replaceState") {
    const list = get(newState, action.path) || [];
    const newList = list.map(m => {
      if (m[action.uniqueKey] === action.value[action.uniqueKey]) {
        return action.value;
      }
      return m;
    });
    newState = set(newState, action.path, newList);
  }

  return applyCustomerReducers(newState, action);
});

function applyCustomerReducers(state, action = {}) {
  let newState = state;
  for (let i = 0; i < reducers.length; i++) {
    newState = reducers[i](state, action);
  }
  return newState;
}

// for debugging
window.store = store;

export const setState = ({ path, value }) => {
  store.dispatch({ type: "setState", path, value });
};

export const getState = ({ path, defaultValue }) => {
  return get(store.getState(), path, defaultValue);
};

export const pushState = ({ path, value, uniqueKey }) => {
  store.dispatch({ type: "pushState", path, value, uniqueKey });
};

export const replaceState = ({ path, value, uniqueKey }) => {
  store.dispatch({ type: "replaceState", path, value, uniqueKey });
};

export const stateCmds = {
  getState(path, defaultValue) {
    return {
      type: "getState",
      path,
      defaultValue
    };
  },
  setState(path, value) {
    return {
      type: "setState",
      path,
      value
    };
  },
  pushState(path, value, uniqueKey) {
    return {
      type: "pushState",
      path,
      value,
      uniqueKey
    };
  },
  replaceState(path, value, uniqueKey) {
    return {
      type: "replaceState",
      path,
      value,
      uniqueKey
    };
  },
  clearState(path) {
    return {
      type: "setState",
      path,
      value: undefined
    };
  },
  props() {
    return {
      type: "props"
    };
  }
};

export const cmds = {
  ...coreCmds,
  ...stateCmds,
  ...appCmds
};

const errorToObject = e => {
  const props = Object.getOwnPropertyNames(e).concat("name");
  return props.reduce((p, c) => {
    p[c] = e[c];
    return p;
  }, {});
};

function handleError(error, context) {
  if (error.message && error.message.match(/Failed to fetch/i)) return;
  if (window.Rollbar) {
    window.Rollbar.error(error, {
      message: error.message,
      err: errorToObject(error),
      custom: {
        context,
        meta
      }
    });
  } else {
    console.error("Error:", error); // eslint-disable-line no-console
    console.error("Context", context); // eslint-disable-line no-console
  }
}

onError(handleError);

addInterpreters({
  ...coreInterpreters,
  ...appInterpreters,
  getState,
  setState,
  pushState,
  replaceState
});

export { addInterpreters, promisify };

// eslint-disable-next-line
export function Provider({ children }) {
  return <RR.Provider store={store}>{children}</RR.Provider>;
}

export function connect(Component, mapStateToProps, functions) {
  function mapDispatchToProps(_, props) {
    const functions2 =
      typeof functions === "function"
        ? { ...functions(props) }
        : { ...functions };
    for (let i in functions2) {
      functions2[i] = (fn => {
        const localInterpreters = { props: () => props, ...getInterpreters() };
        return (...args) =>
          call(getContext(), localInterpreters, fn, ...args).catch(error => {
            return {
              success: false,
              error
            };
          });
      })(functions2[i]);
    }
    return functions2;
  }

  function mapStateToPropsSafe(state, ownProps) {
    function safeGetter(path, defaultValue = undefined) {
      return get(state, path, defaultValue);
    }
    return mapStateToProps(state, ownProps, safeGetter);
  }

  return RR.connect(mapStateToPropsSafe, mapDispatchToProps)(Component);
}
