const { isVideoFile } = require("./helpers/media");

function getVideoThumbnail(file, done) {
  try {
    let url = URL.createObjectURL(file);
    var video = document.createElement("video");
    video.setAttribute("src", url);
    video.setAttribute("style", "position: absolute; left: -10000px; top: 0;");
    var canvas = document.createElement("canvas");

    video.addEventListener("error", done);

    video.addEventListener("loadeddata", function() {
      video.play();
    });

    video.addEventListener("play", function() {
      try {
        var width = video.videoWidth;
        var height = video.videoHeight;
        canvas.width = width;
        canvas.height = height;
        var ctx = canvas.getContext("2d");
        ctx.drawImage(video, 0, 0, width, height);
        document.body.removeChild(video);
        done(null, ctx);
      } catch (e) {
        done(e);
      }
    });

    document.body.appendChild(video);
  } catch (e) {
    done(e);
  }
}

function getImageThumbnail(file, orientation, done) {
  try {
    let url = typeof file === "string" ? file : URL.createObjectURL(file);
    var img = new Image();

    img.src = url;

    img.onerror = done;

    img.onload = () => {
      var rotate = false;
      if (orientation) {
        rotate = [5, 6, 7, 8].indexOf(orientation) > -1;
      }

      var width = 225;
      var ratio = width / img.width;
      var height = img.height * ratio;

      var c = document.createElement("canvas");

      c.width = rotate ? height * 2 : width * 2;
      c.height = rotate ? width * 2 : height * 2;
      var ctx = c.getContext("2d");

      switch (orientation) {
        case 1:
          ctx.drawImage(img, 0, 0, width * 2, height * 2);
          break;

        case 2:
          ctx.translate(width * 2, 0);
          ctx.scale(-1, 1);
          ctx.drawImage(img, 0, 0, width * 2, height * 2);
          break;

        case 3:
          ctx.translate(c.width, c.height);
          ctx.rotate((180 * Math.PI) / 180);
          ctx.drawImage(img, 0, 0, width * 2, height * 2);
          break;

        case 4:
          ctx.translate(c.width, c.height);
          ctx.rotate((180 * Math.PI) / 180);
          ctx.translate(width * 2, 0);
          ctx.scale(-1, 1);
          ctx.drawImage(img, 0, 0, width * 2, height * 2);
          break;

        case 5:
          ctx.translate(c.width, c.height);
          ctx.rotate((90 * Math.PI) / 180);
          ctx.translate(0, height * 2);
          ctx.scale(1, -1);
          ctx.drawImage(img, -2 * width, 0, width * 2, height * 2);
          break;

        case 6:
          ctx.translate(c.width, c.height);
          ctx.rotate((90 * Math.PI) / 180);
          ctx.drawImage(img, -width * 2, 0, width * 2, height * 2);
          break;

        case 7:
          ctx.translate(c.width, c.height);
          ctx.rotate((-90 * Math.PI) / 180);
          ctx.translate(0, 2 * height);
          ctx.scale(1, -1);
          ctx.drawImage(img, 0, height * 2, width * 2, height * 2);
          break;

        case 8:
          ctx.translate(c.width, c.height);
          ctx.rotate((-90 * Math.PI) / 180);
          ctx.drawImage(img, 0, -2 * height, width * 2, height * 2);
          break;

        default:
          ctx.drawImage(img, 0, 0, width * 2, height * 2);
      }

      ctx.restore();

      done(null, ctx);
    };
  } catch (e) {
    done(e);
  }
}

export function generateVideoPreview({ file }) {
  return new Promise((resolve, reject) => {
    try {
      getVideoThumbnail(file, (err, renderingContext) => {
        if (err) return reject(err);
        resolve(renderingContext.canvas);
      });
    } catch (e) {
      reject(e);
    }
  });
}

export function generateImagePreview({ file, orientation }) {
  return new Promise((resolve, reject) => {
    try {
      if (isVideoFile(file.name)) {
        getVideoThumbnail(file, (err, canvas) => {
          if (err) return reject(err);
          resolve(canvas);
        });
      } else {
        getImageThumbnail(file, orientation, (err, canvas) => {
          if (err) return reject(err);
          resolve(canvas);
        });
      }
    } catch (e) {
      reject(e);
    }
  });
}
