import React from "react";
import Media from "components/common/form/Media";
import Address from "components/common/form/Address";
import Hours from "components/common/form/Hours";
import Location from "components/common/form/Location";
import Section from "components/common/form/Section";
import Switch from "components/common/form/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import TextField from "components/common/form/TextField";
import { get, set } from "object-path";
import assert from "assert";
import { isURL, isEmail } from "validator";
import { parsePhoneNumber } from "libphonenumber-js";

export function StandardPOIFields({
  path,
  city,
  pathPoints,
  handleChange,
  handleBlur,
  values,
  errors,
  setFieldValue
}) {
  assert(setFieldValue, "setFieldValue required");

  return (
    <div>
      <Section title="Publishing">
        <FormControlLabel
          control={
            <Switch
              name="draft"
              checked={Boolean(values.draft)}
              onChange={handleChange}
              color="secondary"
              value={true}
            />
          }
          label="Draft"
        />
      </Section>

      <Section title="Details">
        <TextField label="Name" name="name" />

        <TextField label="Website" name="website" />

        <TextField label="Phone" name="phone" />

        <TextField label="Email" name="email" />

        <TextField label="Description" name="description" multiline />

        <TextField
          label="Special Instructions"
          name="specialInstructions"
          multiline
        />
      </Section>

      <TextField label="Promotional Material URL" name="promoUrl" />

      <Section title="Address">
        <Address
          values={values}
          errors={errors}
          handleChange={handleChange}
          handleBlur={handleBlur}
        />
      </Section>

      <Section title="Hours">
        <Hours
          values={values}
          errors={errors}
          handleChange={handleChange}
          handleBlur={handleBlur}
          setFieldValue={setFieldValue}
        />
      </Section>

      <Section title="Location">
        <Location
          values={values}
          errors={errors}
          handleChange={handleChange}
          handleBlur={handleBlur}
          pathPoints={pathPoints}
          setFieldValue={setFieldValue}
          zoom={19}
        />
      </Section>
      <Section title="Attach Photos">
        <Media name="media" pathId={path.id} />
      </Section>
    </div>
  );
}

export function validateStandardPOIFields(values) {
  const errors = {};

  const isUploading = get(values, "media", []).some(m => m.uploading);
  if (isUploading) {
    errors.media = "Media is still uploading";
  }

  if (!values.name) {
    errors.name = "Required";
  }

  if (values.website && !isURL(values.website)) {
    errors.website = "URL is invalid";
  }

  if (values.email && !isEmail(values.email)) {
    errors.email = "Email address is invalid";
  }

  if (values.phone) {
    try {
      const number = parsePhoneNumber(values.phone);
      if (!number.isValid()) {
        errors.phone = "Invalid phone number";
      }
    } catch (e) {
      errors.phone = e.message;
    }
  }

  if (values.promoUrl && !isURL(values.promoUrl)) {
    errors.promoUrl = "URL is invalid";
  }

  if (!get(values, "location.lat")) {
    set(errors, "location.lat", "Required");
  }

  if (!get(values, "location.lng")) {
    set(errors, "location.lng", "Required");
  }

  return errors;
}
