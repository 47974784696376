import React from "react";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Button from "@material-ui/core/Button";
import Switch from "components/common/form/Switch";
import HourRange from "components/common/form/HourRange";

export default function HourSelect({
  name,
  handleChange,
  day,
  closedToday,
  syncDays,
  setClosed
}) {
  return (
    <div style={{ marginBottom: 20, display: "flex", alignItems: "center" }}>
      <div style={{ display: "flex", marginRight: 10 }}>
        <HourRange
          startLabel="Start 1"
          startName={`hours.${day}.start1`}
          startLabelWidth={70}
          endLabel="End 1"
          endName={`hours.${day}.end1`}
          endLabelWidth={75}
        />
        &nbsp;
        <HourRange
          startLabel="Start 2"
          startName={`hours.${day}.start2`}
          startLabelWidth={70}
          endLabel="End 2"
          endName={`hours.${day}.end2`}
          endLabelWidth={75}
        />
      </div>
      <div style={{ width: 220 }}>
        <FormControlLabel
          control={
            <Switch
              name={`hours.${day}.closedToday`}
              checked={closedToday}
              onChange={e => setClosed(e, day)}
              value={true}
              color="primary"
              size="small"
            />
          }
          label={`Closed on ${capitalize(day)}`}
          labelPlacement="start"
          style={{ marginLeft: 8 }}
        />
        <Button color="primary" onClick={() => syncDays(day)}>
          Sync week to {capitalize(day)}
        </Button>
      </div>
    </div>
  );
}

const capitalize = s => {
  if (typeof s !== "string") return "";
  return s.charAt(0).toUpperCase() + s.slice(1);
};
