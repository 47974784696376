import { connect, initializable } from "effects";
import * as functions from "./functions";
import Search from "./Search";

const mapStateToProps = (state, ownProps, getState) => {
  return {
    loading: getState("search.loading", true),
    results: getState("search.results", []),
    path: getState("app.path"),
    query: getState("search.query", ""),
    searching: getState("searching.searching", false)
  };
};

export default connect(initializable(Search), mapStateToProps, functions);
