import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Link from "@material-ui/core/Link";
import Button from "@material-ui/core/Button";
import AppLoading from "components/common/AppLoading";
import Page from "components/common/Page";
// import Map from "./Map";

const useStyles = makeStyles({
  table: {
    width: "100%",
    overflowX: "auto"
  },
  header: {
    display: "flex",
    alignItems: "center"
  },
  mapContainer: {
    marginBottom: 20,
    width: "100%",
    height: 500
  }
});

export default function Stages({
  loading,
  stages,
  path,
  pathId,
  deleteStage,
  pathPoints
}) {
  const classes = useStyles();

  if (loading) return <AppLoading />;
  return (
    <Page
      path={path}
      title="Stages"
      crumbs={[
        {
          name: "Stages",
          href: "/stages"
        }
      ]}
    >
      <Button
        color="primary"
        variant="contained"
        href={`/paths/${pathId}/stages/create`}
        style={{ marginBottom: 20 }}
      >
        New Stage
      </Button>

      <Paper className={classes.table}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Start</TableCell>
              <TableCell>End</TableCell>
              <TableCell>Walking Distance</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {stages.map(stage => (
              <TableRow key={stage.id}>
                <TableCell component="th" scope="row">
                  {stage.cityStartName}
                </TableCell>
                <TableCell component="th" scope="row">
                  {stage.cityEndName}
                </TableCell>
                <TableCell component="th" scope="row">
                  {metersToKm(stage.distanceInMeters)}km
                </TableCell>
                <TableCell component="th" scope="row">
                  <Link href={`/paths/${pathId}/stages/edit/${stage.id}`}>
                    Edit
                  </Link>
                  &nbsp;
                  <Link href="#" onClick={() => deleteStage(stage.id)}>
                    Delete
                  </Link>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Paper>
    </Page>
  );
}

function metersToKm(meters) {
  if (!meters) return 0;
  if (meters < 1000) return meters / 1000;
  return (meters / 1000).toFixed(1);
}
