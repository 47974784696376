import React from "react";
import Button from "@material-ui/core/Button";

export default class CancelButton extends React.Component {
  cancel() {
    window.location.href = this.props.location;
  }

  render() {
    return (
      <Button
        variant="contained"
        color="secondary"
        onClick={() => this.cancel()}
      >
        Cancel
      </Button>
    );
  }
}
