import React from "react";
import {
  Map,
  Polyline,
  Marker,
  GoogleApiWrapper,
  InfoWindow
} from "google-maps-react";
import {renderAlternateRoutes} from "../../helpers";

export class MapContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showingInfoWindow: false,
      activeMarker: {},
      selectedPlace: {}
    };
  }

  onMarkerClick = (props, marker, e) => {
    this.setState({
      selectedPlace: props,
      activeMarker: marker,
      showingInfoWindow: true
    });
  };

  onMapClicked = props => {
    if (this.state.showingInfoWindow) {
      this.setState({
        showingInfoWindow: false,
        activeMarker: null
      });
    }
  };

  render() {
    const { cities, pathPoints, center } = this.props;

    return (
      <Map
        google={this.props.google}
        zoom={7}
        style={{ position: "static", width: "100%", height: 500 }}
        containerStyle={{ position: "static" }}
        initialCenter={center}
      >
        {cities.map(city => (
          <Marker
            key={city.id}
            position={city.location}
            name={city.name}
            icon={{
              url: "/images/marker.png",
              anchor: new google.maps.Point(4, 4), // eslint-disable-line
              scaledSize: new google.maps.Size(8, 8) // eslint-disable-line
            }}
            onClick={this.onMarkerClick}
          />
        ))}
        {pathPoints && (
          <Polyline
            path={pathPoints.main || []}
            strokeColor="#0000FF"
            strokeOpacity={0.8}
            strokeWeight={2}
          />
        )}
        {pathPoints && renderAlternateRoutes(pathPoints.alternateRoutes)}
        <InfoWindow
          marker={this.state.activeMarker}
          visible={this.state.showingInfoWindow}
        >
          <p>{this.state.selectedPlace.name}</p>
        </InfoWindow>
      </Map>
    );
  }
}

export default GoogleApiWrapper({
  apiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY
})(MapContainer);
