import React from "react";
import Paper from "@material-ui/core/Paper";
import Page from "components/common/Page";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import CaminoListItem from "components/common/ListItem";
import PathIcon from "@material-ui/icons/DirectionsWalk";
import ReportAbuseIcon from "@material-ui/icons/ReportProblem";

export default function Paths({ paths, deletePath }) {
  return (
    <Page title="Camino de Santiago">
      <Paper>
        <List>
          <CaminoListItem
            icon={<PathIcon />}
            label="Paths"
            description={"Manage Camino paths."}
            url={`/paths`}
          />

          <Divider variant="inset" component="li" />

          <CaminoListItem
            icon={<ReportAbuseIcon />}
            label="Abuse Reports"
            description={"Handle abuse reports from users."}
            url={`/abuse-reports`}
          />

          <Divider variant="inset" component="li" />

          {/* <CaminoListItem
            // icon={<LiveHelpIcon />}
            label="Camino Guide"
            description={"Handle frequently asked questions."}
            url={`/camino-guide`}
          /> */}
        </List>
      </Paper>
    </Page>
  );
}
