import { cmds } from "effects";
import { get } from "object-path";

export function* setup(props) {
  const pathId = get(props, "match.params.pathId");
  const { path } = yield cmds.rpc("GetPathByID", { id: pathId });
  yield cmds.setState("app.path", path);

  const plannerCategories = yield cmds.rpc("ListPlannerCategories", {
    pathId,
    page: 0,
    pageLength: 1000,
  });

  // const plannerCategoriesWithDefault = plannerCategories.map((c) => {
  //   return c;
  // });

  yield cmds.setState("plannerCategories.list", plannerCategories);
  yield cmds.setState("plannerCategories.loading", false);
}

export function* deletePlannerCategory(id) {
  const confirmed = yield cmds.confirm(
    "Are you sure you want to delete this category?"
  );
  if (!confirmed) return;

  const plannerCategories = yield cmds.getState("plannerCategories.list", []);
  yield cmds.setState(
    "plannerCategories.list",
    plannerCategories.filter((p) => p.id !== id)
  );
  yield cmds.rpc("DeletePlannerCategory", { id });
}
