import { connect, initializable } from "effects";
import * as functions from "./functions";
import PlannerPublish from "./PlannerPublish";

const mapStateToProps = (state, ownProps, getState) => {
  return {
    loading: getState("plannerPublish.loading", true),
    planner: getState("plannerPublish.planner"),
    path: getState("plannerPublish.path"),
    plannerCategory: getState("plannerPublish.plannerCategory"),
  };
};

export default connect(
  initializable(PlannerPublish),
  mapStateToProps,
  functions
);
