import { connect, initializable } from "effects";
import * as functions from "./functions";
import PlaceProblemReportsList from "./PlaceProblemReportsList";

const mapStateToProps = (state, ownProps, prop) => {
  return {
    loading: prop("placeProblemReports.loading", true),
    path: prop("placeProblemReports.path"),
    placeProblemReports: prop("placeProblemReports.list", [])
  };
};

export default connect(
  initializable(PlaceProblemReportsList),
  mapStateToProps,
  functions
);
