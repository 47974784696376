import React from "react";
import TextField from "components/common/form/TextField";
import HourSelect from "./HourSelect";
import { get } from "object-path";
import isEqual from "lodash/isEqual";
import assert from "assert";

const days = [
  "sunday",
  "monday",
  "tuesday",
  "wednesday",
  "thursday",
  "friday",
  "saturday"
];

export default class Hours extends React.Component {
  shouldComponentUpdate(props) {
    if (!isEqual(this.props.values.hours, props.values.hours)) return true;
    if (!isEqual(this.props.errors, props.errors)) return true;
    return false;
  }

  syncDays(syncToDay) {
    const { values, setFieldValue } = this.props;
    days.forEach(day => {
      setFieldValue(`hours.${day}`, values.hours[syncToDay]);
    });
  }

  setClosed(e, day) {
    const { setFieldValue, handleChange } = this.props;
    handleChange(e);

    if (e.target.value) {
      setFieldValue(`hours.${day}.start1`, "");
      setFieldValue(`hours.${day}.end1`, "");
      setFieldValue(`hours.${day}.start2`, "");
      setFieldValue(`hours.${day}.end2`, "");
    }
  }

  render() {
    const {
      handleChange,
      handleBlur,
      values,
      errors,
      setFieldValue
    } = this.props;

    assert(setFieldValue, "setFieldValue required");

    return (
      <div>
        {days.map(day => (
          <HourSelect
            syncDays={day => this.syncDays(day)}
            key={day}
            day={day}
            handleChange={handleChange}
            onBlur={handleBlur}
            closedToday={get(values, `hours.${day}.closedToday`, false)}
            setClosed={(e, day) => this.setClosed(e, day)}
            errors={errors}
          />
        ))}

        <TextField
          label="Hours Notes"
          variant="outlined"
          fullWidth
          margin="normal"
          type="text"
          name="hours.notes"
          onChange={handleChange}
          onBlur={handleBlur}
          value={get(values, "hours.notes", "")}
          error={errors["hours.notes"]}
          helperText={errors["hours.notes"]}
        />
      </div>
    );
  }
}
