import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import Page from "components/common/Page";
import AppLoading from "components/common/AppLoading";
import { format } from "fecha";
import { get } from "object-path";

const useStyles = makeStyles(theme => ({
  page: {
    padding: 20
  }
}));

export default function AbuseReportView({
  loading,
  abuseReport,
  messages,
  closeReport
}) {
  const classes = useStyles();

  if (loading) return <AppLoading />;

  return (
    <Page
      title="Abuse Reports"
      crumbs={[
        {
          name: "Abuse Reports",
          href: "/abuse-reports"
        },
        {
          name: "View report"
        }
      ]}
    >
      <Paper>
        <div className={classes.page}>
          <h2>Report Details</h2>

          <AbuseReportField
            name="Status"
            value={abuseReport.closed ? "Closed" : "Open"}
          />
          <AbuseReportField
            name="Reporting User"
            value={`${abuseReport.reportingUserName} (${abuseReport.reportingUser})`}
          />
          <AbuseReportField
            name="Reported User"
            value={`${abuseReport.reportedUserName} (${abuseReport.reportedUser})`}
          />
          <AbuseReportField
            name="Type"
            value={typeLabel(abuseReport.entityType)}
          />

          <div>
            <h2>Actions</h2>
            <Button
              variant="contained"
              color="primary"
              type="submit"
              disabled={abuseReport.closed}
              onClick={() => closeReport()}
            >
              Close Report
            </Button>
          </div>

          <h2>Content Preview</h2>
          {abuseReport.entityType === "messagethread" && (
            <MessageThreadPreview
              messages={messages}
              abuseReport={abuseReport}
            />
          )}
          {abuseReport.entityType === "story" && (
            <StoryPreview
              storyId={abuseReport.entityId}
              abuseReport={abuseReport}
            />
          )}
        </div>
      </Paper>
    </Page>
  );
}

const fieldStyles = makeStyles(theme => ({
  field: {
    marginBottom: 10
  },
  label: {
    fontWeight: "bold"
  }
}));

function AbuseReportField({ name, value }) {
  const classes = fieldStyles();

  return (
    <div className={classes.field}>
      <span className={classes.label}>{name}</span>: {value}
    </div>
  );
}

function MessageThreadPreview({ messages, abuseReport }) {
  return (
    <div>
      {messages.map(m => (
        <MessagePreview message={m} key={m.id} abuseReport={abuseReport} />
      ))}
    </div>
  );
}

const messagePreviewStyles = makeStyles(theme => ({
  message: {
    marginBottom: 10,
    paddingBottom: 10,
    borderBottom: "1px solid #ccc"
  },
  username: {
    fontWeight: "bold"
  }
}));

function MessagePreview({ message, abuseReport }) {
  const classes = messagePreviewStyles();

  return (
    <div className={classes.message}>
      <div className={classes.username}>{username(message, abuseReport)}</div>
      <div>{timestamp(message.timestamp)}</div>
      <div>{message.content}</div>
      {get(message, "media.url") && (
        <div>
          <a href={get(message, "media.url")}>{get(message, "media.url")}</a>
        </div>
      )}
    </div>
  );
}

function username(message, abuseReport) {
  if (abuseReport.reportingUser === message.userId) {
    return abuseReport.reportingUserName;
  }

  if (abuseReport.reportedUser === message.userId) {
    return abuseReport.reportedUserName;
  }

  return "unknown user";
}

function StoryPreview ({ storyId }) {
  return <a href={`${process.env.REACT_APP_STORY_REVIEW_URL}/share/story/${storyId}`} target={"_blank"}>Review Story</a>;
}

function timestamp(t) {
  const d = new Date();
  if (isNaN(d.getTime())) return "";
  return format(d, "MM-DD-YYYY HH:mm:ss");
}

function typeLabel(t) {
  if (t === "messagethread") return "Message Thread";
  if (t === "story") return "Story";
  return t;
}
