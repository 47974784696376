import { connect, initializable } from "effects";
import * as functions from "./functions";
import TagSelect from "./TagSelect";
import assert from "assert";

const mapStateToProps = (state, ownProps, getState) => {
  const { setName, name, pathId } = ownProps;
  assert(setName, "setName required");
  assert(name, "name required");
  assert(pathId, "pathId required");

  return {
    ...ownProps,
    loading: getState(`tagselect.${name}.loading`, true),
    options: getState(`tagselect.${setName}.options`, [])
  };
};

export default connect(initializable(TagSelect), mapStateToProps, functions);
