import React from "react";
import Loading from "components/common/AppLoading";
import Paper from "@material-ui/core/Paper";
import Page from "components/common/Page";
import List from "@material-ui/core/List";
import SearchIcon from "@material-ui/icons/Search";
import LocationCityIcon from "@material-ui/icons/LocationCity";
import MapIcon from "@material-ui/icons/Map";
import LocalOfferIcon from "@material-ui/icons/LocalOffer";
import ImportRestaurantsIcon from "@material-ui/icons/Restaurant";
import ImportAccommodationsIcon from "@material-ui/icons/Hotel";
import ImportPOIIcon from "@material-ui/icons/Room";
import PlaceProblemsIcon from "@material-ui/icons/ErrorOutline";
import PublishIcon from "@material-ui/icons/DataUsage";
import ContributionsIcon from "@material-ui/icons/Favorite";
import QuestionAnswerIcon from '@material-ui/icons/QuestionAnswer';
import PlannerIcon from "@material-ui/icons/CalendarToday";
import Divider from "@material-ui/core/Divider";
import CaminoListItem from "components/common/ListItem";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import CircularProgress from "@material-ui/core/CircularProgress";
import { withStyles } from "@material-ui/core/styles";

const ColorCircularProgress = withStyles({
  root: {
    color: "#FFFFFF"
  }
})(CircularProgress);

export default function PathsDashboard({
  path,
  publish,
  closeSuccessDialog,
  closeErrorDialog,
  importSuccess,
  importError,
  building
}) {
  if (!path) return <Loading />;

  return (
    <Page path={path} title={path.name}>
      <Paper>
        <List>
          <CaminoListItem
            path={path}
            icon={<SearchIcon />}
            label={`Search`}
            description={`Search the ${path.name}.`}
            url={`/paths/${path.id}/search`}
          />

          <Divider variant="inset" component="li" />

          <CaminoListItem
            path={path}
            icon={<LocationCityIcon />}
            label="Cities and Towns"
            description={"Manage cities and towns on the " + path.name}
            url={`/paths/${path.id}/cities`}
          />

          <Divider variant="inset" component="li" />

          <CaminoListItem
            path={path}
            icon={<MapIcon />}
            label="Stages"
            description={"Manage the stages of " + path.name}
            url={`/paths/${path.id}/stages`}
          />

          <Divider variant="inset" component="li" />

          <CaminoListItem
            path={path}
            icon={<LocalOfferIcon />}
            label="Manage Tags"
            description={`Manage tags for ${path.name} entities`}
            url={`/paths/${path.id}/tags`}
          />

          <Divider variant="inset" component="li" />

          <CaminoListItem
            path={path}
            icon={<ImportAccommodationsIcon />}
            label="Import Accommodations"
            description={`Import accommodations for ${path.name}`}
            url={`/paths/${path.id}/import-data/accommodations`}
          />

          <Divider variant="inset" component="li" />

          <CaminoListItem
            path={path}
            icon={<ImportRestaurantsIcon />}
            label="Import Restaurants"
            description={`Import restaurants for ${path.name}`}
            url={`/paths/${path.id}/import-data/restaurants`}
          />

          <Divider variant="inset" component="li" />

          <CaminoListItem
            path={path}
            icon={<ImportPOIIcon />}
            label="Import Points-of-interest"
            description={`Import points-of-interest for ${path.name}`}
            url={`/paths/${path.id}/import-data/pois`}
          />

          <Divider variant="inset" component="li" />

          <CaminoListItem
            path={path}
            icon={
              building ? (
                <ColorCircularProgress size={20} thickness={6} />
              ) : (
                <PublishIcon />
              )
            }
            label="Publish Data"
            description={
              building
                ? "Publishing path data..."
                : `Publish data for ${path.name}`
            }
            onClick={publish}
          />

          <Divider variant="inset" component="li" />

          <CaminoListItem
            path={path}
            icon={<PlaceProblemsIcon />}
            label="Place Problem Reports"
            description={`User submitted place problem reports for ${path.name}`}
            url={`/paths/${path.id}/place-problems`}
          />

          <Divider variant="inset" component="li" />

          <CaminoListItem
            path={path}
            icon={<ContributionsIcon />}
            label="Contributions"
            description={`Manage contributions for ${path.name}`}
            url={`/paths/${path.id}/contributions`}
          />

          <CaminoListItem
            path={path}
            icon={<QuestionAnswerIcon />}
            label="Guide"
            description={`Manage FAQs for ${path.name}`}
            url={`/paths/${path.id}/guide`}
          />
          <Divider variant="inset" component="li" />

          <CaminoListItem
            path={path}
            icon={<PlannerIcon />}
            label="Planner Category"
            description={`Manage planner categories for ${path.name}`}
            url={`/paths/${path.id}/planner-categories`}
          />
        </List>

        <Snackbar
          open={Boolean(importSuccess)}
          autoHideDuration={6000}
          onClose={closeSuccessDialog}
        >
          <Alert onClose={closeSuccessDialog} severity="success">
            Data has been published for {path.name}.
          </Alert>
        </Snackbar>
        <Snackbar
          open={Boolean(importError)}
          autoHideDuration={10000}
          onClose={closeErrorDialog}
        >
          <Alert onClose={closeErrorDialog} severity="error">
            There was an error publishing data.
          </Alert>
        </Snackbar>
      </Paper>
    </Page>
  );
}

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
