import { connect, initializable } from "effects";
import * as functions from "./functions";
import MediaList from "./MediaList";

const mapStateToProps = (state, ownProps, getState) => {
  const namespace = ownProps.name || "publish";

  return {
    namespace,
    media: getState(`${namespace}.media`, []),
    uploading: getState(`${namespace}.uploading`, false),
    uploadProgress: getState(`${namespace}.progress`, {}),
    moveMedia: "",
    isMobile: "",
    pathId: ownProps.pathId,
    isSingle: getState(`${namespace}.isSingle`, false),
  };
};

export default connect(initializable(MediaList), mapStateToProps, functions);
