import React from "react";
import AlternateRouteForm from "./AlternateRouteForm";
import Button from "@material-ui/core/Button";
import mongoid from "mongoid-js"

export default function AlternateRoutesList({path, removeAltRoute, setFieldValue}) {
    return (
        <div>
            <Button
                variant="contained"
                color="primary"
                style={{marginBottom: 20}}
                onClick={() => {
                    const newId = mongoid();
                    setFieldValue(`alternateRoutes.${newId}`, {
                        id: newId,
                        name: "",
                        description: "",
                        green: false,
                        media: [],
                    });
                }}
            >
                Add Alternate Route
            </Button>
            {mm(path, removeAltRoute, setFieldValue)}
        </div>
    );
}

function mm(path, removeAltRoute, setFieldValue) {
    let out = []
    if (!path.alternateRoutes) return out;

    for (let id in path.alternateRoutes) {
        if (!path.alternateRoutes.hasOwnProperty(id)) continue;
        out.push(<AlternateRouteForm key={id} path={path} route={path.alternateRoutes[id]}
                                     removeAltRoute={removeAltRoute} setFieldValue={setFieldValue}/>)
    }
    return out
}