import { cmds } from "effects";
import { get } from "object-path";
import slug from "slug";
import mongoid from "mongoid-js";

export function* setup(props) {
  const pathId = get(props, "match.params.pathId");
  const setName = get(props, "match.params.setName");
  const { path } = yield cmds.rpc("GetPathByID", { id: pathId });
  yield cmds.setState("tags.path", path);

  const tags = yield cmds.rpc("ListTags", {
    pathId,
    setName: setName
  });

  tags.sort((a, b) => get(a, "name", "").localeCompare(get(b, "name", "")));

  yield cmds.setState("tags.loading", false);
  yield cmds.setState("tags.list", tags);
}

export function* deleteTag(id) {
  const confirmed = yield cmds.confirm(
    "Are you sure you want to delete this tag?"
  );
  if (!confirmed) return;

  const tags = yield cmds.getState("tags.list", []);
  yield cmds.setState(
    "tags.list",
    tags.filter(p => p.id !== id)
  );
  yield cmds.rpc("DeleteTag", { id });
}

export function* newTag() {
  const props = yield cmds.props();
  const pathId = get(props, "match.params.pathId");
  const setName = get(props, "match.params.setName");

  yield cmds.setState("tags.edit", {
    pathId,
    setName,
    name: ""
  });

  yield cmds.setState("tags.formOpen", true);
}

export function* editTag(tag) {
  yield cmds.setState("tags.edit", tag);
  yield cmds.setState("tags.formOpen", true);
}

export function* handleTagFormNameChange(value) {
  yield cmds.setState("tags.edit.name", value);
}

export function* tagFormSubmit() {
  const tag = yield cmds.getState("tags.edit");

  const existingTags = yield cmds.getState("tags.list");

  const duplicate = existingTags.some(
    t => t.slug === slug(tag.name, { lower: true })
  );

  if (duplicate) {
    yield cmds.setState("tags.errors.name", "Duplicate tag");
    return;
  }

  if (tag.name.trim() === "") {
    yield cmds.setState("tags.errors.name", "Required");
    return;
  }

  yield cmds.setState("tags.formOpen", false);
  yield cmds.setState("tags.edit", {});
  yield cmds.setState("tags.errors", {});

  let id = tag.id;
  if (tag.id) {
    yield cmds.rpc("UpdateTag", tag);
  } else {
    tag.id = mongoid();
    id = tag.id;
    tag.slug = slug(tag.name, { lower: true });
    yield cmds.rpc("CreateTag", tag);
  }

  const { tag: updated } = yield cmds.rpc("GetTagByID", {
    pathId: tag.pathId,
    id
  });
  yield cmds.call(upsertTag, updated);
}

export function* upsertTag(tag) {
  let tags = yield cmds.getState("tags.list", []);

  const hasTag = tags.some(t => t.id === tag.id);

  if (hasTag) {
    tags = tags.map(t => {
      return t.id === tag.id ? tag : t;
    });
  } else {
    tags = [...tags, tag];
  }

  tags.sort((a, b) => a.name.localeCompare(b.name));

  yield cmds.setState("tags.list", tags);
}

export function* cancel() {
  yield cmds.setState("tags.formOpen", false);
  yield cmds.setState("tags.edit", {});
}
