import React from "react";
import Button from "@material-ui/core/Button";
import AppLoading from "components/common/AppLoading";
import TextField from "components/common/form/TextField";
import { Formik, Form } from "formik";
import Page from "components/common/Page";
import Paper from "components/common/Paper";
import Currency from "./Currency";
import Section from "components/common/form/Section";
import Location from "components/common/form/Location";
import CancelButton from "components/common/form/CancelButton";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "components/common/form/Switch";
import AlternateRoutesList from "./AlternateRoutesList";
import Media from "components/common/form/Media";

export default function Paths({ loading, path, save, validate, removeAltRoute }) {
  if (loading) return <AppLoading />;

  return (
    <Page path={path} title={path.name || "New Path"}>
      <Paper>
        <Formik
          initialValues={path}
          validate={validate}
          onSubmit={async (values, { setSubmitting }) => {
            await save(values);
            setSubmitting(false);
          }}
          noValidate
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            setFieldValue
          }) => (
            <Form onSubmit={handleSubmit}>
              <Section title="Status">
                <div>
                  <FormControlLabel
                    control={
                      <Switch
                        name="production"
                        checked={Boolean(values.production)}
                        color="primary"
                        value={values.production}
                      />
                    }
                    label="Is Live"
                  />
                </div>
                <div>
                  <FormControlLabel
                    control={
                      <Switch
                        name="comingSoon"
                        checked={Boolean(values.comingSoon)}
                        color="primary"
                        value={values.comingSoon}
                      />
                    }
                    label="Is Coming Soon"
                  />
                </div>
              </Section>
              <Section title="Details">
                <TextField label="Path Name" name="name" />

                <TextField label="Default UTC Offset" name="utcOffsetDefault" type="number" />

                <Currency name="currency" labelWidth={65} />
              </Section>

              <Section title="Cover Photo">
                <Media name="media" pathId={path.id} />
              </Section>

              <Section title="GPS Center">
                <Location
                  values={values}
                  name="gpsCenter"
                  zoom={7}
                  errors={errors}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  setFieldValue={setFieldValue}
                />
              </Section>

              <Section title="Alternate Routes">
                <AlternateRoutesList path={values} removeAltRoute={removeAltRoute} setFieldValue={setFieldValue} />
              </Section>

              <Section title="Save Changes">
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  disabled={isSubmitting}
                  style={{ marginRight: 6 }}
                >
                  Save
                </Button>
                <CancelButton location={`/paths`} />
              </Section>
            </Form>
          )}
        </Formik>
      </Paper>
    </Page>
  );
}
