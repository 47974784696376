import { cmds } from "effects";

export function* setup() {
  yield cmds.appLoadingStart();
  try {
    const { ok } = yield cmds.gateway("/me");
    if (ok) yield cmds.setState("app.loggedIn", true);
  } catch (e) {
    // noop
  }
  yield cmds.appLoadingEnd();
}
