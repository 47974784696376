import React from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import AppLoading from "components/common/AppLoading";
import { Formik } from "formik";
import Page from "components/common/Page";
import Paper from "components/common/Paper";
import CancelButton from "components/common/form/CancelButton";
import CitySelect from "components/common/form/CitySelect";
import Section from "components/common/form/Section";
import Media from "components/common/form/Media";
import AccommodationSelectMultiple from "components/common/form/AccommodationSelectMultiple";
import POISelectMultiple from "components/common/form/POISelectMultiple";
import RestaurantSelectMultiple from "components/common/form/RestaurantSelectMultiple";

const PlannerPublish = ({
  loading,
  planner,
  save,
  validate,
  path,
  plannerCategory,
  handleAddDay,
  handleRemoveDay,
}) => {
  if (loading) return <AppLoading />;

  return (
    <Page
      path={path}
      title={planner.title ? "Edit " + planner.title : "New Planner"}
      crumbs={[
        {
          name: "Planner",
          href: `/planners/${plannerCategory.id}`,
        },
        {
          name: planner.title || "New Planner",
        },
      ]}
    >
      <Paper>
        <Formik
          initialValues={planner}
          validate={validate}
          onSubmit={async (values, { setSubmitting }) => {
            await save(values);
            setSubmitting(false);
          }}
          noValidate
        >
          {({
            values,
            errors,
            touched,
            submitCount,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            setFieldValue,
          }) => (
            <form onSubmit={handleSubmit} noValidate>
              <Section title="Details">
                <div style={{ fontSize: "18px" }}>
                  <b>Category: </b>
                  <label>{plannerCategory && plannerCategory.title}</label>
                </div>

                <TextField
                  label="Planner Name"
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  type="text"
                  name="title"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.title || ""}
                  error={Boolean(errors.title)}
                  helperText={errors.title}
                />
              </Section>

              <Section title="Day Details">
                {values.dayDetails &&
                  values.dayDetails.map((e, i) => {
                    return (
                      <div key={e}>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <b>Day {i + 1}</b>
                          <b>
                            <a
                              style={{ color: "red", cursor: "pointer" }}
                              onClick={() =>
                                handleRemoveDay(values, setFieldValue, i)
                              }
                            >
                              Remove
                            </a>
                          </b>
                        </div>

                        <h4 style={{ borderBottom: "1px solid #ddd" }}>
                          Source City
                        </h4>
                        <CitySelect
                          name={`dayDetails[${i}].startCityId`}
                          pathId={path.id}
                        />
                        {((errors[`dayDetails_startCityId_${i}`] &&
                          touched[`dayDetails_startCityId_${i}`]) ||
                          submitCount > 0) && (
                          <span style={{ color: "#f44336" }}>
                            {errors[`dayDetails_startCityId_${i}`]}
                          </span>
                        )}

                        {values.dayDetails[i].startCityId && (
                          <>
                            <AccommodationSelectMultiple
                              key={`startCityAccommodation_${i}_${values.dayDetails[i].startCityId}`}
                              name={`dayDetails[${i}].startCityAccommodationIds`}
                              pathId={path.id}
                              currentIndex={`start_${i}`}
                              cityId={values.dayDetails[i].startCityId}
                            />
                            <POISelectMultiple
                              key={`startCityPOI_${i}_${values.dayDetails[i].startCityId}`}
                              name={`dayDetails[${i}].startCityPoiIds`}
                              pathId={path.id}
                              currentIndex={`start_${i}`}
                              cityId={values.dayDetails[i].startCityId}
                            />
                            <RestaurantSelectMultiple
                              key={`startCityRestaurant_${i}_${values.dayDetails[i].startCityId}`}
                              name={`dayDetails[${i}].startCityRestaurantIds`}
                              pathId={path.id}
                              currentIndex={`start_${i}`}
                              cityId={values.dayDetails[i].startCityId}
                            />
                          </>
                        )}

                        <h4 style={{ borderBottom: "1px solid #ddd" }}>
                          Destination City
                        </h4>
                        <CitySelect
                          name={`dayDetails[${i}].endCityId`}
                          pathId={path.id}
                        />
                        {((errors[`dayDetails_endCityId_${i}`] &&
                          touched[`dayDetails_endCityId_${i}`]) ||
                          submitCount > 0) && (
                          <span style={{ color: "#f44336" }}>
                            {errors[`dayDetails_endCityId_${i}`]}
                          </span>
                        )}

                        {values.dayDetails[i].endCityId && (
                          <>
                            <AccommodationSelectMultiple
                              key={`endCityAccommodation_${i}_${values.dayDetails[i].endCityId}`}
                              name={`dayDetails[${i}].endCityAccommodationIds`}
                              pathId={path.id}
                              currentIndex={`end_${i}`}
                              cityId={values.dayDetails[i].endCityId}
                            />
                            <POISelectMultiple
                              key={`endCityPOI_${i}_${values.dayDetails[i].endCityId}`}
                              name={`dayDetails[${i}].endCityPoiIds`}
                              pathId={path.id}
                              currentIndex={`end_${i}`}
                              cityId={values.dayDetails[i].endCityId}
                            />
                            <RestaurantSelectMultiple
                              key={`endCityRestaurant_${i}_${values.dayDetails[i].endCityId}`}
                              name={`dayDetails[${i}].endCityRestaurantIds`}
                              pathId={path.id}
                              currentIndex={`end_${i}`}
                              cityId={values.dayDetails[i].endCityId}
                            />
                          </>
                        )}

                        {/* <TextField
                          label="Description"
                          variant="outlined"
                          fullWidth
                          margin="normal"
                          type="text"
                          name={`dayDetails[${i}].description`}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.dayDetails[i].description || ""}
                          error={Boolean(errors[`dayDetails_description_${i}`])}
                          helperText={errors[`dayDetails_description_${i}`]}
                        /> */}
                      </div>
                    );
                  })}

                <Button
                  variant="contained"
                  color="primary"
                  type="button"
                  style={{ marginRight: 6 }}
                  onClick={() => handleAddDay(values, setFieldValue)}
                >
                  Add Day
                </Button>
                {((errors[`dayDetails`] && touched[`dayDetails`]) ||
                  submitCount > 0) && (
                  <span style={{ color: "#f44336" }}>
                    {errors[`dayDetails`]}
                  </span>
                )}
              </Section>

              <Section title="Attach Photos">
                <Media name="media" pathId={path.id} />
              </Section>

              <Section title="Save Changes">
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  disabled={isSubmitting}
                  style={{ marginRight: 6 }}
                >
                  Save
                </Button>
                <CancelButton
                  location={`/paths/${path.id}/planners/${plannerCategory.id}`}
                />
              </Section>
            </form>
          )}
        </Formik>
      </Paper>
    </Page>
  );
};

export default PlannerPublish;
