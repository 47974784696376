import { connect, initializable } from "effects";
import * as functions from "./functions";
import CityDashboard from "./CityDashboard";

const mapStateToProps = (state, ownProps, getState) => {
  return {
    path: getState("app.path", null),
    city: getState("citydashboard.city"),
    loading: getState("citydashboard.loading", true)
  };
};

export default connect(
  initializable(CityDashboard),
  mapStateToProps,
  functions
);
