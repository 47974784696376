import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import ImportError from "./ImportError";

const useStyles = makeStyles(theme => ({
  error: {
    // padding: 10,
    // backgroundColor: "red",
    // color: "#fff"
  },
  name: {
    fontSize: 18
  }
}));

export default function ImportErrorList({ validation }) {
  const classes = useStyles();

  const combined = [
    ...(validation.errors || []),
    ...(validation.warnings || [])
  ].filter(v => v);

  if (combined.length === 0) return null;

  return (
    <div>
      <h1 className={classes.name}>{validation.name}</h1>
      {combined.map((e, i) => (
        <ImportError key={i} message={e} />
      ))}
    </div>
  );
}
//
// function safeMap(a, mapper) {
//   return (a || []).map(mapper);
// }
//
// function isEmpty(a) {
//   return !a || a.length === 0;
// }
