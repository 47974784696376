import { cmds } from "effects";
import assert from "assert";

export function* setup({ pathId }) {
  assert(pathId, "pathId required");

  yield cmds.setState("cityselect.loading", true);
  const cities = yield cmds.rpc("ListCities", {
    pathId,
    page: 0,
    pageLength: 1000
  });

  const map = cities.reduce((p, c) => {
    return {
      ...p,
      [c.id]: c
    };
  }, {});

  yield cmds.setState("cityselect.options", cities);
  yield cmds.setState("cityselect.map", map);
  yield cmds.setState("cityselect.loading", false);
}
