import { cmds } from "effects";
import { get } from "object-path";

export function* setup(props) {
  yield cmds.setState("tagdashboard.loading", true);
  const pathId = get(props, "match.params.pathId");
  const { path } = yield cmds.rpc("GetPathByID", { id: pathId });
  yield cmds.setState("app.path", path);
  yield cmds.setState("tagdashboard.loading", false);
}
