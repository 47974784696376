import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Link from "@material-ui/core/Link";
import Button from "@material-ui/core/Button";
import AppLoading from "components/common/AppLoading";
import Page from "components/common/Page";

const useStyles = makeStyles({
  table: {
    width: "100%",
    overflowX: "auto",
  },
  header: {
    display: "flex",
    alignItems: "center",
  },
  mapContainer: {
    marginBottom: 20,
    width: "100%",
    height: 500,
  },
});

const PlannerCategoryList = ({
  loading,
  path,
  plannerCategories,
  pathId,
  deletePlannerCategory,
}) => {
  const classes = useStyles();

  if (loading) return <AppLoading />;

  return (
    <Page
      path={path}
      title="Planner Category"
      crumbs={[
        {
          name: "Planner Category",
          href: "/planner-categories",
        },
      ]}
    >
      <Button
        color="primary"
        variant="contained"
        href={`/paths/${pathId}/planner-categories/create`}
        style={{ marginBottom: 20 }}
      >
        New Category
      </Button>

      <Paper className={classes.table}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {plannerCategories.map((e) => (
              <TableRow key={e.id}>
                <TableCell component="th" scope="row">
                  <Link href={`/paths/${pathId}/planners/${e.id}`}>
                    {e.title}
                  </Link>
                </TableCell>
                <TableCell component="th" scope="row">
                  <Link
                    href={`/paths/${pathId}/planner-categories/edit/${e.id}`}
                  >
                    Edit
                  </Link>
                  &nbsp;
                  <Link href="#" onClick={() => deletePlannerCategory(e.id)}>
                    Delete
                  </Link>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Paper>
    </Page>
  );
};

export default PlannerCategoryList;
