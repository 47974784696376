const EXIF = require("./helpers/exif");

function isVideo(filename) {
  var ext = filename.split(".").pop();
  return (
    ["mov", "mp4", "ogv", "avi", "mov", "flv", "3gp", "m4v"].indexOf(
      ext.toLowerCase()
    ) > -1
  );
}

export function getOrientation(file) {
  return new Promise(resolve => {
    try {
      EXIF.getData(file, function(err) {
        if (err) return resolve(1);
        try {
          const orientation = EXIF.getTag(this, "Orientation");
          resolve(orientation || 1);
        } catch (e) {
          window.rollbar && window.rollbar.error(e);
          resolve(1);
        }
      });
    } catch (e) {
      window.rollbar && window.rollbar.error(e);
      resolve(1);
    }
  });
}

export function imageDimensions({ file }) {
  return new Promise((resolve, reject) => {
    if (isVideo(file.name)) {
      return resolve({});
    }

    var reader = new FileReader();
    var image = new Image();

    reader.readAsDataURL(file);

    reader.onload = function(_file) {
      image.src = _file.target.result;

      image.onload = function() {
        getOrientation(file)
          .then(orientation => {
            const rotate = [5, 6, 7, 8].indexOf(orientation) > -1;

            const dimensions = {
              width: rotate ? this.height : this.width,
              height: rotate ? this.width : this.height
            };

            return dimensions;
          })
          .then(resolve)
          .catch(reject);
      };

      image.onerror = function() {
        reject(new Error("Invalid file type: " + file.type));
      };
    };
  });
}

export function imageOrientation({ file }) {
  return getOrientation(file);
}
