import React from "react";
import TextField from "@material-ui/core/TextField";
import { FastField } from "formik";

export default function TextFieldFast({
  type,
  defaultValue,
  name,
  label,
  multiline,
  fullWidth
}) {
  return (
    <FastField name={name}>
      {({ field, meta }) => {
        return (
          <TextField
            label={label}
            variant="outlined"
            fullWidth={fullWidth === false ? false : true}
            margin="normal"
            type={type || "text"}
            name={name}
            onChange={field.onChange}
            onBlur={field.onBlur}
            value={field.value || defaultValue || ""}
            multiline={Boolean(multiline)}
            error={Boolean(meta.error)}
            helperText={meta.error}
          />
        );
      }}
    </FastField>
  );
}
