import { connect, initializable } from "effects";
import * as functions from "./functions";
import CityList from "./CityList";
import { get } from "object-path";

const mapStateToProps = (state, ownProps, getState) => {
  const pathId = get(ownProps, "match.params.pathId");
  return {
    loading: getState("cities.loading", true),
    path: getState("app.path"),
    cities: getState("cities.list", []),
    pathPoints: getState("app.pathPoints", {}),
    pathId
  };
};

export default connect(initializable(CityList), mapStateToProps, functions);
