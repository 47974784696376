import { connect, initializable } from "effects";
import * as functions from "./functions";
import StageList from "./StageList";
import { get } from "object-path";

const mapStateToProps = (state, ownProps, getState) => {
  const pathId = get(ownProps, "match.params.pathId");
  return {
    loading: getState("stages.loading", true),
    path: getState("stages.path"),
    stages: getState("stages.list", []),
    pathPoints: getState("app.pathPoints"),
    pathId
  };
};

export default connect(initializable(StageList), mapStateToProps, functions);
