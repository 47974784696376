import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Link from "@material-ui/core/Link";
import Page from "components/common/Page";

const useStyles = makeStyles({
  table: {
    width: "100%",
    overflowX: "auto"
  },
  header: {
    display: "flex",
    alignItems: "center"
  }
});

export default function AbuseReports({ abuseReports }) {
  const classes = useStyles();

  return (
    <Page
      title="Abuse Reports"
      crumbs={[
        {
          name: "Abuse Reports",
          href: "/abuse-reports"
        }
      ]}
    >
      <Paper className={classes.table}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Reporting Pilgrim</TableCell>
              <TableCell>Reported Pilgrim</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {abuseReports.map(abuseReport => (
              <TableRow key={abuseReport.id}>
                <TableCell component="th" scope="row">
                  {abuseReport.reportingUserName}
                </TableCell>
                <TableCell component="th" scope="row">
                  {abuseReport.reportedUserName}
                </TableCell>
                <TableCell component="th" scope="row">
                  {abuseReport.closed ? "Closed" : "Open"}
                </TableCell>
                <TableCell component="th" scope="row">
                  <Link href={`/abuse-reports/${abuseReport.id}`}>
                    View Report
                  </Link>
                  &nbsp;
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Paper>
    </Page>
  );
}
