import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Link from "@material-ui/core/Link";
import Button from "@material-ui/core/Button";
import AppLoading from "components/common/AppLoading";
import Page from "components/common/Page";
import Map from "./Map";
import Chip from "@material-ui/core/Chip";

const useStyles = makeStyles({
  table: {
    width: "100%",
    overflowX: "auto"
  },
  header: {
    display: "flex",
    alignItems: "center"
  },
  mapContainer: {
    marginBottom: 20,
    height: 500,
    width: "100%"
  }
});

export default function Restaurants({
  loading,
  restaurants,
  path,
  pathId,
  deleteRestaurant,
  city,
  pathPoints
}) {
  const classes = useStyles();

  if (loading) return <AppLoading />;

  return (
    <Page
      path={path}
      title="Restaurants"
      crumbs={[
        {
          name: "Cities and Towns",
          href: "/cities"
        },
        {
          name: city.name,
          href: `/cities/${city.id}`
        },
        {
          name: "Restaurants",
          href: `/cities/${city.id}/restaurants`
        }
      ]}
    >
      <Button
        color="primary"
        variant="contained"
        href={`/paths/${pathId}/cities/${city.id}/restaurants/create`}
        style={{ marginBottom: 20 }}
      >
        New Restaurant
      </Button>

      <div className={classes.mapContainer}>
        <Map
          center={city.location}
          restaurants={restaurants}
          pathPoints={pathPoints}
        />
      </div>

      <Paper className={classes.table}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {restaurants.map(restaurant => (
              <TableRow key={restaurant.id}>
                <TableCell component="th" scope="row">
                  {restaurant.name}
                </TableCell>
                <TableCell component="th" scope="row">
                  {restaurant.draft ? (
                    <Chip label="draft" color="secondary" />
                  ) : (
                    <Chip label="active" color="primary" />
                  )}
                </TableCell>
                <TableCell component="th" scope="row">
                  <Link
                    href={`/paths/${pathId}/cities/${city.id}/restaurants/edit/${restaurant.id}`}
                  >
                    Edit
                  </Link>
                  &nbsp;
                  <Link
                    href="#"
                    onClick={() => deleteRestaurant(restaurant.id)}
                  >
                    Delete
                  </Link>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Paper>
    </Page>
  );
}
