import {cmds} from "effects";
import {get} from "object-path";

export function* setup(props) {
    const pathId = get(props, "match.params.pathId");
    const {path} = yield cmds.rpc("GetPathByID", {id: pathId});
    yield cmds.setState("placeProblemReports.path", path);

    const paths = yield cmds.rpc("ListPlaceProblems", {
        page: 0,
        pageLength: 1000,
        pathId
    });
    yield cmds.setState("placeProblemReports.list", paths);
    yield cmds.setState("placeProblemReports.loading", false);
}

export function* closeReport(placeProblemReport) {
    const placeProblemReportUpdated = {...placeProblemReport, resolved: true}
    yield cmds.rpc("UpdatePlaceProblem", placeProblemReportUpdated);

    let reports = yield cmds.getState("placeProblemReports.list", [])
    reports = reports.map((r) => {
        if (r.id !== placeProblemReportUpdated.id) return r;
        return placeProblemReportUpdated;
    })

    yield cmds.setState("placeProblemReports.list", reports);
}