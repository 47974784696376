import React from "react";
import {
    Map,
    Polyline,
    Marker,
    GoogleApiWrapper,
    InfoWindow
} from "google-maps-react";
import {renderAlternateRoutes} from "../../helpers";

export class MapContainer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showingInfoWindow: false,
            activeMarker: {},
            selectedPlace: {}
        };
    }

    onMarkerClick = (props, marker, e) => {
        this.setState({
            selectedPlace: props,
            activeMarker: marker,
            showingInfoWindow: true
        });
    };

    onMapClicked = props => {
        if (this.state.showingInfoWindow) {
            this.setState({
                showingInfoWindow: false,
                activeMarker: null
            });
        }
    };

    render() {
        const {accommodations, pathPoints, center} = this.props;

        const accommodationsWithLocations = accommodations.filter(
            a => a.location && a.location.lat
        );

        return (
            <Map
                google={this.props.google}
                zoom={16}
                style={{position: "static", width: "100%", height: 500}}
                containerStyle={{position: "static"}}
                initialCenter={center}
            >
                {accommodationsWithLocations.map(accommodation => (
                    <Marker
                        key={accommodation.id}
                        position={accommodation.location}
                        name={accommodation.name}
                        icon={{
                            url: "/images/marker.png",
                            anchor: new google.maps.Point(4, 4), // eslint-disable-line
                            scaledSize: new google.maps.Size(8, 8) // eslint-disable-line
                        }}
                        onClick={this.onMarkerClick}
                    />
                ))}
                {pathPoints && (
                    <Polyline
                        path={pathPoints.main || []}
                        strokeColor="#0000FF"
                        strokeOpacity={0.8}
                        strokeWeight={2}
                    />
                )}
                {pathPoints && renderAlternateRoutes(pathPoints.alternateRoutes)}
                <InfoWindow
                    marker={this.state.activeMarker}
                    visible={this.state.showingInfoWindow}
                >
                    <p>{this.state.selectedPlace.name}</p>
                </InfoWindow>
            </Map>
        );
    }
}

export default GoogleApiWrapper({
    apiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY
})(MapContainer);
