import {cmds} from "effects";
import {get, set} from "object-path";
import mongoid from "mongoid-js"

export function* setup(props) {
    yield cmds.setState("pathpublish.loading", true);
    const pathId = get(props, "match.params.pathId");
    if (pathId) {
        const {path} = yield cmds.rpc("GetPathByID", {id: pathId});

        if (!path.utcOffsetDefault) path.utcOffsetDefault = 0;

        yield cmds.setState("pathpublish.path", path);
    } else {
        yield cmds.setState("pathpublish.path", {});
    }
    yield cmds.setState("pathpublish.loading", false);
}

export function* save(path) {
    if (path.id) {
        yield cmds.rpc("UpdatePath", path);
    } else {
        path = {
            id: mongoid(),
            ...path
        }
        yield cmds.rpc("CreatePath", path);
    }
    yield cmds.redirect("/paths");
}

// eslint-disable-next-line require-yield
export function* validate(values) {
    const errors = {};

    if (!values.name) {
        errors.name = "Required";
    }

    if (!values.currency) {
        errors.currency = "Required";
    }

    if (!get(values, "gpsCenter.lat")) {
        set(errors, "gpsCenter.lat", "Required");
    }

    if (!get(values, "gpsCenter.lng")) {
        set(errors, "gpsCenter.lng", "Required");
    }

    const routes = get(values, "alternateRoutes", {})

    for (let id in routes) {
        if (!routes.hasOwnProperty(id)) continue
        if (!routes[id].name) {
            set(errors, `alternateRoutes.${id}.name`, "Required");
        }
    }

    return errors;
}