const {imageDimensions} = require("./image");
const uuid = require("uuid");

export default () => {
  const {firebase, firebaseConfig, imgixUrl} = window;
  window.firebase.initializeApp(firebaseConfig);

  const storageRef = firebase.storage().ref();

  const transactions = {};

  function firebaseUploadCancel({transactionId}) {
    const transaction = transactions[transactionId];
    if (!transaction) return;
    return transaction.cancel();
  }

  function firebaseUpload(
    {file, uploadPath, transactionId, pathId, onProgress, onEnd, onError, onCancel, ctx = {}},
    {interpreters, context, call}
  ) {
    if (!transactionId) transactionId = uuid.v4();
    if (!uploadPath) uploadPath = `/assets/${pathId}/${transactionId}`;

    const uploadRef = storageRef.child(uploadPath);
    const uploadTask = uploadRef.put(file);


    transactions[transactionId] = uploadTask;

    uploadTask.on(
      firebase.storage.TaskEvent.STATE_CHANGED,
      function (snapshot) {
        var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        if (onProgress)
          call(context, interpreters, onProgress, {
            transactionId,
            progress,
            ctx
          });
      },
      error => {
        delete transactions[transactionId];
        if (error.code === "storage/canceled") {
          if (onCancel)
            call(context, interpreters, onCancel, {transactionId, ctx});
        } else {
          if (onError)
            call(context, interpreters, onError, {transactionId, error, ctx});
        }
      },
      function () {
        const url = `${imgixUrl}${uploadPath}`;
        if (onEnd) {
          return imageDimensions({file})
            .then(dims => {
              delete transactions[transactionId];
              return uploadRef.getDownloadURL().then(publicUrl => {
                return call(context, interpreters, onEnd, {
                  transactionId,
                  uploadPath,
                  pathId,
                  url,
                  publicUrl,
                  snapshot: uploadTask.snapshot,
                  dims,
                  ctx
                });
              });
            })
            .catch(error => {
              delete transactions[transactionId];
              if (onError)
                call(context, interpreters, onError, {
                  transactionId,
                  error,
                  ctx
                });
            });
        }
      }
    );

    return transactionId;
  }

  function firebaseDataSheetUploadCancel({transactionId}) {
    const transaction = transactions[transactionId];
    if (!transaction) return;
    return transaction.cancel();
  }

  function firebaseDataSheetUpload(
    {file, pathId, onProgress, onEnd, onError, onCancel, ctx = {}},
    {interpreters, context, call}
  ) {
    const transactionId = uuid.v4();
    const uploadPath = `/data/${pathId}/${transactionId}`;
    const uploadRef = storageRef.child(uploadPath);
    const uploadTask = uploadRef.put(file);

    transactions[transactionId] = uploadTask;

    uploadTask.on(
      firebase.storage.TaskEvent.STATE_CHANGED,
      function (snapshot) {
        var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        if (onProgress)
          call(context, interpreters, onProgress, {
            transactionId,
            progress,
            ctx
          });
      },
      error => {
        delete transactions[transactionId];
        if (error.code === "storage/canceled") {
          if (onCancel)
            call(context, interpreters, onCancel, {transactionId, ctx});
        } else {
          if (onError)
            call(context, interpreters, onError, {transactionId, error, ctx});
        }
      },
      function () {
        const url = uploadTask.snapshot.downloadURL;
        if (onEnd) {
          delete transactions[transactionId];
          return uploadRef
            .getDownloadURL()
            .then(publicUrl => {
              return call(context, interpreters, onEnd, {
                transactionId,
                pathId,
                url,
                publicUrl,
                snapshot: uploadTask.snapshot,
                ctx
              });
            })
            .catch(error => {
              delete transactions[transactionId];
              if (onError)
                call(context, interpreters, onError, {
                  transactionId,
                  error,
                  ctx
                });
            });
        }
      }
    );

    return transactionId;
  }

  return {
    firebaseUpload,
    firebaseUploadCancel,
    firebaseDataSheetUpload,
    firebaseDataSheetUploadCancel
  };
};
