
import { cmds } from "effects";
import { get } from "object-path";

export function* setup(props) {
    const pathId = get(props, "match.params.pathId");

    const { path } = yield cmds.rpc("GetPathByID", { id: pathId });
    yield cmds.setState("app.path", path);


    const guide = yield cmds.rpc("ListGuideTitles", {
        pathId,
        page: 0,
        pageLength: 1000
    });

    yield cmds.setState("guide.list", guide);
    yield cmds.setState("caminoGuide.loading", false);
}

export function* DeleteGuideTitle(id) {
    const confirmed = yield cmds.confirm(
        "Are you sure you want to delete this guide title?"
    );
    if (!confirmed) return;

    const guides = yield cmds.getState("guide.list", []);
    yield cmds.setState(
        "guide.list",
        guides.filter(p => p.id !== id)
    );
    yield cmds.rpc("DeleteGuideTitle", { id });
}