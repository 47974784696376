import { connect, initializable } from "effects";
import * as functions from "./functions";
import AbuseReportsList from "./AbuseReportsList";

const mapStateToProps = (state, ownProps, prop) => {
  return {
    abuseReports: prop("abuseReports.list", [])
  };
};

export default connect(
  initializable(AbuseReportsList),
  mapStateToProps,
  functions
);
