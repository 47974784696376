import React from "react";
import Loading from "components/common/AppLoading";
import Paper from "@material-ui/core/Paper";
import Page from "components/common/Page";
import List from "@material-ui/core/List";
import RestaurantIcon from "@material-ui/icons/Restaurant";
import HotelIcon from "@material-ui/icons/Hotel";
import RoomIcon from "@material-ui/icons/Room";
import Divider from "@material-ui/core/Divider";
import CaminoListItem from "components/common/ListItem";

export default function CityDashboard({ city, path, loading }) {
  if (loading) return <Loading />;

  return (
    <Page
      path={path}
      title={path.name}
      crumbs={[
        {
          name: "Cities and Towns",
          href: "/cities"
        },
        {
          name: city.name,
          href: "/cities/" + city.id
        }
      ]}
    >
      <Paper>
        <List>
          <CaminoListItem
            path={path}
            icon={<HotelIcon />}
            label="Accommodations"
            description={"Manage accommodations in " + city.name}
            url={`/paths/${path.id}/cities/${city.id}/accommodations`}
          />

          <Divider variant="inset" component="li" />

          <CaminoListItem
            path={path}
            icon={<RestaurantIcon />}
            label="Restaurants"
            description={"Manage restaurants in " + city.name}
            url={`/paths/${path.id}/cities/${city.id}/restaurants`}
          />

          <Divider variant="inset" component="li" />

          <CaminoListItem
            path={path}
            icon={<RoomIcon />}
            label="Points of interest"
            description={"Manage points of interest in " + city.name}
            url={`/paths/${path.id}/cities/${city.id}/pois`}
          />
        </List>
      </Paper>
    </Page>
  );
}
