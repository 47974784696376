import { cmds } from "effects";
import { get } from "object-path";
import { getPathPoints } from "components/helpers";

export function* setup(props) {
  const pathId = get(props, "match.params.pathId");
  const cityId = get(props, "match.params.cityId");
  const [{ path }, { city }] = yield [
    cmds.rpc("GetPathByID", { id: pathId }),
    cmds.rpc("GetCityByID", { id: cityId })
  ];

  yield cmds.setState("restaurants.path", path);
  yield cmds.setState("restaurants.city", city);

  const restaurants = yield cmds.rpc("ListRestaurants", {
    pathId,
    cityId,
    page: 0,
    pageLength: 1000
  });
  yield cmds.setState("restaurants.list", restaurants);
  yield cmds.setState("restaurants.loading", false);

  const points = yield cmds.call(getPathPoints, pathId);
  yield cmds.setState("app.pathPoints", points);
}

export function* deleteRestaurant(id) {
  const confirmed = yield cmds.confirm(
    "Are you sure you want to delete this restaurant?"
  );
  if (!confirmed) return;

  const restaurants = yield cmds.getState("restaurants.list", []);
  yield cmds.setState(
    "restaurants.list",
    restaurants.filter(p => p.id !== id)
  );
  yield cmds.rpc("DeleteRestaurant", { id });
}
