import React from "react";
import Select from "@material-ui/core/Select";
import { FastField } from "formik";
import MenuItem from "@material-ui/core/MenuItem";

export default function HourSelect(props) {
  return (
    <FastField name={props.name}>
      {({ field, meta }) => {
        const list = [];

        for (let hour = 0; hour < 24; hour++) {
          for (let minute = 0; minute < 60; minute += 15) {
            const time = `${pad(hour)}:${pad(minute)}`;
            list.push(
              <MenuItem key={time} value={time}>
                {time}
              </MenuItem>
            );
          }
        }

        return (
          <Select
            {...props}
            value={field.value || ""}
            onChange={field.onChange}
            onBlur={field.onBlur}
          >
            {list}
          </Select>
        );
      }}
    </FastField>
  );
}

function pad(t) {
  const v = `${t}`;
  if (v.length === 1) return `0${v}`;
  return v;
}
