import { connect, initializable } from "effects";
import * as functions from "./functions";
import ContributionList from "./ContributionList";
import { get } from "object-path";

const mapStateToProps = (contribution, ownProps, getState) => {
  const pathId = get(ownProps, "match.params.pathId");
  return {
    loading: getState("contributions.loading", true),
    path: getState("contributions.path"),
    contributions: getState("contributions.list", []),
    pathId
  };
};

export default connect(initializable(ContributionList), mapStateToProps, functions);
