import { cmds } from "effects";
import { get } from "object-path";
import mongoid from "mongoid-js";

export function* setup(props) {
    const pathId = get(props, "match.params.pathId");
    const { path } = yield cmds.rpc("GetPathByID", { id: pathId });
    yield cmds.setState("guidepublish.path", path);

    yield cmds.setState("guidepublish.loading", true);
    const guideId = get(props, "match.params.guideId");
    yield cmds.setState("guidepublish.pathId", pathId);

    if (guideId) {
        const { guideTitle } = yield cmds.rpc("GetGuideTitleByID", { id: guideId });
        yield cmds.setState("guidepublish.guide", guideTitle);
    } else {
        yield cmds.setState("guidepublish.guide", {
            pathId,
        });
    }
    yield cmds.setState("guidepublish.loading", false);
}

export function* save(guide) {
    const pathId = yield cmds.getState("guidepublish.pathId");
    if (guide.id) {
        yield cmds.rpc("UpdateGuideTitle", guide);
    } else {
        guide = {
            id: mongoid(),
            ...guide
        };
        yield cmds.rpc("CreateGuideTitle", guide);
    }
    yield cmds.redirect(`/paths/${pathId}/guide`);
}


// eslint-disable-next-line require-yield
export function* validate(values) {
    const errors = {};

    const isUploading = get(values, "media", []).some(m => m.uploading);
    if (isUploading) {
        errors.media = "Media is still uploading";
    }

    if (!values.media) {
        errors.media = "Image is required";
    }

    if (!values.title) {
        errors.title = "Title is required";
    }
    return errors;
}