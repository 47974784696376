import React from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import AppLoading from "components/common/AppLoading";
import { Formik } from "formik";
import Page from "components/common/Page";
import Paper from "components/common/Paper";
import Location from "components/common/form/Location";
import CancelButton from "components/common/form/CancelButton";
import Section from "components/common/form/Section";
import Media from "components/common/form/Media";

export default function Cities({
  loading,
  city,
  onChangeName,
  save,
  validate,
  path,
  pathPoints
}) {
  if (loading) return <AppLoading />;

  return (
    <Page
      path={path}
      title={city.name ? "Edit " + city.name : "New City"}
      crumbs={[
        {
          name: "Cities",
          href: "/cities"
        },
        {
          name: city.name || "New City"
        }
      ]}
    >
      <Paper>
        <Formik
          initialValues={city}
          validate={validate}
          onSubmit={async (values, { setSubmitting }) => {
            await save(values);
            setSubmitting(false);
          }}
          noValidate
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            setFieldValue
          }) => (
            < form onSubmit={handleSubmit} noValidate>
              <Section title="Details">
                <TextField
                  label="City Name"
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  type="text"
                  name="name"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.name || ""}
                  error={Boolean(errors.name)}
                  helperText={errors.name}
                />

                <TextField
                  label="Description"
                  name="description"
                  multiline
                  variant="outlined"
                  fullWidth
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.description || ""}
                  error={Boolean(errors.description)}
                  helperText={errors.description}
                />

                <Location
                  values={values}
                  errors={errors}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  pathPoints={pathPoints}
                  setFieldValue={setFieldValue}
                />
              </Section>

              <Section title="Attach Photos">
                <Media name="media" pathId={path.id} />
              </Section>

              <Section title="Save Changes">
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  disabled={isSubmitting}
                  style={{ marginRight: 6 }}
                >
                  Save
                </Button>
                <CancelButton location={`/paths/${path.id}/cities`} />
              </Section>
            </form>
          )}
        </Formik>
      </Paper>
    </Page>
  );
}
