import { connect, initializable } from "effects";
import * as functions from "./functions";
import ImportData from "./ImportData";

const mapStateToProps = (state, ownProps, getState) => {
  return {
    loading: getState("importdata.loading", true),
    path: getState("importdata.path"),
    entityType: getState("importdata.entityType"),
    progress: getState("importdata.progress", 0),
    uploading: getState("importdata.uploading", false),
    processing: getState("importdata.processing", false),
    results: getState("importdata.results", []),
    importSuccess: getState("importdata.importSuccess", false),
    importError: getState("importdata.importError", false)
  };
};

export default connect(initializable(ImportData), mapStateToProps, functions);
