import { connect, initializable } from "effects";
import * as functions from "./functions";
import AbuseReportView from "./AbuseReportView";

const mapStateToProps = (state, ownProps, prop) => {
  return {
    abuseReport: prop("abuseReportView.abuseReport"),
    loading: prop("abuseReportView.loading", true),
    messages: prop("abuseReportView.messages", [])
  };
};

export default connect(
  initializable(AbuseReportView),
  mapStateToProps,
  functions
);
