import { connect, initializable } from "effects";
import * as functions from "./functions";
import PathDashboard from "./PathDashboard";

const mapStateToProps = (state, ownProps, getState) => {
  return {
    path: getState("app.path", null),
    importSuccess: getState("paths.importSuccess"),
    importError: getState("paths.importError"),
    building: getState("paths.building", false)
  };
};

export default connect(
  initializable(PathDashboard),
  mapStateToProps,
  functions
);
