import { connect, initializable } from "effects";
import * as functions from "./functions";
import GUidePublish from "./GuidePublish";

const mapStateToProps = (state, ownProps, getState) => {
    return {
        loading: getState("guidepublish.loading", true),
        path: getState("guidepublish.path"),
        guide: getState("guidepublish.guide"),
    };
};

export default connect(initializable(GUidePublish), mapStateToProps, functions);
