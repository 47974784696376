import { cmds } from "effects";
import { get } from "object-path";

export function* setup(props) {
  yield cmds.setState("citydashboard.loading", true);
  const pathId = get(props, "match.params.pathId");
  const cityId = get(props, "match.params.cityId");
  const [{ path }, { city }] = yield [
    cmds.rpc("GetPathByID", { id: pathId }),
    cmds.rpc("GetCityByID", { id: cityId })
  ];
  yield cmds.setState("app.path", path);
  yield cmds.setState("citydashboard.city", city);
  yield cmds.setState("citydashboard.loading", false);
}
