import React from "react";
import TopMenu from "./TopMenu";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Breadcrumbs from "./Breadcrumbs";

const useStyles = makeStyles({
  content: {
    padding: 20
  },
  header: {
    marginBottom: 20
  },
  crumbs: {
    paddingLeft: 1
  }
});

export default function Page({ children, path, crumbs, title }) {
  const classes = useStyles();

  return (
    <div>
      <TopMenu />
      <div className={classes.content}>
        <header className={classes.header}>
          <Typography variant="h4" component="h1">
            {title}
          </Typography>
          <div className={classes.crumbs}>
            <Breadcrumbs path={path} items={crumbs}></Breadcrumbs>
          </div>
        </header>
        <section>{children}</section>
      </div>
    </div>
  );
}
