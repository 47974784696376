import React from "react";
import Button from "@material-ui/core/Button";
import AppLoading from "components/common/AppLoading";
import TextField from "components/common/form/TextField";
import { Formik, Form, } from "formik";
import Page from "components/common/Page";
import Paper from "components/common/Paper";
import Section from "components/common/form/Section";
import CancelButton from "components/common/form/CancelButton";
import Media from "components/common/form/Media";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
    error: {
        color: "#f44336",
        fontSize: "12px",
        margin: "-14px 0px 0px 10px",
        letterSpacing: "0.03333em",
    },
});

const GuidePublish = ({ loading,
    path,
    save,
    guide,
    validate
}) => {
    const classes = useStyles();

    if (loading) return <AppLoading />;

    return (
        <Page
            path={path}
            title={guide.title ? "Edit " + guide.title : "New Title"}
            crumbs={
                [
                    {
                        name: "Guide",
                        href: "/guide"
                    },
                    {
                        name: guide.title || "New Title"
                    }
                ]}
        >
            <Paper>
                <Formik
                    initialValues={guide}
                    validate={validate}
                    onSubmit={async (values, { setSubmitting }) => {
                        await save(values);
                        setSubmitting(false);
                    }}
                    noValidate
                >
                    {({
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        isSubmitting,
                        setFieldValue
                    }) => (
                        < Form onSubmit={handleSubmit}>
                            <Section title="Details">
                                <TextField
                                    label="Title"
                                    variant="outlined"
                                    fullWidth
                                    margin="normal"
                                    type="text"
                                    name="title"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.title || ""}
                                    error={Boolean(errors.title)}
                                    helperText={errors.title}
                                />
                            </Section>
                            <Section title="Attach Photos">
                                <Media
                                    name="media"
                                    pathId={path.id}
                                    isSingle={true}
                                />
                            </Section>
                            {errors.media && !values.media && <p className={classes.error}>{errors.media}</p>}

                            <Section title="Save Changes">
                                <Button
                                    variant="contained"
                                    color="primary"
                                    type="submit"
                                    disabled={isSubmitting}
                                    style={{ marginRight: 6 }}
                                >
                                    Save
                                </Button>
                                <CancelButton location={`/paths/${path.id}/guide`} />
                            </Section>
                        </Form>
                    )}
                </Formik>
            </Paper>
        </Page>
    );
}

export default GuidePublish;
