import React from "react";
import Button from "@material-ui/core/Button";
import AppLoading from "components/common/AppLoading";
import { StandardPOIFields } from "components/common/form/StandardPOIFields";
import { Formik, Form } from "formik";
import Page from "components/common/Page";
import Paper from "components/common/Paper";
import Section from "components/common/form/Section";
import CancelButton from "components/common/form/CancelButton";
import TextField from "components/common/form/TextField";

export default function POIs({
  loading,
  poi,
  onChangeName,
  save,
  validate,
  path,
  city,
  pathPoints
}) {
  if (loading) return <AppLoading />;
  return (
    <Page
      path={path}
      title={
        poi.name ? "Edit " + poi.name : "New Point of Interest in " + city.name
      }
      crumbs={[
        {
          name: "Cities and Towns",
          href: "/cities"
        },
        {
          name: city.name,
          href: `/cities/${city.id}`
        },
        {
          name: "Points of Interest",
          href: `/cities/${city.id}/pois`
        },
        {
          name: poi.name || "New Point of Interest"
        }
      ]}
    >
      <Paper>
        <Formik
          initialValues={poi}
          validate={validate}
          onSubmit={async (values, { setSubmitting }) => {
            await save(values);
            setSubmitting(false);
          }}
          validateOnBlur={true}
          validateOnChange={false}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            setFieldValue
          }) => (
            <Form>
              <StandardPOIFields
                path={path}
                city={city}
                handleChange={handleChange}
                handleBlur={handleBlur}
                values={values}
                errors={errors}
                pathPoints={pathPoints}
                setFieldValue={setFieldValue}
              />

              <Section title="Additional Information">
                <TextField label="Point-of-interest type" name="type" />
                <TextField label="Name in English" name="nameEnglish" />
                <TextField label="Booking Url" name="bookingUrl" />
                <TextField label="Price" name="price" />
                <TextField label="Free Days/Time" name="freeDaysTimes" />
                <TextField label="Facebook" name="facebook" />
              </Section>

              <Section title="Save Changes">
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  style={{ marginRight: 6 }}
                >
                  Save
                </Button>
                <CancelButton
                  location={`/paths/${path.id}/cities/${city.id}/pois`}
                />
              </Section>
            </Form>
          )}
        </Formik>
      </Paper>
    </Page>
  );
}
