import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Button from "@material-ui/core/Button";

const useStyles = makeStyles({
  padding: {
    height: 64,
    width: "100%"
  },
  toolbar: {
    justifyContent: "flex-end"
  }
});

export default function({ logout }) {
  const classes = useStyles();

  return (
    <div>
      <AppBar className={classes.appbar}>
        <Toolbar className={classes.toolbar}>
          <Button color="inherit" onClick={logout}>
            Logout
          </Button>
        </Toolbar>
      </AppBar>
      <div className={classes.padding} />
    </div>
  );
}
