import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Page from "components/common/Page";
import { Button } from "@material-ui/core";
import Link from "@material-ui/core/Link";
import AppLoading from "components/common/AppLoading";

const useStyles = makeStyles({
    table: {
        width: "100%",
        overflowX: "auto"
    },
    header: {
        display: "flex",
        alignItems: "center"
    }
});

export default function GuideList({
    loading,
    path,
    pathId,
    guide,
    DeleteGuideTitle
}) {
    const classes = useStyles();

    if (loading) return <AppLoading />;

    return (
        <Page
            path={path}
            title="Guide"
            crumbs={[
                {
                    name: "Guide",
                    href: "/guide"
                }
            ]}
        >
            <Button
                color="primary"
                variant="contained"
                href={`/paths/${pathId}/guide/create`}
                style={{ marginBottom: 20 }}
            >
                New Title
            </Button>

            <Paper className={classes.table}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Title</TableCell>
                            <TableCell>Image</TableCell>
                            {/* <TableCell>view</TableCell> */}
                            <TableCell>Actions</TableCell>
                        </TableRow>
                    </TableHead>

                    <TableBody>
                        {guide.map(guide => (
                            <TableRow key={guide.id}>
                                <TableCell component="th" scope="row">
                                    <Link href={`/paths/${pathId}/guide/${guide.id}/faqs`}>
                                        {guide.title}
                                    </Link>
                                </TableCell>
                                <TableCell component="th" scope="row">
                                    <div className="thumbnail-img">
                                        {(guide.media && guide.media.length > 0) &&
                                            (
                                                <div style={{ width: "200px", height: "100px" }}>
                                                    <img src={`${process.env.REACT_APP_IMGIX_URL}${guide.media[0].filePath}?h=100&&w=200`} alt="guide-title"
                                                        onError={(e) => {
                                                            if (!e.target.errorOccurred) {
                                                                e.target.errorOccurred = true;
                                                                e.target.src = '/images/logo.png'; // Show default image
                                                                e.target.style.width = "150px";
                                                                e.target.style.height = "100px"
                                                            } else {
                                                                e.target.style.display = 'none'; // Hide the image if default also fails
                                                            }
                                                        }}
                                                    />
                                                </div>
                                            )
                                        }

                                    </div>
                                </TableCell>

                                <TableCell component="th" scope="row">
                                    <Link
                                        href={`/paths/${pathId}/guide/edit/${guide.id}`}
                                    >
                                        Edit
                                    </Link>
                                    &nbsp;
                                    <Link href="#" onClick={() => DeleteGuideTitle(guide.id)}>
                                        Delete
                                    </Link>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </Paper>
        </Page>
    );
}
