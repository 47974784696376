import initFirebase from "./firebase";

export { rpc, gateway } from "./rpc";
export { redirect } from "./redirect";
export { confirm } from "./confirm";
export { setLocal, getLocal } from "./localStorage";
export { search } from "./algolia";

const {
  firebaseUpload,
  firebaseUploadCancel,
  firebaseDataSheetUpload,
  firebaseDataSheetUploadCancel
} = initFirebase();
export {
  firebaseUpload,
  firebaseUploadCancel,
  firebaseDataSheetUpload,
  firebaseDataSheetUploadCancel
};

export { imageOrientation, imageDimensions } from "./image";

export {
  generateImagePreview,
  generateVideoPreview
} from "./generateImagePreview";
