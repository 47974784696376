import { connect, initializable } from "effects";
import * as functions from "./functions";
import POIPublish from "./POIPublish";

const mapStateToProps = (state, ownProps, getState) => {
  return {
    loading: getState("poipublish.loading", true),
    poi: getState("poipublish.poi"),
    path: getState("poipublish.path"),
    city: getState("poipublish.city"),
    pathPoints: getState("app.pathPoints")
  };
};

export default connect(
  initializable(POIPublish),
  mapStateToProps,
  functions
);
