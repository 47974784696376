import React from "react";
import MediaPreview from "./MediaPreview";
import { SortableContainer, SortableElement } from "react-sortable-hoc";
import MediaSelectButton from "./MediaSelectButton";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  list: {
    margin: 0,
    padding: 0,
    display: "flex",
    alignItems: "center",
    overflowX: "scroll",
    "-webkit-overflow-scrolling": "touch",
    paddingTop: 15
  }
});

const SortableItem = SortableElement(
  ({ media, uploadProgress, clearMedia, isSingle }) => {
    return (
      <MediaPreview
        clearMedia={clearMedia}
        key={media.filePath}
        filePath={media.filePath}
        progress={uploadProgress[media.transactionId]}
        uploading={uploadProgress[media.transactionId] < 100}
        fileType={media.fileType}
        url={media.url}
      />
    );
  }
);

const SortableList = SortableContainer(
  ({ mediaList, uploadProgress, clearMedia }) => {
    const classes = useStyles();
    return (
      <ul className={classes.list}>
        {mediaList.map((media, index) => {
          return <SortableItem
            key={media.filePath}
            clearMedia={clearMedia}
            index={index}
            media={media}
            uploadProgress={uploadProgress}
          />;
        })}
      </ul>
    );
  }
);

export default function MediaList({
  media,
  clearMedia,
  uploadProgress,
  moveMedia,
  uploadMedia,
  singleUploadMedia,
  isSingle,
}) {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <SortableList
        axis={"x"}
        clearMedia={clearMedia}
        distance={1}
        lockAxis={"x"}
        lockOffset="30%"
        mediaList={media}
        onSortEnd={e => moveMedia(e.oldIndex, e.newIndex)}
        uploadProgress={uploadProgress}
      />
      <MediaSelectButton onChange={(files) => uploadMedia(files, isSingle)} isSingle={isSingle} />
    </div>
  );
}
