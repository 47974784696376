import React from "react";
import MediaPreviewLoader from "./MediaPreviewLoader";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  preview: {
    display: "flex",
    width: 200,
    position: "relative",
    marginRight: 15,
    marginBottom: 20
  },
  remove: {
    position: "absolute",
    top: -10,
    right: -10,
    width: "30px",
    height: "30px",
    backgroundImage: "url(/images/remove.png)",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    cursor: "pointer"
  },
  image: {
    maxWidth: "100%",
    cursor: "grab"
  }
});

export default function MediaPreview({
  filePath,
  url,
  clearMedia,
  uploading,
  progress,
}) {
  const classes = useStyles();

  if (!filePath && !url) return null;

  const fullUrl = url ? url : `${process.env.REACT_APP_IMGIX_URL + filePath}?w=300`;

  return (
    <div className={classes.preview} data-publish-media-preview>
      <div
        className={classes.remove}
        onClick={() => clearMedia(filePath)}
        data-publish-media-remove
      />
      {uploading && <MediaPreviewLoader progress={progress} />}
      <img src={fullUrl} className={classes.image} alt="" />
    </div>
  );
}
