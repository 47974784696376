import { cmds } from "effects";
import { get } from "object-path";
import mongoid from "mongoid-js";

export function* setup(props) {
    const pathId = get(props, "match.params.pathId");
    const guideId = get(props, "match.params.guideId");

    yield cmds.setState("qapublish.pathId", pathId);
    yield cmds.setState("qapublish.loading", true);

    const [{ path }, { guideTitle }] = yield [
        cmds.rpc("GetPathByID", { id: pathId }),
        cmds.rpc("GetGuideTitleByID", { id: guideId })
    ];
    yield cmds.setState("qapublish.path", path);
    yield cmds.setState("qapublish.guide", guideTitle);

    const faqId = get(props, "match.params.faqId");
    if (faqId) {
        const { guideQuestionAnswer } = yield cmds.rpc("GetGuideQuestionAnswerByID", { id: faqId });
        yield cmds.setState("qapublish.faq", guideQuestionAnswer);
    } else {
        yield cmds.setState("qapublish.faq", {
            pathId,
        });
    }
    yield cmds.setState("qapublish.loading", false);
}

export function* save(faq) {
    const pathId = yield cmds.getState("qapublish.pathId");
    const guide = yield cmds.getState("qapublish.guide");

    if (faq.id) {
        yield cmds.rpc("UpdateGuideQuestionAnswer", faq);
    } else {
        faq = {
            id: mongoid(),
            ...faq,
            guide_title_id: guide.id,
        };
        yield cmds.rpc("CreateGuideQuestionAnswer", faq);
    }
    yield cmds.redirect(`/paths/${pathId}/guide/${guide.id}/faqs`);
}

// eslint-disable-next-line require-yield
export function* validate(values) {
    const errors = {};

    if (!values.question) {
        errors.question = "Question is required";
    }

    if (!values.answerContent) {
        errors.answerContent = "Answer is required";
    }
    return errors;
}