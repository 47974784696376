import {cmds} from "effects";
import {get} from "object-path";
import {getPathPoints} from "components/helpers";

export function* setup(props) {
    const pathId = get(props, "match.params.pathId");
    const cityId = get(props, "match.params.cityId");
    const [{path}, {city}] = yield [
        cmds.rpc("GetPathByID", {id: pathId}),
        cmds.rpc("GetCityByID", {id: cityId})
    ];

    yield cmds.setState("accommodations.path", path);
    yield cmds.setState("accommodations.city", city);

    const accommodations = yield cmds.rpc("ListAccommodations", {
        pathId,
        cityId,
        page: 0,
        pageLength: 1000
    });
    yield cmds.setState("accommodations.list", accommodations);

    const points = yield cmds.call(getPathPoints, pathId);
    yield cmds.setState("app.pathPoints", points);

    yield cmds.setState("accommodations.loading", false);
}

export function* deleteAccommodation(id) {
    const confirmed = yield cmds.confirm(
        "Are you sure you want to delete this accommodation?"
    );
    if (!confirmed) return;

    const accommodations = yield cmds.getState("accommodations.list", []);
    yield cmds.setState(
        "accommodations.list",
        accommodations.filter(p => p.id !== id)
    );
    yield cmds.rpc("DeleteAccommodation", {id});
}
