import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Link from "@material-ui/core/Link";
import Page from "components/common/Page";
import AppLoading from "../../common/AppLoading";
import { getPlaceLink} from "../../helpers";

const useStyles = makeStyles({
  table: {
    width: "100%",
    overflowX: "auto"
  },
  header: {
    display: "flex",
    alignItems: "center"
  },
  resolved: {
    textDecoration: "line-through"
  }
});

export default function PlaceProblemReports({ path, placeProblemReports, loading, closeReport }) {
  const classes = useStyles();

  if (loading) return <AppLoading />;

  return (
    <Page
      title="Place Problems"
      path={path}
      crumbs={[
        {
          name: "Place Problems",
          href: "/place-problems"
        }
      ]}
    >
      <Paper className={classes.table}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Place</TableCell>
              <TableCell>Reporting User</TableCell>
              <TableCell>Problem</TableCell>
              <TableCell>Message</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {placeProblemReports.map(placeProblemReport => (
              <TableRow key={placeProblemReport.id} className={`${placeProblemReport.resolved ? classes.resolved : ""}`}>
                <TableCell component="th" scope="row">
                  <Link target="_blank" href={getPlaceLink(placeProblemReport.placeType, placeProblemReport.placeId)}>{placeProblemReport.placeName}</Link>
                </TableCell>
                <TableCell component="th" scope="row">
                  {placeProblemReport.reportingUserName}
                </TableCell>
                <TableCell component="th" scope="row">
                  {placeProblemReport.problem}
                </TableCell>
                <TableCell component="th" scope="row">
                  {placeProblemReport.message}
                </TableCell>
                <TableCell component="th" scope="row">
                  {placeProblemReport.resolved ? "Closed" : <span style={{color: "red"}}>Open</span>}
                </TableCell>
                <TableCell component="th" scope="row">
                  {!placeProblemReport.resolved && <Link href="#" onClick={() => closeReport(placeProblemReport)}>Close Report</Link>}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Paper>
    </Page>
  );
}
