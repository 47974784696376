import React from "react";
import TextField from "components/common/form/TextField";

export default function Address() {
  return (
    <div>
      <TextField label="Street" name="address.street" />
      <TextField label="City" name="address.locality" />
      <TextField label="Admin Level 2 (County)" name="address.adminLevel2" />
      <TextField label="Admin Level 1 (State)" name="address.adminLevel1" />
      <TextField label="Country" name="address.country" />
      <TextField label="Zip" name="address.zip" />
    </div>
  );
}
