import React from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import AppLoading from "components/common/AppLoading";
import { Formik } from "formik";
import Page from "components/common/Page";
import Paper from "components/common/Paper";
import CancelButton from "components/common/form/CancelButton";
import Section from "components/common/form/Section";

const PlannerCategoryPublish = ({
  loading,
  plannerCategory,
  save,
  validate,
  path,
}) => {
  if (loading) return <AppLoading />;

  return (
    <Page
      path={path}
      title={
        plannerCategory.title
          ? "Edit " + plannerCategory.title
          : "New Planner Category"
      }
      crumbs={[
        {
          name: "Planner Category",
          href: "/planner-categories",
        },
        {
          name: plannerCategory.title || "New Planner Category",
        },
      ]}
    >
      <Paper>
        <Formik
          initialValues={plannerCategory}
          validate={validate}
          onSubmit={async (values, { setSubmitting }) => {
            await save(values);
            setSubmitting(false);
          }}
          noValidate
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            setFieldValue,
          }) => (
            <form onSubmit={handleSubmit} noValidate>
              <Section title="Details">
                <TextField
                  label="Category Name"
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  type="text"
                  name="title"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.title || ""}
                  error={Boolean(errors.title)}
                  helperText={errors.title}
                />
              </Section>

              <Section title="Save Changes">
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  disabled={isSubmitting}
                  style={{ marginRight: 6 }}
                >
                  Save
                </Button>
                <CancelButton
                  location={`/paths/${path.id}/planner-categories`}
                />
              </Section>
            </form>
          )}
        </Formik>
      </Paper>
    </Page>
  );
};

export default PlannerCategoryPublish;
