import { connect, initializable } from "effects";
import * as functions from "./functions";
import Tags from "./Tags";

const mapStateToProps = (state, ownProps, getState) => {
  const map = ownProps.options.reduce((p, c) => {
    p[c.value] = c;
    return p;
  }, {});

  return {
    ...ownProps,
    options: ownProps.options,
    map
  };
};

export default connect(initializable(Tags), mapStateToProps, functions);
