import { cmds } from "effects";
import { get, set } from "object-path";
import { getPathPoints } from "components/helpers";
import mongoid from "mongoid-js";

export function* setup(props) {
  const pathId = get(props, "match.params.pathId");
  const { path } = yield cmds.rpc("GetPathByID", { id: pathId });
  yield cmds.setState("citypublish.path", path);

  yield cmds.setState("citypublish.loading", true);
  const cityId = get(props, "match.params.cityId");
  yield cmds.setState("citypublish.pathId", pathId);

  if (cityId) {
    const { city } = yield cmds.rpc("GetCityByID", { id: cityId });
    if (!city.location) city.location = { lat: 0, lng: 0 };
    yield cmds.setState("citypublish.city", city);
  } else {
    yield cmds.setState("citypublish.city", {
      pathId,
      location: {
        lat: 0,
        lng: 0
      }
    });
  }
  yield cmds.setState("citypublish.loading", false);

  const points = yield cmds.call(getPathPoints, pathId);
  yield cmds.setState("app.pathPoints", points);
}

export function* onChangeName(name) {
  yield cmds.setState("citypublish.city.name", name);
}

export function* save(city) {
  const pathId = yield cmds.getState("citypublish.pathId");
  if (city.id) {
    yield cmds.rpc("UpdateCity", city);
  } else {
    city = {
      id: mongoid(),
      ...city
    };
    yield cmds.rpc("CreateCity", city);
  }
  yield cmds.redirect(`/paths/${pathId}/cities`);
}

// eslint-disable-next-line require-yield
export function* validate(values) {
  const errors = {};

  const isUploading = get(values, "media", []).some(m => m.uploading);
  if (isUploading) {
    errors.media = "Media is still uploading";
  }

  if (!values.name) {
    errors.name = "Required";
  }

  if (!get(values, "location.lat")) {
    set(errors, "location.lat", "Required");
  }

  if (!get(values, "location.lng")) {
    set(errors, "location.lng", "Required");
  }

  return errors;
}
