import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Link from "@material-ui/core/Link";
import Button from "@material-ui/core/Button";
import AppLoading from "components/common/AppLoading";
import Page from "components/common/Page";
import PlannerModal from "./PlannerModal";
const useStyles = makeStyles({
  table: {
    width: "100%",
    overflowX: "auto",
  },
  header: {
    display: "flex",
    alignItems: "center",
  },
  mapContainer: {
    marginBottom: 20,
    width: "100%",
    height: 500,
  },
});

const PlannerList = ({
  loading,
  path,
  planners,
  plannerCategoryDetail,
  pathId,
  categoryId,
  deletePlanner,
}) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  // const handleOpen = () => setOpen(true);
  const [selectedPlannerIndex, setSelectedPlannerIndex] = useState(null);

  const handleOpen = (index) => {
    setSelectedPlannerIndex(index); // Set the selected planner when opening the modal
    setOpen(true);
  };

  if (loading) return <AppLoading />;

  return (
    <Page
      path={path}
      title="Planner"
      crumbs={[
        {
          name: "Planner",
          href: "/planners",
        },
      ]}
    >
      <Button
        color="primary"
        variant="contained"
        href={`/paths/${pathId}/planners/${categoryId}/create`}
        style={{ marginBottom: 20 }}
      >
        New Planner
      </Button>

      <Paper className={classes.table}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Category</TableCell>
              <TableCell>Title</TableCell>
              <TableCell>Total Kms</TableCell>
              <TableCell>Days</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {planners.map((e, index) => (
              < TableRow key={e.id} >
                <TableCell component="th" scope="row">
                  {plannerCategoryDetail.title}
                </TableCell>
                <TableCell component="th" scope="row">
                  <Link onClick={() => handleOpen(index)} style={{ cursor: "pointer" }}>
                    {e.title}
                  </Link>
                </TableCell>
                <TableCell component="th" scope="row">
                  {(e.totalDistanceInMeters / 1000).toFixed(1)} km
                </TableCell>
                <TableCell component="th" scope="row">
                  {e.totalDays}
                </TableCell>
                <TableCell component="th" scope="row">
                  <Link href={`/paths/${pathId}/planners/${categoryId}/edit/${e.id}`}>
                    Edit
                  </Link>
                  &nbsp;
                  <Link href="#" onClick={() => deletePlanner(e.id)}>
                    Delete
                  </Link>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>

        {
          open && (
            <PlannerModal
              open={open}
              setOpen={setOpen}
              planners={planners} // Pass the entire array of planners
              selectedPlannerIndex={selectedPlannerIndex}
              plannerCategoryDetail={plannerCategoryDetail}
            />
          )
        }
      </Paper>
    </Page>
  );
};

export default PlannerList;
