import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";

const useStyles = makeStyles({
  content: {
    padding: 20
  }
});

export default function CaminoPaper({ children }) {
  const classes = useStyles();

  return <Paper className={classes.content}>{children}</Paper>;
}
