import { connect, initializable } from "effects";
import * as functions from "./functions";
import RestaurantSelectMultiple from "./RestaurantSelectMultiple";
import { get } from "object-path";

const mapStateToProps = (state, ownProps, getState) => {
  let currentIndex = get(ownProps, "currentIndex") || 0;
  const cityId = get(ownProps, "cityId");
  return {
    ...ownProps,
    loading: getState(`restaurantselect_${currentIndex}_${cityId}.loading`, true),
    options: getState(`restaurantselect_${currentIndex}_${cityId}.options`, []),
    map: getState(`restaurantselect_${currentIndex}_${cityId}.map`, {})
  };
};

export default connect(initializable(RestaurantSelectMultiple), mapStateToProps, functions);
