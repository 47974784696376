import React from "react";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
  error: {
    padding: 10,
    backgroundColor: "red",
    color: "#fff",
    marginBottom: 5
  }
}));

export default function ImportError({ message }) {
  const classes = useStyles();

  return <div className={classes.error}>{message}</div>;
}
