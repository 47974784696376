import React from "react";
import Media from "./Media";
import { FastField, ErrorMessage } from "formik";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";

export default function (props) {
    const { name } = props;
    return (
        <FastField name={name}>
            {({ field, form, meta }) => {
                return (
                    <FormControl error={Boolean(meta.error)}>
                        <Media
                            {...props}
                            name={name}
                            media={field.value}
                            onChange={m => form.setFieldValue(name, m)}
                        />
                        <ErrorMessage
                            name={name}
                            render={msg => <FormHelperText>{meta.error}</FormHelperText>}
                        />
                    </FormControl>
                );
            }}
        </FastField>
    );
}
