import React from "react";
import Button from "@material-ui/core/Button";
import AppLoading from "components/common/AppLoading";
import Page from "components/common/Page";
import Paper from "components/common/Paper";
import { makeStyles } from "@material-ui/core/styles";
import ImportErrorList from "./components/ImportErrorList";
import CircularProgress from "@material-ui/core/CircularProgress";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";

const useStyles = makeStyles(theme => ({
  upload: {
    display: "flex"
  },
  uploadInput: {
    display: "none"
  },
  progressIndicator: {
    marginLeft: 20
  }
}));

export default function ImportData({
  loading,
  path,
  entityType,
  upload,
  uploading,
  processing,
  progress,
  results,
  importSuccess,
  importError,
  closeSuccessDialog,
  closeErrorDialog
}) {
  const classes = useStyles();

  if (loading) return <AppLoading />;

  return (
    <Page
      path={path}
      title={path.name}
      crumbs={[
        {
          name: "Import " + label(entityType)
        }
      ]}
    >
      <Paper>
        <input
          accept=".csv"
          className={classes.uploadInput}
          id="contained-button-file"
          type="file"
          onChange={e => {
            upload(e.target.files[0]);
            e.target.value = null;
          }}
        />
        <UploadDataSheet
          classes={classes}
          uploading={uploading}
          processing={processing}
          progress={progress}
        />
        <div>
          {results.map((r, i) => {
            return <ImportErrorList validation={r} key={i} />;
          })}
        </div>
        <Snackbar
          open={importSuccess}
          autoHideDuration={6000}
          onClose={closeSuccessDialog}
        >
          <Alert onClose={closeSuccessDialog} severity="success">
            {label(entityType)} have been imported.
          </Alert>
        </Snackbar>
        <Snackbar
          open={!!importError}
          autoHideDuration={10000}
          onClose={closeErrorDialog}
        >
          <Alert onClose={closeErrorDialog} severity="error">
            There was an error importing: {importError}
          </Alert>
        </Snackbar>
      </Paper>
    </Page>
  );
}

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

function UploadDataSheet({ classes, uploading, processing, progress }) {
  return (
    <div className={classes.upload}>
      <label htmlFor="contained-button-file">
        <Button variant="contained" color="primary" component="span">
          Upload Data Sheet
        </Button>
      </label>
      <div className={classes.progressIndicator}>
        {(processing || uploading) && (
          <CircularProgress
            variant={progressType(processing, uploading, progress)}
            value={progress}
          />
        )}
      </div>
    </div>
  );
}

function progressType(processing, uploading, progress) {
  if (uploading && progress === 0) return "indeterminate";
  if (processing && !uploading) return "indeterminate";
  return "determinate";
}

function label(s) {
  switch (s) {
    case "restaurants":
      return "Restaurants";
    case "accommodations":
      return "Accommodations";
    case "pois":
      return "Points-of-interest";
    default:
      return "Unknown Type";
  }
}
