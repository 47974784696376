import { cmds } from "effects";
import { get } from "object-path";
import { validateStandardPOIFields } from "components/common/form/StandardPOIFields";
import { getPathPoints } from "components/helpers";
import { isURL } from "validator";
import mongoid from "mongoid-js"

export function* setup(props) {
  yield cmds.setState("accommodationpublish.loading", true);

  const pathId = get(props, "match.params.pathId");
  const cityId = get(props, "match.params.cityId");
  const [{ path }, { city }] = yield [
    cmds.rpc("GetPathByID", { id: pathId }),
    cmds.rpc("GetCityByID", { id: cityId })
  ];

  yield cmds.setState("accommodationpublish.path", path);
  yield cmds.setState("accommodationpublish.city", city);

  const accommodationId = get(props, "match.params.accommodationId");
  yield cmds.setState("accommodationpublish.pathId", pathId);

  if (accommodationId) {
    const { accommodation } = yield cmds.rpc("GetAccommodationByID", { id: accommodationId });
    yield cmds.setState("accommodationpublish.accommodation", accommodation);
  } else {
    yield cmds.setState("accommodationpublish.accommodation", {
      pathId,
      cityId
    });
  }
  yield cmds.setState("accommodationpublish.loading", false);

  const points = yield cmds.call(getPathPoints, pathId);
  yield cmds.setState("app.pathPoints", points);
}

export function* onChangeName(name) {
  yield cmds.setState("accommodationpublish.accommodation.name", name);
}

export function* save(accommodation) {
  const pathId = yield cmds.getState("accommodationpublish.path.id");
  const cityId = yield cmds.getState("accommodationpublish.city.id");

  if (accommodation.id) {
    yield cmds.rpc("UpdateAccommodation", accommodation);
  } else {
    accommodation = {
      id: mongoid(),
      ...accommodation
    }
    yield cmds.rpc("CreateAccommodation", accommodation);
  }
  yield cmds.redirect(`/paths/${pathId}/cities/${cityId}/accommodations`);
}

// eslint-disable-next-line require-yield
export function* validate(values) {
  const errors = validateStandardPOIFields(values);

  if (!values.type) {
    errors.type = "Required";
  }

  if (values.bookingUrl && !isURL(values.bookingUrl)) {
    errors.bookingUrl = "URL is invalid";
  }

  return errors;
}
