import { cmds } from "effects";
import { get } from "object-path";

export function* setup(props) {
  yield cmds.setState("importdata.loading", true);
  const pathId = get(props, "match.params.pathId");
  const entityType = get(props, "match.params.entityType");
  const { path } = yield cmds.rpc("GetPathByID", { id: pathId });
  yield cmds.setState("importdata.path", path);
  yield cmds.setState("importdata.entityType", entityType);
  yield cmds.setState("importdata.loading", false);
}

export function* upload(file) {
  // if (!files || files.length === 0) return;
  if (!file) return;
  const uploadingTransactionId = yield cmds.getState(
    "importdata.transactionId"
  );

  if (uploadingTransactionId) {
    yield cmds.uploadDataSheetCancel(uploadingTransactionId);
  }

  yield cmds.setState("importdata.processing", true);
  yield cmds.setState("importdata.uploading", true);
  yield cmds.setState("importdata.results", []);
  yield cmds.setState("importdata.progress", 0);

  const path = yield cmds.getState("importdata.path");
  const transactionId = yield cmds.uploadDataSheet(file, path.id, {
    onEnd: onUploadEnd,
    onProgress: onUploadProgress,
    onCancel: onCancel
    // onError: console.error // eslint-disable-line
  });
  yield cmds.setState("importdata.transactionId", transactionId);
}

export function* onCancel(state) {
  yield cmds.setState("importdata.results", []);
  yield cmds.setState("importdata.uploading", false);
  yield cmds.setState("importdata.progress", 0);
}

export function* onUploadProgress(state) {
  yield cmds.setState("importdata.progress", state.progress);
}

export function* onUploadEnd(state) {
  yield cmds.setState("importdata.uploading", false);

  try {
    const path = yield cmds.getState("importdata.path");
    const entityType = yield cmds.getState("importdata.entityType");

    const { results, hasErrors } = yield cmds.gateway("/import/" + entityType, {
      url: state.publicUrl,
      pathId: path.id,
      batchId: state.transactionId
    });

    yield cmds.setState("importdata.results", results);

    if (!hasErrors) yield cmds.setState("importdata.importSuccess", true);
  } catch (e) {
    yield cmds.setState("importdata.importError", e.message);
  } finally {
    yield cmds.setState("importdata.processing", false);
  }
}

export function* closeSuccessDialog() {
  yield cmds.setState("importdata.importSuccess", null);
}

export function* closeErrorDialog() {
  yield cmds.setState("importdata.importError", null);
}
