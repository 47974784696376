import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import HourSelect from "components/common/form/HourSelect";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";

const useStyles = makeStyles({
  left: {
    marginRight: 10
  },
  range: {
    marginBottom: 20
  }
});

export default function HourRange({
  startLabel,
  startLabelWidth,
  startName,
  endLabel,
  endLabelWidth,
  endName,
  values,
  errors,
  handleChange,
  handleBlur
}) {
  const classes = useStyles();

  return (
    <div className={classes.range}>
      <FormControl variant="outlined" margin="normal" style={{ width: 120 }}>
        <InputLabel id={`${startName}-label`}>{startLabel}</InputLabel>
        <HourSelect name={startName} labelWidth={startLabelWidth} />
      </FormControl>
      &nbsp;
      <FormControl variant="outlined" margin="normal" style={{ width: 120 }}>
        <InputLabel id={`${endName}-label`}>{endLabel}</InputLabel>
        <HourSelect name={endName} labelWidth={endLabelWidth} />
      </FormControl>
    </div>
  );
}
